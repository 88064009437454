$sidebar: 225px;
$delete-color: #f32121;
$deselect-color: #0082FF;

.image-gallery {
    height: 100%;

    div.buttons {
        text-align: center; }

    .gallery-btn {
        margin: 5px 0px 5px 15px;
        border-radius: 5px;
        border: none;
        color: #fff;
        padding: 10px;
        cursor: pointer;

        &:disabled {
            cursor: default;
            color: desaturate(#fff, 50%); }

        &.delete {
            background-color: desaturate($delete-color, 20%); }
        &:disabled {
            background-color: desaturate($delete-color, 90%); }

        &.deselect {
            background-color: desaturate($deselect-color, 20%); }
        &:disabled {
            background-color: desaturate($deselect-color, 90%); } }

    div.image-selector-tab {
        height: auto;
        & > div.infinite-scroll-view {
            position: relative !important;
            overflow-y: auto !important; }
        div.image-selector-container-gallery {
            display: grid;
            grid-template-columns: repeat(auto-fill, 272px);
            justify-content: center;
            grid-gap: 10px;
            padding: 15px 40px 15px 40px; }
        div.library-image {
            height: 153px;
            width: 272px;
            box-shadow: 0px 0px 6px 0px #999;
            margin: 0px; } }

    div.uploader {
        min-height: 150px;

        div.centered-content {
            transform: none; } } }




.file-attachments-container, .image-preview, .preview-panel-wrapper {
    div.video-js.vjs-16-9 {
        padding-top: calc(99vh - 80px);
        max-height: 90vh;
        margin: 0 auto;
        margin-top: 80px;
        display: block;
        max-width: 80%; } }

.file-attachments-container, .post-content-wrapper {
    div.video-js.vjs-16-9 {
        margin-top: 0; } }
