.topic-bubble {
    background: #f2f2f0;
    padding: 5px 10px 5px 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 30px;
    font-size: 12px;
    white-space: nowrap;
    display: inline-block; }


.topic-bubble-removable {
    background: #f2f2f0;
    padding: 5px 10px 5px 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 30px;
    font-size: 12px;
    white-space: nowrap;
    display: inline-block;

    .material-icons {
        color: #989696;
        font-size: 14px !important;
        vertical-align: middle;
        padding-left: 5px;
        cursor: pointer; } }
