div.chonky-MuiListSubheader-gutters {
    display: none; }

li.chonky-baseButton {
    height: auto !important;
    padding: 8px 16px !important; }

button.chonky-baseButton {
    padding: 8px; }

li.chonky-activeButton {
    background-color: #F2F2EF; }

li.delete-text-styling {
    border-top: lightgrey 1px solid;

    &[aria-disabled="false"] {
        color: red; } }

div.chonky-searchFieldContainer {
    width: 300px;

    div.chonky-searchFieldInput {
        display: none; } }

span.highlight-search-text {
    font-weight: bold; }

div.search-text-div {
    display: flex;
    flex-direction: column;

    div.search-text-tag-div {
        font-size: 12px; } }

div.search-text-tag-div.listview {
    padding: 2px 9px; }

img.search-text-icon {
    margin-right: 4px; }

div[class^="listFileEntryProperty"] {
    flex-basis: 170px; }

div.checkmark-icon-div {
    margin-left: 8px;

    .checkmark-icon-inactive {
        display: none; } }

div.chonky-MuiListItemIcon-root.chonky-icon {
    margin: 0; }

nav.media-gallery-pagination {
    margin-bottom: 10px;

    > ul {
      justify-content: center; } }

.gallery-drag-drop {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative; }
