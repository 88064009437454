@import "../../common/components/authoring/styles/authoringVariables";

#content-bands-settings-drawer {
    margin-top: 50px;
    overflow: hidden;
    height: calc(100% - 50px); // 50px is the offset of the nav bar
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    &.MuiDrawer-paper {
        width: $settings-drawer-width;

        #content-bands-settings-editor {
            padding: 20px;

            .form-group {
                display: flex;
                align-items: center;
                margin: 15px 0px;
                justify-content: space-between;
                flex-wrap: wrap;

                .input {
                    height: 44px;
                    width: 180px;
                    font-size: 14px;
                    box-sizing: border-box; }

                .feed-label {
                    width: 50%;
                    font-size: 14px; } } }

        /* MUI theme small screen break point*/ }
    @media (max-width: 960px) {
        &.MuiDrawer-paper {
            width: 100%; } } }
