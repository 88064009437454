#my-account {
  position: relative;
  height: 100%;

  .account-data {
    > div {
      > div:first-child {
        width: 120px; } } }

  .avatar {
    margin-bottom: 25px;
    margin-top: 130px;
    .avatar-icon-container {
      height: 100px;
      width: 100px; }
    .avatar-letter {
      font-size: 36px; } }

  .profile-banner {
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("/images/profile-banner.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 200px;
    width: 100%; } }

#user-account {
  .account-data {
    > div {
      > div:first-child {
        flex-shrink: 0;
        width: 120px; } } }

  .account-enabled {
    margin: -12px; }

  .account-type {
    > div {
      word-break: break-word; } }

  .avatar {
    margin-bottom: 20px;
    .avatar-icon-container {
      height: 100px;
      width: 100px; }
    .avatar-letter {
      font-size: 28px; } }

  .user-info {
    display: flex;
    justify-content: space-between;
    .full-name {
      margin-right: 5px; }
    .user-disabled {
      color: #888888;
      display: inline-block;
      font-size: 18px;
      font-style: italic; } } }

#my-account,
#user-account {
  hr {
    margin: 20px 0; }

  .account-info {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; }

  .account-data {
    font-size: 14px;
    margin-top: 20px;
    > div {
      display: flex;
      margin: 8px 0;
      &:first-child {
        margin-top: 0; }
      &:last-child {
        margin-bottom: 0; }
      > div {
        &:first-child {
          align-items: center;
          display: inline-flex;
          height: 20px; } } }
    .interaction-stats {
      max-width: 150px;
      width: 100%;
      > div {
        align-items: center;
        display: inline-flex;
        width: calc(50% - 7.5px);
        &:first-child {
          margin-right: 7.5px; }
        &:nth-child(n+2) {
          margin-left: 7.5px; }
        > svg {
          color: #888888;
          margin-right: 4px; } } } }

  .avatar {
    margin-right: 10px;
    .avatar-icon-container {
      display: flex;
      align-items: center;
      justify-content: center; }
    .avatar-letter {
      font-weight: 400;
      line-height: 1em;
      margin-top: 0; } }

  .email {
    font-size: 14px;
    margin-top: 5px;
    word-break: break-word; }

  .full-name {
    display: inline-block;
    font-size: 18px;
    font-weight: 500;
    word-break: break-word; } }

#user-profile {
  label {
    color: #666666;
    display: block; }

  > hr {
    margin: 35px 0; }

  .disclaimer-block-text {
    margin-bottom: 16px; }

  .section-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    h2 {
      display: flex;
      align-items: center; } }

  .control-label:after {
    content: "*";
    color: red; }

  .section-field {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
    .section-input-field-heading {
      flex-shrink: 0;
      padding-top: 12px;
      width: 200px; }
    .section-input-field {
      flex-grow: 1; }
    .section-input-prefix-field {
      display: flex;
      > div:first-child {
        margin-right: 8px; } }
    .section-input-pronouns-field {
      display: flex;
      align-items: center;
      margin-top: 16px;
      > div {
        flex-grow: 1;
        margin-right: 16px;
        &:last-child {
          margin-right: 0; } } }
    .section-input-switch-field {
      display: inline-flex;
      align-items: center;
      flex-grow: 1; } }

  .section-field-button {
    align-items: center;
    border: 1px solid #dde1e5;
    cursor: pointer;
    justify-content: space-between;
    padding: 15px;
    label {
      cursor: inherit; } }

  .section-field-selection {
    justify-content: space-between; }

  .italicized-description {
    font-style: italic; }

  .show-more-topics-option {
    text-align: center; }

  @media screen and (min-width: 600px) {
    .section-field {
      flex-wrap: nowrap; } }

  #subscribed-tags-bubbles {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 30px;
    .tag-group {
      flex-basis: 33.333333%;
      margin-bottom: 20px;
      min-width: 150px;
      padding-right: 20px;
      box-sizing: border-box; }
    .tag-group-name {
      color: #666666;
      font-size: 14px;
      font-weight: 500;
      margin: 5px 0; }
    .tag-bubble {
      font-size: 12px;
      margin: 5px 0;
      > div {
        display: inline-block;
        background-color: #dde1e5;
        padding: 6px 12px;
        border-radius: 24px; } }
    .tag-bubble-end {
      margin-bottom: 0; }
    .chip-styling {
      margin-right: 5px;
      margin-bottom: 8px; } } }

.my-profile-confirmation-dialog {

  .my-profile-confirmation-dialog-paper {
    max-width: 450px;
    padding: 10px 0; }

  .dialog-field {
    margin: 16px 0; } }

.my-profile-dialog {

  .my-profile-dialog-drawer {
    width: 450px;

    > hr {
      margin: 15px 0; }

    .my-profile-dialog-header {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 500;
      padding: 15px 0 5px 0; }

    .dialog-field {
      margin: 16px 0;
      padding: 0 16px;
      .dialog-field-heading {
        label {
          display: block;
          color: #666666; } } }

    .dialog-switch-field {
      display: flex;
      justify-content: space-between; }

    .tags-list-checkbox {
      margin-top: -6px;
      margin-bottom: -6px;
      min-width: 32px; }

    .tags-list-tag-group-text {
      color: #666666;
      font-weight: 500; }

    .tags-list-tags {

      > div {
        padding-left: 50px; }

      .restricted-tag-icon {
        color: #a52a2a; } }

    .reset-dialog {
      padding: 0 8px 15px 8px;
      button {
        font-weight: 400;
        text-transform: none; } } } }

#phoneField {
  .react-tel-input {
    .selected-flag:focus:before {
      border-color: none !important;
      box-shadow: none !important; }
    .country-list {
      max-height: 300px; } }

  .phone-input-adjustments {
    height: 20px;
    textDecoration: underline;
    background: transparent;
    fontSize: 11px; } }
