#readability-settings {
    display: flex;
    flex-direction: column;

    .readability-setting-container {
        margin-bottom: 24px;

        .readability-select {
            height: 40;
            padding: 11px; } } }
