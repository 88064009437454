.indicator {
    width: 100%;

    .score-label-container {
        margin-top: 2px;
        width: 100%;
        display: flex;
        justify-content: center;

        .score-label {
            margin: 0;
            font-size: 10px;
            width: 50%;
            color: #3b78ab;
            text-align: center; }

        .score-icon-container {
            width: 100%; } }

    .start-end-labels {
        display: flex;
        width: 100%;
        justify-content: space-between;
        position: relative;
        bottom: 10px;
        margin-bottom: 20px;

        .label {
            font-size: 12px; } }

    .progress-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;

        .vertical-line {
            border-left: solid 1px #dde1e5;
            height: 29px;
            position: relative;
            bottom: 5px; }

        .progress {
            height: 15px;
            flex-basis: 50%;

            &.empty {
                background-color: #f4f4f5; }

            &.left {
                border-bottom-right-radius: 8px;
                border-top-right-radius: 8px;
                transform: rotate(180deg);
                border-left: none;

                .filled {
                    border-top-left-radius: 0;
                    border-top-left-radius: 0;
                    border-top-right-radius: 7px;
                    border-bottom-right-radius: 7px; } }

            &.right {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
                border-left: none;

                .filled {
                    border-top-right-radius: 7px;
                    border-bottom-right-radius: 7px; } } } } }
