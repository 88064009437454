.editing-form {
    margin: 16px;
    width: 100%;
    max-width: 805px;
    box-sizing: border-box;
    float: left; }

.edit-post-title {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    font-size: 32px;
    line-height: 42px;
    font-weight: bold;
    outline: none;
    box-shadow: none;
    background: transparent;
    border: none;
    border-bottom: solid 1px #aaa;
    margin: 2px 0px;
    resize: vertical;
    font-family: 'Open Sans', sans-serif;
    overflow: hidden;

    &:hover {
        border-bottom: solid 1px #81afd4; }

    &:focus {
        border-bottom: solid 1px #2196f3; } }

.creation-form-section {
    background: #fff;
    padding: 10px 16px;
    margin-bottom: 15px;
    clear: both;
    position: relative;
    box-shadow: 0px 3px 5px -2px rgba(0, 0, 0, 0.32); }

.settings-group {
    margin-top: 24px; }

.button {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 2px;
    padding: 8px 22px;
    font-size: 14px;
    cursor: pointer;
    box-shadow: 0 2px 3px rgba(0,0,0,0.1);
    margin-right: 15px;
    transition: all 0.25s ease-in;
    &.action {
        background: #01b5fa;
        border: 1px solid #00adff;
        color: #fff; }

    &:hover {
        box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.1); } }

.save-button {
    @extend .button;
    background-color: #01b5fa;
    color: white; }

.delete-button {
    @extend .button; }

.author-wrapper {
    display: inline-flex;
    width: 100%;
    .author-field {
        padding: 6px;
        width: 100%; }
    .author-field-textfield {
        font-weight: bold; } }

.release-wrapper {
    width: 30%;
    padding: 10px; }

$grey: #f3f3f4;

.langBtn {
    background-color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: black;
    padding: 10px 0;
    font-size: 13px;
    display: inline-block;
    position: relative;
    width: 25%;
    text-align: center;
    cursor: default;
    border: 2px solid $grey;
    margin-right: 10px;

    &:nth-of-type(2n) {} }

.langBtn-selected {
    font-weight: bold;
    background-color: $grey;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px; }

.ml-container {
    border: 10px solid $grey;
    padding: 10px; }

.second {
    border-color: $grey; }

.dropdown {
    position: relative;
    display: inline-block; }

.dropdown-content {
    display: none;
    position: absolute;
    left: -100px;
    top: 34px;
    background-color: #939598;
    min-width: 250px;
    z-index: 1;
    span {
        color: white;
        padding: 8px;
        margin: 5px 0;
        text-decoration: none;
        display: block;
        &:hover {
            background-color: #ddd; } } }

.dropdown:hover ~ .dropdown-content {
    display: block; }

.dropdown-content:hover {
    display: block; }

.post-tab-command {
  position: relative;
  display: inline-block;
  margin: 0 15px;

  .post-tab-command-list {
    width: 264px;
    position: absolute;
    left: 1px;
    right: 1px;
    background-color: #ffffff;
    box-shadow: 0 0 4px #aaaaaa;
    margin-top: 1px;
    z-index: 1202; // one more than the post settings panel

    .tab-command-list-item {
      white-space: nowrap; } } }

.preview-button {
    color: #3b78ab;
    padding: 0;
    margin-right: 23px; }

.discard-button {
    margin-right: 21px;
    padding: 0; }

.save-as-draft-button {
    margin-right: 18px !important; }

.post-preview {
    display: flex;
    justify-content: center; }

#smart-post-authoring-fab {
    position: absolute;
    bottom: 30px;

    &.open {
        right: 495px !important;
        transition: right 500ms cubic-bezier(0, 0, 0.2, 1) 0ms !important; }

    &.closed {
        right: 58px !important;
        transition:  right 500ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important; } }
