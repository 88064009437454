$blueBar: #337AB7;
$firstBar: #337AB7;
$secondBar: #E6911A;
.bar-chart-widget, .line-chart-widget {
    g {
        .bar {
            fill: $blueBar; }
        text.label {
            fill: #000000;
            font: 13px sans-serif;
            text-anchor: start;
            width: 150px;
            position: relative; }
        text.value {
            fill: #393939;
            font: 10px sans-serif; }
        &.is-zero-width {
            text.value {
                fill: #393939; } }
        &.has-action {
            cursor: pointer; }
        & .bar:hover {
            opacity: 0.75; } }
    &.top-posts {
        height: 210px; } }
.dashboard-chart-widget {
    g {
        .bar {
            fill: $blueBar;
            &:hover {
                opacity: 0.7; } } }
    &.yellow-chart {
        g .bar {
            fill: $firstBar; }
        g .barSecond {
            fill: $secondBar; }
        &.bar-text {
            color: $firstBar; }
        &.bar-textSecond {
            color: $secondBar; } }

    &.orange-chart {
        g .bar {
            fill: #F5A623; } }

    &.blue-chart {
        g .bar {
            fill: #F5A623; } } }
.users-not-enough-data {
    text-align: center; }
.tags-chart-widget {
    g {
        cursor: pointer;
        .bar {
            fill: #9c27b0;
            &:hover {
                opacity: 0.7; } } } }

.reactions-chart-widget {
    g {
        .bar {
            fill: #3F51B5;
            &:hover {
                opacity: 0.7; } } } }

.line {
    fill: none;
    stroke: rgba(0, 86, 179, 1);// #3596ff
    stroke-width: 2px; }

.lineSecond {
    fill: none;
    stroke: rgba(0, 137, 123, 1);
    stroke-width: 2px; }

.area {
    fill:  rgba(51, 122, 183, 1);// #337AB7
    stroke-width: 1px; }
.areaSecond {
    fill: rgba(216, 243, 240, 1); } // rgba(129, 212, 250, 1)

.hover-line {
    stroke-width: 2px;
    //fill: red
    stroke-dasharray: 4px;
 }    // @include dots(#17BF7D, 7px)
.hover-box {
    fill: rgba(216, 243, 240, 0.1); }

.hover-text-active-users {
    fill: rgba(0, 86, 179, 1);
    font-weight: bold;
    border-bottom: 4px; }

.hover-text-registered-users {
    font-weight: bold;
    fill: rgba(0, 137, 123, 1); }

.hover-text-date {
    font-weight: bold;
    fill: rgba(0, 0, 0, 0.8); }

.reads-chart-widget {
	g {
		.bar {
			fill: $blueBar;
			&:hover {
                opacity: 0.7; } } } }
.app-usage {
    g {
        path {
            &:hover {
                opacity: 0.7; } } } }
.x-axis {
    g.tick {
        text {
            transform: translate(15px, 10px) rotate(45deg); } } }

.user-trend {
    padding-top: 15px;
    position: relative;
    min-height: 160px;
    height: 160px;
    .last-month {
        width: 140px;
        padding-left: 10px;
        position: absolute; }
    .user-trend-description {
        padding-top: 10px;
        margin-left: 185px;
        font-size: 14px;
        text-align: left;
        p {
            margin-bottom: 0; }
        .user-trend-description-icon-wrapper {
            text-align: center;
            .material-icons {
                font-size: 64px;
                color: #ddd; } } }
    .user-trend-circle {
        width: 100px;
        height: 100px;
        text-align: center;
        line-height: 100px;
        border-radius: 50%;
        border: 20px solid #00bcd4;
        font-size: 54px;
        &.trending-up {
            border-color: #009688; }
        &.trending-down {
            border-color: #f44336; } }
    .user-trend-circle-footer {
        padding: 5px 0;
        text-align: center;
        font-size: 14px; } }

.app-usage {
    position: relative;
    height: 160px;
    padding-top: 15px;
    .chart-wrapper {
        position: absolute;
        padding-left: 10px; }
    .legend {
        margin-left: 185px;
        padding-top: 15px;
        header {
            font-size: 10px !important;
            font-weight: 400 !important;
            padding: 0 !important;
            margin-bottom: 0;
            color: #666; }
        ul {
            list-style: none;
            padding-left: 5px !important;
            margin: 0;
            li {
                line-height: 22px;
                font-size: 13px; } }
        .color-indicator {
            display: inline-block;
            width: 15px;
            height: 15px;
            margin-right: 5px;
            position: relative;
            top: 2px; } } }

.post-awareness {
    position: relative;
    padding: 0;
    .awareness-graph-wrapper {
        padding-left: 15px;
        .line {
            fill: none;
            stroke: #2196F3;
            stroke-width: 2px; }
        .area {
            fill: rgba(33, 150, 243, 0.15); } }
    .awareness-badge {
        position: absolute;
        right: 15px;
        top: 50px;
        .awareness-circle {
            width: 100px;
            height: 100px;
            text-align: center;
            line-height: 100px;
            border-radius: 50%;
            border: 20px solid #ff9800;
            font-size: 54px;
            &.high {
                border-color: #8bc34a; }
            &.low {
                border-color: #f44336; } }
        .awareness-circle-footer {
            padding: 5px 0;
            text-align: center;
            font-size: 14px; } } }

.post-content-reset-styles {
    background: rgba(243, 243, 244, 0.24);
    border: 1px solid #ddd;
    padding: 15px;
    h1 {
        font-size: 22px; }
    h2 {
        font-size: 20px; }
    h3, h4, h5, h6 {
        font-size: 18px; } }

.bar-group {
    .top-label {
        opacity: 0;
        transition: 1s;
        transition-delay: 1s; }
    &:hover {
        .top-label {
            opacity: 1;
            transition: 0.3s;
            transition-delay: 0.1s; } } }
