.clearfix {
    display: block;
    content: "";
    clear: both; }

.relative-position {
    position: relative;
    background-color: #8699AC;
    height: 34px; }
.fixed-position {
    position: fixed;
    top: 0;
    left: 225px;
    right: 0;
    width: auto;
    z-index: 8;
    background-color: #8699AC;
    height: 34px; }

.page-tool-bar {
    width: 100%;
    display: inline-block;
    .tool-bar-buttons-wrapper {
        padding-top: 5px;
        .context-buttons {
            &.left-side {
                float: left;
                margin-left: 10px; }
            &.overflow {
                float: left;
                margin-left: 15px; }
            .button {
                padding: 0px 8px 5px 5px;
                font-size: 15px;
                font-weight: bold;
                color: #ffffff;
                box-shadow: none;
                outline: none;
                border: none;
                background: none; }
            .not-active {
                color: #BFC4C8;
                cursor: default;
                opacity: 0.5; } }
        .call-to-action-button {
            color: #ffffff;
            font-size: 15px;
            font-weight: bold;
            cursor: pointer;
            outline: none;
            padding-top: 2px;
            margin-left: 30px; }
        .refresh-button {
            float: right;
            margin-right: 75px;
            font-size: 15px;
            outline: none;
            cursor: pointer;
            font-weight: bold;
            color: #ffffff; } }


    .overflow-context-menu {
        position: absolute;
        z-index: 8;
        top: 30px;
        width: 180px;
        height: 40px;
        padding: 8px 16px;
        background-color: #fcfcfc;
        border-bottom: solid 1px #ccc;
        box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.25);
        box-sizing: border-box;
        cursor: pointer;
        .menu-button {
            box-sizing: border-box;
            color: #666666;
            text-decoration: none; } } }
