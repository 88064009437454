div.analytics-dashboard {
	min-height: 500px;
	padding: 15px;

	div.row {
		padding: 15px; }
	header {
		padding: 10px;
		font-weight: bold;
		font-size: 18px;
		background: #fff;
		color: #2f4050;
		.analytics-widge-top-icon {
				float: left;
				padding-right: 8px; } }
	div.body {
		position: relative;
		box-sizing: border-box; }
	ul {
		padding-left: 16px; }
	footer {
		font-size: 12px;
		width: 100%;
		box-sizing: border-box;
		a {
			cursor: pointer;
			display: inline-block;
			border-radius: 3px;
			color: #3B78AB; } }
	&.tips {
		header {
			background: #FF9800; }
		background: rgba(255,152,0,0.95);
		color: $sparrow-color-white; }
	table {
		width: 100%;
		border-collapse: collapse;
		td {
			padding: 5px 3px; }
		td.num, th.num {
			text-align: right; }
		tbody tr:nth-child(even) {
			background: #f9f9f9; } } }

.analytics-widget {
	width: 100%;
	box-sizing: border-box;
	border: 1px solid #dde1e5;
	position: relative;
	font-size: 12px;
	margin-bottom: 10px;
	margin-right: 10px;
	overflow: hidden;
	padding: 10px 15px;
	height: 280px;
	border-radius: 2px;
	background: #ffffff;
	&.placeholder {
		margin-left: 40px;
		opacity: 0.4;
		font-size: 18px;
		color: #797979; }

	div.header {
		margin-bottom: 10px;
		font-size: 16px;
		font-weight: 700;
		min-width: 300px;

		.tooltip-placement {
			position: relative;
			top: -4px; } }

	div.number-wrapper {
		padding: 20px 10px 15px 10px;
		margin: 10px 0px;

		.title {
			font-size: 13px;
			margin-top: 0px; } } }

div.post-stats, div.page-body {
	padding: 0;
	ul.tag-list {
		list-style: none;
		padding: 0;
		li {
			float: left; }
		span.tag {
			display: inline-block;
			padding: 5px;
			background: #2196f3;
			color: #fff;
			margin-right: 5px; } } }

div.subtle-tag-list {
	position: relative;
	top: -2px; }
a.subtle-tag {
	color: $sparrow-color-dark;
	font-size: 12px;
	background: #fff;
	border: 1px solid #ddd;
	border-radius: 2px;
	margin-right: 5px;
	padding: 1px 5px;
	cursor: pointer; }

span.badge-small {
	background: #607d8b;
	color: $sparrow-color-white;
	padding: 1px 2px;
	border-radius: 2px;
	margin-right: 10px;
	display: inline-block;
	font-size: smaller; }
div.row {
	position: relative;
	&:after {
		clear: both; } }
div.widget-wrapper-33 {
	position: relative;
	width: 33.3%;
	float: left;
	box-sizing: border-box;
	padding-right: 10px; }
div.widget-wrapper-66 {
	position: relative;
	width: 66.67%;
	float: right;
	box-sizing: border-box;
	padding-right: 0; }
div.widget-wrapper-63 {
	position: relative;
	width: 33.3%;
	float: left;
	box-sizing: border-box;
	padding-right: 0; }
div.col-50 {
	position: relative;
	width: calc(50%);
	float: left;
	box-sizing: border-box;
	padding: 3px; }
div.widget-wrapper-501 {
	position: relative;
	width: 50%;
	float: left;
	box-sizing: border-box;
	padding-right: 8px; }
div.widget-wrapper-502 {
	position: relative;
	width: 50%;
	float: right;
	box-sizing: border-box;
	padding-left: 8px; }
div.widget-wrapper-100 {
	position: relative;
	width: 100%;
	float: left;
	box-sizing: border-box;
	padding-top: 15px;
	padding-bottom: 15px;
	padding-right: 0; }

div.col-100 {
	position: relative;
	width: 100%;
	box-sizing: border-box;
	padding: 3px; }

@media screen and (max-width: 989px) {
	div.widget-wrapper-33, div.widget-wrapper-66 {
		padding-right: 0;
		float: none;
		min-width: 0;
		width: 100%; } }

.hl-stats-wrapper {
	overflow: hidden;
	background: #efefef;
	box-shadow: inset 0px 0px 12px -5px black; }
.hl-stats {
	padding: 15px;
	margin: 0 auto;
	margin-top: 25px;
	width: 80%; }
.hl-stat {
	width: 150px;
	float: left;
	margin-right: 20px;
	margin-bottom: 20px; }
.hl-stat-loading {
	opacity: 0.5; }
.hl-stat-circle {
	width: 110px;
	height: 110px;
	position: relative;
	text-align: center;
	line-height: 110px;
	border-radius: 50%;
	border: 20px solid #03a9f4;
	font-size: 36px;
	&.trending-up, &.good {
		border-color: #8bc34a; }
	&.trending-down, &.bad {
		border-color: #f44336; } }
.hl-stat-neutral {
	.hl-stat-circle {
		border-color: #607d8b; } }
.hl-stat-good {
	.hl-stat-circle {
		border-color: #8bc34a; } }
.hl-stat-bad {
	.hl-stat-circle {
		border-color: #FF9800; } }
.hl-stat-circle-footer {
	padding: 5px 0;
	text-align: center;
	font-size: 18px;
	.mandatory-note {
		font-size: 12px;
		color: #696969; } }

@media screen and (min-width: 1080px) {
	div.post-detail .hl-stat {
		width: calc(20% - 20px);
		.hl-stat-circle {
			margin: 0 auto; } } }
.post-detail {
	padding-bottom: 100px; }


@media(max-width: 768px) {
    .tag-detail-item {
        width: calc(100% - 20px); } }

.read-dropoff-graph-wrapper {
	padding: 0 0 0 25px;
	span.xLabel {
		transform: translate(40px, -25px);
		display: inline-block;
		font-size: 12px; }
	span.yLabel {
		transform: rotate(-90deg) translate(115px, -125px);
		display: inline-block;
		font-size: 12px; }
	.line {
		fill: none;
		stroke: #607D8B;
		stroke-width: 2px; }
	.area {
		fill: rgba(96, 125, 139, 0.15); } }

.post-stats {
	.post-awareness {
		width: 50%;
		float: left;
		span.xLabel {
			transform: translate(50px, -20px);
			display: inline-block;
			font-size: 12px; } }
	.post-reactions {
		width: 50%;
		float: left;
		.body p {
			padding-left: 15px; }
		&:after {
			content: '';
			clear: both; } }
	&.analysis {
		float: left;
		padding-right: 75px; } }



.post-content-dropoff {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	margin-bottom: 50px;
	padding: 15px;
	.post-content-dropoff-content {
		width: 560px;
		padding: 0;
		box-sizing: border-box;
		border: 5px solid rgba(47, 64, 80, 0.73);
		border-radius: 5px;
		border-bottom-width: 50px;
		margin-bottom: -35px;
		z-index: 1;
		header {
			padding: 15px !important;
			span.author {
				color: #ddd; }
			h3 {
				margin: 0;
				padding: 0 !important; } }
		.post-content-reset-styles {
			padding: 15px;
			background: none;
			border: none;
			h1, h2, h3, h4, h5, h6 {
				padding-left: 0; } } }
	.post-content-dropoff-tips {
		width: calc(100% - 562px);
		position: relative;
		min-height: 100px;
		.post-content-dropoff-tip {
			position: absolute;
			background: #ddd;
			color: #fff;
			padding: 5px;
			filter: drop-shadow(0px 2px 2px rgba(0,0,0,0.2));
			z-index: 2;
			&:before {
				right: 100%;
				top: 50%;
				border: solid transparent;
				content: " ";
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				border-color: rgba(194, 225, 245, 0);
				border-right-color: #c2e1f5;
				border-width: 12px;
				margin-top: -12px; } }
		.tip-25 {
			bottom: 75%;
			background: #ff5722;
			&:before {
				border-right-color: #ff5722; } }
		.tip-50 {
			background: #ff9800;
			bottom: 50%;
			&:before {
				border-right-color: #ff9800; } }
		.tip-75 {
			background: #ffc107;
			bottom: 25%;
			&:before {
				border-right-color: #ffc107; } }
		.tip-100 {
			background: #8bc34a;
			bottom: 0;
			&:before {
				border-right-color: #8bc34a; } } } }

div.all-tags {
	background: #fff;
	padding-bottom: 100px;
	header {
		padding: 25px 25px;
		background: #fff;
		h2 {
			margin-top: 0;
			margin-bottom: 0; } }
	.page-controls {
		width: 90%;
		margin: 0 auto;
		padding-top: 15px;
		input {
			width: 200px;
			margin-left: 45px; }
		.add-btn {
			float: right;
			margin-bottom: 15px; } }
	.fetching-message {
		margin: 0 45px; } }

table.tag-list {
	width: 92%;
	margin: 0 auto;
	border: 1px solid #ddd;
	border-collapse: separate;
	border-spacing: 0;
	border-radius: 3px;
	background: #fff;
	tr {
		background-color: #fcfcfc; }
	th {
		padding: 10px; }
	th.empty {
		width: 75%; }
	td.num, th.num {
		text-align: right;
		padding-right: 50px; }
	td.date, th.date {
		width: 120px;
		text-align: left; } }

.downloadIcon {
	position: absolute;
	right: 0;
	margin-right: 5px; }
