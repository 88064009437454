$sparrow-blue: #2196f3;
$placeholder-color: #a7a7ab;

.user-insights-wrapper {
    border: 1px solid #dde1e5;
    box-sizing: border-box;
    height: 100%;
    padding: 20px;
    width: 100%;

    h2 {
        align-items: center;
        display: flex;
        margin-bottom: 20px; } }

.column-insights {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    min-height: 0; }

.column-insights-small {
    display: flex;
    flex-wrap: wrap;
    min-height: 0; }

.column-insights-tiny {
    display: flex;
    flex-wrap: wrap;
    min-height: 0; }

.block-container {
    background: rgba(252, 252, 249, 1);
    flex: 1 1 33%;
    margin: 5px;
    border-radius: 5px;
    -moz-box-shadow: 0 0 4px black;
    -webkit-box-shadow: 0 0 4px black;
    box-shadow: 0 0 5px #b8b8b8;
    text-align: center;
    max-width: 200px;
    height: 130px;
    min-width: 150px;
    display: flex;
    flex-direction: column;
    padding: 5px; }

.insights-stats {
    font-weight: bold;
    font-size: 30px;
    height: 75px;
    line-height: 95px; }

.insights-title {
    font-size: 12px; }


.block-container-small {
    background: rgba(252, 252, 249, 1);
    flex: 1 1;
    margin: 5px;
    border-radius: 5px;
    -moz-box-shadow: 0 0 4px black;
    -webkit-box-shadow: 0 0 4px black;
    box-shadow: 0 0 5px #b8b8b8;
    text-align: center;
    height: 150px;
    max-width: 190px;
    display: flex;
    flex-direction: column;
    padding: 5px;
    justify-content: center; }

.insights-stats-small {
    font-weight: bold;
    font-size: 30px; }

.insights-title-small {
    font-size: 14px; }

.block-container-tiny {
    background: rgba(252, 252, 249, 1);
    flex: 1 1;
    margin: 5px;
    margin-right: 10px;
    border-radius: 5px;
    -moz-box-shadow: 0 0 4px black;
    -webkit-box-shadow: 0 0 4px black;
    box-shadow: 0 0 5px #b8b8b8;
    text-align: center;
    height: 110px;
    max-width: 160px;
    min-width: 120px;
    display: flex;
    flex-direction: column;
    padding: 5px;
    justify-content: center; }

.insights-stats-tiny {
    font-weight: bold;
    font-size: 30px; }

.insights-title-tiny {
    font-size: 14px; }

.block-container-messaging-center {
    background: rgba(252, 252, 249, 1);
    flex: 1 1;
    margin: 5px;
    border-radius: 5px;
    -moz-box-shadow: 0 0 4px black;
    -webkit-box-shadow: 0 0 4px black;
    box-shadow: 0 0 5px #b8b8b8;
    text-align: center;
    max-width: 200px;
    max-height: 150px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 5px; }


.insights-stats-messaging-center {
    font-weight: bold;
    font-size: 30px;
    height: 80px;
    line-height: 100px; }

.toolbox-button {
    height: 50px;
    line-height: 0px;
    width: 290px;
    text-align: left;
    -moz-box-shadow: 0 0 4px black;
    -webkit-box-shadow: 0 0 4px black;
    box-shadow: 0 0 5px #b8b8b8;
    margin: 5px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    background: white;
    font-size: 15px; }


.toolbox-button-wrapper {
    display: flex;
    flex-direction: column; }

.toolbox-container {
    background: #f5f5f5;
    padding: 10px;
    width: 300px;
    border-radius: 5px; }

.toolbox-title {
    font-weight: bold;
    font-size: 20px;
    padding: 10px; }

.overview-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
    gap: 20px; }

.route-link {
    text-align: right;
    font-size: 13px;
    color: $sparrow-blue;
    font-weight: 500;
    border: none;
    box-shadow: none;
    cursor: pointer;
    user-select: none; }

.download-user-data-btn {
    width: 13%;
    float: right;
    margin-top: 10px;
    background: white !important; }

.overview-insights {
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 30px;
    width: calc(50% - 15px); }

.insights-account-types {
    box-sizing: border-box;
    display: inline-block;
    width: calc(50% - 15px); }

.insights-platforms {
    height: 270px;
    width: 600px; }

.profile-page {
    display: grid;
    grid-template-columns: 35% 35%;
    grid-template-rows: auto;
    grid-template-areas: "header header" "left right" "left right" "left right";

    .profile-banner {
        display: flex;
        grid-area: header;
        height: 100px;
        margin: 20px;
        padding: 20px;
        border-radius: 10px;
        background: white;
        align-items: center;
        justify-content: space-between; } }

.profile-section {
    height: auto;
    background: white;
    margin: 20px;
    padding: 20px;
    border-radius: 10px;

    .left {
        grid-area: left; }

    .right {
        grid-area: right; } }

.audience-selector-hidden {
    position: absolute;
    right: -450px;
    -webkit-animation: slideout 0.5s;
    -moz-animation: slideout 0.5s;
    animation: slideout 0.5s;
    display: none; }

.audience-selector-visible {
    position: absolute;
    -webkit-animation: slidein 0.5s;
    -moz-animation: slidein 0.5s;
    animation: slidein 0.5s;
    right: 0px;
    display: block;
    z-index: 999; }

@-webkit-keyframes slidein {
    from {
        right: -450px;
        opacity: 0.5; }
    to {
        right: 0px;
        opacity: 1; } }

@-moz-keyframes slidein {
    from {
        right: -450px;
        opacity: 0.5; }
    to {
        right: 0px;
        opacity: 1; } }

@keyframes slidein {
    from {
        right: -450px;
        opacity: 0.5; }
    to {
        right: 0px;
        opacity: 1; } }


@-webkit-keyframes slideout {
    from {
        right: 0px;
        opacity: 1; }
    to {
        right: -450px;
        opacity: 0.5; } }

@-moz-keyframes slideout {
    from {
        right: 0px;
        opacity: 1; }
    to {
        right: -450px;
        opacity: 0.5; } }

@keyframes slideout {
    from {
        right: 0px;
        opacity: 1; }
    to {
        right: -450px;
        opacity: 0.5; } }

.description-text {
    font-size: 14px;
    color: #7C7C7C; }

.clearfix::after {
    content: "";
    clear: both;
    display: table; }

.profile-input-label {
    margin-right: 10px;
    width: 15%;
    display: inline-block;
    font-weight: 500;
    font-size: 16px;
    float: left;
    overflow-wrap: break-word; }

.profile-input-label-wide {
    margin-right: 10px;
    width: 40%;
    display: inline-block;
    font-weight: 600;
    font-size: 13px;
    float: left; }

.profile-input-field {
    width: 100%;
    border: 1px solid #bfbfbf;
    border-radius: 3px;
    height: 30px;
    padding-left: 10px;
    color: #414141; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }

::-webkit-input-placeholder {
    color: $placeholder-color; }
::-moz-placeholder {
    color: $placeholder-color; }
::-ms-input-placeholder {
    color: $placeholder-color; }
::-moz-placeholder {
    color: $placeholder-color; }

.profile-input-rows {
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-around; }

.label-description-block {
    display: flex;
    flex-wrap: wrap; }

.label-description-block-double {
    display: flex;
    flex-direction: row;
    padding-bottom: 10px; }

.user-creation-header {
    content: "";
    clear: both;
    display: table; }

.button-no-background {
    border: none;
    box-shadow: none;
    color: #03a9f4;
    background: none;
    font-weight: 500;
    margin-right: 10px;
    padding: 10px;
    cursor: pointer; }

.user-input-section-title {
    margin-bottom: 20px;
    font-weight: bold; }

.row-insights {
    display: flex;
    margin-bottom: 30px;
    &:last-child {
        margin-bottom: 0; } }


.nivo-container {
    box-sizing: border-box;
    display: inline-block;
    width: calc(50% - 20px); }

.nivo-container-title {
    font-weight: 500; }


.profile-input-field-concat {
    border: 1px solid #bfbfbf;
    border-radius: 3px;
    height: 30px;
    padding-left: 10px;
    color: #414141;
    flex: 1; }

.label-description-concat-block {
    display: flex;
    overflow: hidden;
    flex: 2; }

.concat-value-field {
    display: inline-block;
    margin-right: 10px;
    line-height: 30px;
    padding: 0px 5px 0px 5px;
    border: 1px solid #A3A3A3;
    background: #F3F3F4;
    border-radius: 3px;
    max-height: 33px; }

.username-available-text {
    color: green;
    line-height: 30px;
    margin-left: 5px;
    font-weight: bold; }

.username-unavailable-text {
    color: red;
    line-height: 30px;
    margin-left: 5px;
    font-weight: bold; }

.topics-div {
    max-height: 100px;
    overflow: hidden; }

.topics-div expanded {
    overflow-y: auto;
    max-height: 1000px; }

.external-user-dialog-formatting {

  &.MuiDialogTitle-root {
    padding-bottom: 0;
    padding-top: 0;
    border-bottom: 1px solid #dde1e5; }

  .external-user-dialog-header-title {
    display: flex;
    justify-content: space-between;
    align-items: center; }

  button {
    margin-right: -16px; } }

.external-user-dialog-content {
    padding: 8px 24px;

    .icon-formatting {
        padding: 5px; }

    .user-dialog-description-text {
        padding: 7px;
        padding-left: 2px;
        font-size: 14px; }

    .invitations-section {
        display: flex;
        flex-direction: row;
        padding-top: 20px;

        .prompt-text {
            padding-top: 8px;
            font-size: 14px;
            min-width: 120px; } }

    .email-error-warning-text {
        color: red;
        font-size: 14px;
        position: relative;
        top: 5px;
        word-wrap: break-word; }

    .invitations-data-boxes {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 20px; }

    .permissions-fields-formatting {

        .switch-testing {
            background-color: red;
            float: right; } }

    .local-fields-formatting {
        padding-top: 15px;
        padding-bottom: 15px;
        overflow: auto;
        min-width: 500px;
        max-width: 880px;

        div {
            margin-bottom: 4px;
            display: flex;

            span {
                position: relative;
                top: 6px; }

            .textfield-formatting {
                width: 700px;
                margin-left: auto;

                .checkbox-styling-sparrow {
                    position: relative;
                    top: -9px;
                    left: -10px;
                    background-color: transparent; } }

            .textfield-formatting-smaller {
                width: 620px;
                margin-left: auto; } } } }

@media (max-width: 1200px) {
    .profile-page {
        grid-template-columns: 50% 50%; }
    .profile-input-label {
        width: 30%; }
    .profile-banner-left {
        font-size: .8rem; }
    .profile-input-label-wide {
        width: 60%; } }

@media (min-width: 1200px) {
    .profile-page {
        grid-template-columns: 50% 50%; }
    .profile-input-label {
        width: 25%; }
    .profile-input-label-wide {
        width: 60%; } }


@media (min-width: 1600px) {
    .profile-page {
        grid-template-columns: 35% 35%; }
    .profile-input-label {
        width: 16%; } }
