@import "common.sass";

.news-feed-section {
	.horizontal-card {
		flex-wrap: nowrap!important;
		overflow-x: auto;

		.event {
			max-width: 350px!important;
			min-width: 300px!important;
			margin-right: 15px; }

		.load-tile {
			align-content: center;
			justify-content: center; } }

	.horizontal-card, .stacked-card {
		.event {
			display: block!important;

			.image-container {
				width: 100%!important; } } } }
