#post-smart-content-keywords-suggestions {
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    #post-smart-content-keywords-content {
        display: flex;
        flex-direction: column;

        .prompt {
            margin-top: 12px;
            margin-bottom: 16px; }

        .label {
            margin-top: 22px;
            margin-bottom: 0; } } }
