#user-management-list {
  tbody tr {
    cursor: pointer; }

  .activity {
    background-color: transparent;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    > div {
      display: inline-flex;
      align-items: center;
      margin-right: 5px;
      width: 60px;
      &:last-child {
        margin-right: 0; } }
    svg {
      color: #888888;
      margin-right: 5px; } }

  .avatar {
    background-color: #dde1e5;
    border-radius: 50%;
    margin-right: 10px;
    .avatar-icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      width: 50px; }
    .avatar-letter {
      font-size: 14px;
      font-weight: 500;
      line-height: 1em;
      margin-top: 0; } }

  .name-email {
    display: flex;
    align-items: center;
    line-height: 1.2em;
    padding: 10px 0;
    .name {
      font-weight: 500; } } }
