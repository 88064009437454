.tableview {
    display: block;
    width: 94%;
    margin: 0 auto;
    padding: 0px;
    border: solid 1px #e0e0e0;
    border-radius: 4px;
    margin-bottom: 50px;
    .clearfix {
        clear: both;
        display: block;
        content: ""; }
    .tableview-row {
        width: 100%;
        padding: 10px 16px;
        border-bottom: solid 1px #e0e0e0;
        box-sizing: border-box;
        background-color: #fcfcfc;
        display: flex;
        flex-flow: row nowrap;
        cursor: pointer;

        &:nth-child(even) {
            background-color:#f4f4f4 {} }

        &:last-child {
            border-bottom: none; }

        &:after {
            @extend .clearfix; }

        &.selected {
            background-color: #dff1ff;
            background-color: #f3f3f3; }

        &:not(.tableview-headers):hover {
            background-color: rgba(177, 191, 203, 0.2); } }

    .tableview-column {
        position: relative;
        overflow: hidden;
        height: 1.6em;
        font-size: 14px;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 50px;

        .truncate {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 4px; }

        .clickTarget {
            position: absolute;
            width: 32px;
            height: 32px;
            transform: translate(-8px, -7px); } }

    .tableview-headers {
        @extend .tableview-row;
        font-weight: bold;
        background-color: #f4f4f4;
        padding-top: 16px;
        cursor: default;
        padding-bottom: 16px;
        color: #666;

        .tableview-header {
            @extend .tableview-column;
            .caret {
                font-size: 12px;
                position: absolute;
                top: 1px;
                margin-left: 4px;
                &.up {
                    top: 2px;
                    transform: scale(1, -1); } }
            &.published {
                cursor: pointer; }
            &:last-child {
                cursor: default; } } } }

.empty-row {
    text-align: center;
    margin-top: 10px;
    font-size: 16px; }
