#content-bands-button-container {
    padding-top: 25px;
    display: flex;
    flex-direction: column;

    #cb-support-text {
        max-width: 639px;
        font-size: 12px; }

    #content-bands-button-content {
        max-width: 639px;
        height: 62px;
        border-radius: 4px;
        border: 1px solid #dde1e5;
        display: inline-flex;
        align-items: center;
        padding: 19px 16px;
        box-sizing: border-box;

        .start-icon {
            margin: 0 3px 0 0; }

        .disabled-text {
            color: #aaaaaa; } } }
