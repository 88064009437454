
.items-table {
    border: 1px solid #ddd;
    margin: 0 auto;
    width: 95%;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 4px;
    td, th {
        border-bottom: 1px solid #ddd; }
    thead {
        background-color:#f4f4f4 {}
        cursor: pointer;
        height: 35px;
        text-align: center; }
    tr {
        height: 45px; }
    tr:nth-child(even) {
        background-color:#f4f4f4 {} }
    .item-info {
        width: 80%;
        .item-wrapper {
            padding: 10px;
            margin-left: 10px;
            margin-top: 5px; }
        .stats-wrapper {
            display: inline-block;
            padding-bottom: 10px;
            margin-left: 15px;
            label {
                font-weight: bold; }
            span {
                margin-left: 10px; } } }

    .actions {
        vertical-align: middle;

        .flagActionBtn {
            min-width: 170px;
            margin-bottom: 3px; } } }
