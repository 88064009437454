.portal-page-doc-feed {
    table-layout: fixed;

    .list {
        .document {

            .with-divider {
                border-bottom: solid thin lightgrey; }

            .file-icon {
                width: 40px; }

            .inline-banner {
                margin-right: 10px; }

            .one-line-text {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis; }

            .last-cell {
                vertical-align: bottom;
                text-align: end;
                width: 20%;

                .action-buttons {
                    display: flex;
                    justify-content: flex-end;
                    height: 30px; } } } } }
