.options-container {
    .MuiOutlinedInput-root {
        &:hover {
            fieldset {
                border-color: #3b78ab; } } }

    .required {
        &::after {
            content: "*";
            color: #b72026; } }

    .options-input-group {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        margin-top: 17px;
        justify-content: space-between;
        align-items: center; }

    .hover-label {
        width: 100px;
        margin-bottom: 5px; } }
