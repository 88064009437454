.mobile-app-banner {
    position: relative;
    z-index: 1301; // one more than MUI dialog component

    .app-bar {
        height: 48px;

        .tool-bar {
            height: 48px;
            min-height: 48px !important;
            display: flex;
            justify-content: space-between;
            align-items: center;

            #open-in-app-link {
                &:hover {
                    color: #ffffff;
                    text-decoration: none; } } } } }
