@import "common.sass";

#event-feed {
  .card {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -10px;

    .event {
      position: relative;
      display: inline-flex;
      background-color: #ffffff;
      border: 1px solid #dde1e5;
      box-sizing: border-box;
      margin-bottom: 15px;
      overflow: hidden;
      padding: 10px;
      width: 100%;
      cursor: pointer;
      &:hover {
        box-shadow: 0 0 5px 1px #dde1e5; } }

    .event-2 {
      min-width: calc(50% - 20px);
      &:nth-child(even) {
        margin-left: 0px;
        margin-right: 0px; } }

    .date-block {
      display: none; }

    .datetime {
      display: none; }

    .details {
      padding: 0 10px; }

    .full-datetime {
      margin: 10px 0; }

    .image {
      position: relative;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      padding-top: 56.25%;
      width: 100%; }

    .image-container {
      flex-shrink: 0;
      width: 40%; }

    .summary {
      display: none; } }

  @media screen and (min-width: 640px) {
    .card {
      .event {
        flex-direction: column;
        padding: 0; }

      .attendance {
        padding: 0 15px 10px 15px; }

      .date-block {
        display: inline-flex; }

      .datetime {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #888888;
        font-size: 12px; }

      .details {
        flex-grow: 1;
        padding: 10px 15px; }

      .full-datetime {
        display: none; }

      .image-container {
        width: 100%; }

      .summary {
        display: block;
        margin: 5px 0;
        text-overflow: ellipsis; }

      .title {
        margin: 10px 0; } } }

  @media screen and (min-width: 640px) and (max-width: 1023px) {
    .card {
      .event {
        flex-basis: calc(50% - 15.5px); }

      .event-3 {
        margin-left: 15px;
        margin-right: 0px; }

      .event-2 {
        min-width: calc(50% - 20px);
        &:nth-child(even) {
          margin-left: 15px;
          margin-right: 0px; } } } }


  @media screen and (min-width: 1024px) {
    .card {
      .event {
        flex-basis: calc(33.33333% - 15.5px); }

      .event-3 {
          margin-left: 15px;
          margin-right: 0px; }

      .event-2 {
        min-width: calc(50% - 20px);
        &:nth-child(even) {
          margin-left: 15px;
          margin-right: 0px; } }

      .date-block {
        font-size: 16px;

        .day {
          font-size: 38px; } } } }

  .card {
    .event {
      &.event-1 {
        min-width: 100%; } } } }
