.audience-sidebar {
    height: auto;
    width: 400px;
    background: white;
    border-radius: 5px;
    box-shadow: -2px 6px 10px 5px #e8e8e8;
    padding: 15px;
    max-height: 800px;
    overflow-y: scroll;
    overflow-x: hidden;
    position: fixed;
    right: 0;
    top: 98px;
    z-index: 999; }

.audience-displayname {
    font-size: 15px;
    margin-left: 10px; }

.sidebar-header::after {
    padding: 2px 0;
    content: "";
    clear: both;
    display: table; }

.reset-btn-no-border {
    border: none !important;
    box-shadow: none !important;
    color: #03a9f4;
    font-weight: 500;
    background: none;
    margin-right: 10px;
    padding: 10px;
    cursor: pointer; }

.apply-btn-2 {
    border: none;
    color: white;
    font-weight: 600;
    background: #03a9f4;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 3px;
    min-width: 75px;
    height: 36px; }

.disabled-btn-2 {
    border: none;
    color: white;
    font-weight: 600;
    background: #CCCCCC;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 3px; }

.cancel-btn-2 {
    border: none;
    color: white;
    font-weight: 600;
    background: red;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 3px; }

.audience-checkbox {
    margin-bottom: 10px; }


