#mobile-app-settings-page {
    .reset-btn {
        color: #666666; }

    .content {
        padding: 0px 24px;
        height: 100%;

        .base-form-group {
            display: flex;
            max-width: 638px;
            margin: 7px 0px;
            flex-wrap: wrap;

            &.home-input {
                max-width: 700px; }

            .select-container {
                display: flex;
                flex-direction: column;
                flex-basis: 389px;
                max-width: 389px;

                .select {
                    max-width: 390px; }

                .feed-control {
                    justify-content: space-between;
                    display: flex;
                    margin-left: 0px;
                    align-items: center;
                    max-width: 389px; } }

            .error {
                font-size: 13px;
                color: #b72026; } }

        .info-container {
            display: flex;
            align-items: center;
            margin: 30px 0px; }

        #home-screen-settings-actions {
            margin-bottom: 21px;
            margin-top: 10px; }

        .full-group {
            @extend .base-form-group;
            justify-content: space-between; }

        .half-group {
            @extend .base-form-group;
            justify-content: flex-end; }

        .info-label {
            font-size: 18px;
            font-weight: 500; } } }
