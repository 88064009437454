.user-item {
    .external-user-flag {
        padding: 2px 5px;
        font-size: 12px;
        background: #fff;
        border: 1px solid #ddd;
        border-radius: 2px;
        display: inline-block; }
    .external-user-disabled-flag {
        @extend .external-user-flag;
        background: #fff;
        color: #a00404;
        border: 1px solid rgba(160, 4, 4, 0.39);
        margin-left: 3px; }
    .avatar-wrapper {
        position: absolute;
        margin-left: 40px;
        margin-top: 10px; }
    .user-item-name {
        font-size: 16px;
        padding-top: 18px;
        text-align: left;
        margin-left: 140px;
        &.reports {
            padding-top: 25px;
            font-size: 18px; } }
    .user-item-roles {
        text-align: left;
        margin-left: 140px;
        font-size: 11px;
        color: #939598; }
    .user-item-contact {
        position: absolute;
        top: 55px;
        text-align: left;
        margin-left: 140px;
        font-size: 15px; } }

.users-page-stats {
    padding-top: 5px;
    font-size: 14px;
    padding-bottom: 8px; }

.users-wrapper {
    table {
        width: 98%;
        margin: 0 auto;
        margin-left: 10px;
        border-collapse: separate;
        border-spacing: 0;
        border-radius: 3px;
        tr {
            height: 45px; }
        th, td {
            text-align: center; }
        th:not(:first-child) {
            min-width: 50px; }
        thead {
            background: #f3f3f4;
            background-color:red {}
            cursor: pointer;
            height: 35px;
            &.reports {
                cursor: context-menu; }
            .caret-fix {
                position: relative;
                .caret {
                    font-size: 12px;
                    position: absolute;
                    top: 3px;
                    margin-left: 4px;
                    &.up {
                        transform: scale(1, -1); } } } }

        td {
            height: auto; }
        .empty-row {
            width: 98%;
            height: 50px; } } }

.user-item-wrapper {
    cursor: pointer;
    &:hover {
       background: rgba(228, 237, 250, 0.47); } }

.user-info-wrapper {
    height: 100px;
    position: relative;
    img {
        height: 80px; } }

.example-enter {
    opacity: 0.01;
    background-color: #DADADA; }

.example-enter.example-enter-active {
    opacity: 1;
    background-color: #fff;
    transition: all 250ms ease-in; }

.example-leave {
    opacity: 1; }

.example-leave.example-leave-active {
    opacity: 0.01;
    transition: all 100ms ease-in; }

.form-group.invite-user {
    .form-control {
        display: inline-block;
        width: 300px; }
    button {
        margin-left: 10px;
        padding: 6px 11px; } }

table.external-users {
    border: 1px solid #ddd;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 3px;
    &.disabled {
        pointer-events: none;
        a {
            color: #999999; } }
    th {
        pointer-events: none;
        font-weight: 500;
        background-color:#f4f4f4 {} }
    tr:nth-child(even) {
        background-color:#f4f4f4 {} }
    tr {
        &:hover {
            cursor: pointer;
            box-shadow: 0px 11px 4px -10px  rgba(0, 0, 0, 0.3) inset, 0px -11px 4px -10px  rgba(0, 0, 0, 0.3) inset; } }


    td {
        padding: 5px 0; }
    img.auth-provider-logo {
        width: 18px;
        border-radius: 3px;
        padding: 1px; } }

th.auth-provider-col, td.auth-provider-col {
    width: 32px;
    padding: 0 3px;
    text-align: center; }
th.actions, td.actions {
    width: 320px; }
th.email, td.email {
    width: 530px; }

div.date-and-time {
    display: inline-block;
    &.disabled {
        pointer-events: none;
        color: #999999; }
    span.date {
        font-size: 14px;
        display: block; }
    span.time {
        font-size: 12px;
        display: block;
        color: #595959; } }

div.upload-csv-instructions {
    font-size: 14px;
    span {
        color: #2196F3;
        cursor: pointer;
        &:hover {
            text-decoration: underline; } } }

table.external-lobby {
    border: 1px solid #ddd;
    width: 60%;
    margin-left: 32px;
    min-width: 1000px;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 3px;
    img.auth-provider-logo {
        width: 20px;
        border-radius: 3px;
        padding: 1px; }
    th {
        font-weight: bold;
        background-color: #f4f4f4;
        color: #666; }
    tr:nth-child(even) {
        background-color:#f4f4f4 {} } }

.download-button {
    background-color: #2f4050;
    color: #ffffff;
    border: none;
    outline: none;
    padding: 8px;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    border-radius: 3px;
    margin-left: 10px; }

.external-user-options {
    margin-left: 30px;
    border: 1px solid #aaa;
    border-radius: 3px;
    width: 745px;
    text-align: center;
    background-color: #fff;
    position: absolute;
    top: 150px;
    left: 22%;
    .header {
        background-color:#f4f4f4 {}
        font-weight: bold;
        color: #2f4050;
        line-height: 30px; }
    .option-row {
        display: flex;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 10px; }
    .option-button {
        border: none;
        outline: none;
        padding: 8px;
        width: 135px;
        cursor: pointer;
        display: inline-block;
        font-size: 15px;
        border-radius: 3px;
        float: left; }
    .close-btn {
        float: right;
        margin-right: 5px;
        cursor: pointer; } }


@media screen and (max-width: 1400px) {
    .user-item {
        .avatar-wrapper {
            display: none; }
        .user-item-name {
            margin-left: 15px;
            width: 100px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; }
        .user-item-roles {
            margin-left: 15px; }
        .user-item-contact {
            margin-left: 15px;
            a {
                display: inline-block;
                width: 200px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis; } } } }

.user-creation-page {
    margin: 20px; }

.single-user-creation-container {
    display: grid;
    grid-template-columns: 40% [col-60] 40% [col-40];
    grid-template-rows: auto;
    grid-template-areas: "left right" "left right"; }

.single-page-creation-section {
    background: white;
    margin: 20px 20px 5px 0px;
    padding: 20px;
    border-radius: 10px;
    height: auto;

    .left {
        grid-area: left; }

    .right {
        grid-area: right; } }

.bulk-user-creation-container {
    display: grid;
    grid-template-columns: 30% [col-60] 40% [col-40];
    grid-template-rows: auto;
    grid-template-areas: "left right" "left right"; }

.bulk-page-creation-section {
    background: white;
    margin: 20px 20px 5px 0px;
    padding: 20px;
    border-radius: 10px;
    height: auto;
    display: flex;
    flex-direction: column; }

.input-row {
    margin-bottom: 20px; }

.selected-users-bar {
    height: 40px;
    width: 98%;
    margin-left: 10px;
    color: #2f4050;
    line-height: 40px;
    font-size: 18px;
    background: #f3f3f4;
    font-weight: 600; }

.select-all-checkbox {
    height: 20px;
    width: 20px;
    text-align: center; }


.bulk-user-uploader {
    border: dashed 2px #ccc;
    height: 160px;
    transition: all 0.25s;
    margin: 15px;
    box-sizing: border-box;

    .bulk-user-centered-content {
        text-align: center;
        margin: 20px auto;
        //width: 400px
        position: relative;

        .progress-bar-wrapper {
            display: inline-block;
            width: 30%;
            padding: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; } }


    .large {
        font-size: 13px;
        font-weight: bold;
        transition: all 0.25s;

        span {
            color: #ccc;
            margin-left: 12px; } }

    .original-input {
        display: block;
        width: 66px;
        height: 25px;
        clip: rect(0px 0px 0px 0px);
        position: absolute;
        left: 0;
        top: 0; }

    .choose-file-button {
        display: inline-block;
        cursor: pointer;
        color: #888; }

    &.is-dragging {
        border: dashed 2px #03a9f4;

        .choose-file-button {
            color: #ccc; }

        .large span {
            color: #888; } } }

.invited-users-table {
    width: 100%;
    margin-top: 20px;
    overflow-y: auto;
    table-layout: fixed;
    border-spacing: 0;
    border-radius: 3px {
        padding-left: 20px; }

    tr {
        text-align: left; }

    th {
        padding-left: 20px;
        background: #f3f3f4;
        height: 45px;
        font-weight: 500; }

    td {
        padding-left: 20px;
        height: 50px;
        word-break: break-word; } }
