label.has-error,
input.has-error {
    color: #de6262; }

.settings-page {
    .white-settings-background {
        background: #ffffff;
        padding: 30px; }
    .color {
        .reset-btn {
            padding: 8px;
            font-size: small;
            margin-bottom: 8px; }
        .close-btn {
            float: right;
            font-size: 18px;
            margin-right: 65px;
            cursor: pointer;
            margin-top: 15px; }
        .banner {
            position: absolute;
            top: 15px;
            left: 250px; }
        .input-group {
            padding-top: 8px; } }
    table.table-border {
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-collapse: collapse;
        td {
            border-left: 1px solid rgba(0, 0, 0, 0.1);
            border-right: 1px solid rgba(0, 0, 0, 0.1); } }
    table.vertical-align-top {
        td {
            vertical-align: top; } }

    .form-group.group-hidden {
        opacity: 0;
        visibility: hidden;
        height: 0; }
    .form-group.group-visible {
        opacity: 1;
        visibility: visible;
        height: inherit; }
    button {
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 3px;
        padding: 8px 22px;
        font-size: 14px;
        cursor: pointer;
        box-shadow: 0 2px 3px rgba(0,0,0,0.1);
        margin-right: 15px;
        transition: all 0.25s ease-in;
        &:disabled {
            color: #ccc;
            cursor: default; }
        &.action {
            background: #01b5fa;
            border: 1px solid #00adff;
            color: #fff; } }
    .toggleswitch {
        float: none;
        padding: 5px 0; }
    table {
        border-collapse: collapse;
        th, td {
            padding: 5px; }
        thead {
            th {
                font-weight: 100;
                text-align: left;
                border-bottom: 1px solid #ddd; } } }

    span.icon-sentiment {
        background: #fff;
        display: inline-block;
        border-radius: 50%;
        padding: 5px;
        box-shadow: 0 2px 2px 2px rgba(0,0,0,0.15); }
    .sort-buttons {
        .sort-btn {
            background: none;
            border: none;
            box-shadow: none;
            padding: 0;
            display: block;
            color: #ddd;
            cursor: pointer;
            &:hover {
                color: #01b5fa; }
            &:disabled {
                background: #fff !important; } }
        &.first {
            .sort-up {
                color: #efefef; } }
        &.last {
            .sort-down {
                color: #efefef; } } }
    .btn-loading-spinner {
        position: absolute; }
    .error-msg {
        background: #f44336;
        padding: 15px;
        margin-bottom: 15px;
        border: 1px solid rgba(0,0,0,0.2);
        color: #fff; }

    .row {
        width: 100%;
        display: block;
        box-shadow: border-box;

        .col {
            float: left;
            display: block;
            box-sizing: border-box;
            margin-bottom: 12px; }

        .col-300 {
            @extend .col;
            width: 100%;
            max-width: 300px;
            padding: 5px; }

        .col-400 {
            @extend .col;
            width: 100%;
            max-width: 400px;
            padding: 5px; }

        &:after {
            clear: both;
            display: block;
            content: ""; } }

    .iphone-container {
        width: 260px; } }

.settings-page-actions {
    padding: 15px; }

.vertical-tab-ctrl {
    position: relative;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 2px;
    min-height: 500px;
    height: auto;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }

.vertical-tab-btns {
    width: 200px;
    margin: 0;
    min-height: 100%;
    position: relative;
    box-sizing: border-box;
    border-right: solid 1px #e5e5e5;
    padding-bottom: 20px;
    &.language {
        width: 275px; }
    ul {
        padding-left: 0;
        list-style: none;
        padding-bottom: 50px;
        li {
            margin-top: 15px; } } }

.vertical-tab-btn {
    position: relative;
    list-style-type: none;
    padding: 15px;
    cursor: pointer;
    -moz-user-select: none;
    -webkit-user-select: none;
    border-right: 0 solid #03a9f4;
    transition: border-right-width 0.1s ease-in;
    &.active {
        border-right: 3px solid #03a9f4; } }
.vertical-tabs {
    min-height: 400px; }
.vertical-tab {
    display: none;
    padding: 15px 25px 0;
    &.active {
        display: block; } }

button.delete-btn {
    position: absolute;
    right: 15px;
    top: 15px; }

button.translate-btn {
    position: absolute;
    right: 220px;
    top: 15px; }

.disclaimer {
    color: #888;
    font-size: 12px; }

.settings-input-field-sm {
    width: 40px;
    height: 31px;
    padding: 2px;
    border: 1px solid hsl(0, 0%, 80%);
    position: relative;
    bottom: 1px;
    border-radius: 5px;
    margin-left: 20px;
    text-align: center; }

.settings-input-field-select {
    display: inline-block;
    width: 120px;
    padding: 5px; }

.settings-input-field-select-long {
    display: inline-block;
    width: 360px;
    padding: 5px; }

.settings-input-label {
    width: 300px;
    display: inline-block; }

.tooltips-settings {
    padding-left: 5px; }

.input-label-text {
    font-size: 12px;
    margin-left: 5px; }

.input-text-padding {
    padding-top: 1%; }

.share-div-padding {
    padding-top: 2%; }

.share-text-styling {
    fontSize: 16px;
    padding-top: 2%; }

.settings-checkbox-section {

    .settings-checkbox-row {
        margin-bottom: 5px; }

    .settings-checkbox-radio {
        margin-bottom: 5px;
        position: relative;
        right: 16px; }

    .settings-checkbox-channel {
        margin-bottom: 5px;
        position: relative;
        right: 14px; }

    .settings-checkbox-orand {
        margin-bottom: 5px;
        position: relative;
        right: 1px; } }

.blackout-checkbox-single {
    margin-bottom: 20px;
    cursor: pointer; }

.blackout-checkbox-text {
    margin-bottom: 5px;
    font-size: 12px;
    margin-left: 5px;
    cursor: default; }

.pointer-text {
    cursor: pointer;
    color: #01b5fa; }
