svg.help-icon {
    color: #aaaaaa;
    margin-left: 8px; }

.help-icon-description,
.warning-icon-description {
    font-size: 12px;
    padding: 5px;
    pointer-events: auto;
    .emphasis {
        font-weight: 500; } }
