.language-bar-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 40px;
    margin-bottom: 17px;
    border-bottom: 1px solid #dde1e5;

    .language-select-button {
        color: #3b78ab;
        padding: 0;
        font-size: 14px;
        font-weight: 500;
        &:hover {
            background-color: transparent; } } }
