@import "common.sass";

.news-feed-section {
    .list {
        width: 100%;
        .date-block {
            position: initial!important;
            margin: 15px!important; }

        .attendance {
            padding: 0px!important; }

        .event {
            display: flex;
            cursor: pointer;
            borderBottom: 1px solid #dde1e5; }

        .first-line {
            display: flex;
            justify-content: space-between;

            .unread {
                height: fit-content; } } } }
