.app-header {
    width: 100%;
    display: block;
    box-sizing: border-box;
    background-color: #fff;
    border-bottom: 1px solid #dee1e2;
    height: 48px;
    line-height: 48px;
    top: 0;
    padding: 0 15px;
    .tenant-name {
        display: inline-block;
        font-size: 22px; }
    a.logout {
        float: right;
        font-size: 14px;
        color: #999c9e;
        text-decoration: none; }
    #locale-selector {
        float: right;
        margin: 15px; } }


