.audience-dialog {
  width: 800px;

  .audience-dialog-title {
    align-items: center;
    border-bottom: 1px solid #dde1e5;
    font-size: 20px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    .audience-dialog-actions {
      margin-bottom: -8px;
      margin-right: -16px;
      margin-top: -8px;
      button {
        margin-left: 8px; } } }

  .audience-dialog-content {
    .MuiSwitch-root {
      margin-left: -12px; }

    .audience-details {
      padding: 10px 0 20px 0;
      > div {
        display: flex;
        align-items: flex-start;
        margin: 10px 0;
        &:first-child {
          margin-top: 0; }
        &:last-child {
          margin-bottom: 0; }
        > div:first-child {
          align-items: center;
          display: inline-flex;
          flex-grow: 0;
          flex-shrink: 0;
          padding: 8px 0;
          width: 150px; }
        > div:last-child {
          align-items: center;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          min-height: 40px; } }
      .audience-type-description {
        font-size: 14px;
        margin-top: 5px; } }


    .no-search-results {
      color: #666666;
      margin: 15px 0; }

    .smart-rules {
      padding: 20px 0;

      > div {
        margin: 20px 0;
        &:first-child {
          margin-top: 0; }
        &:last-child {
          margin-bottom: 0; } }

      .new-rule {
        margin-left: -8px; }

      .rule-applicability {
        display: flex;
        > div:first-child {
          flex-grow: 0;
          flex-shrink: 0;
          padding: 8px 0;
          width: 175px; } }

      .rules {
        margin-bottom: 0;
        > div {
          position: relative;
          background-color: #f3f3f4;
          margin-bottom: 10px;
          padding: 20px 15px; }
        .remove-rule {
          position: absolute;
          top: 8px;
          right: 8px;
          color: #aaaaaa; }
        .rule-number {
          font-weight: 500;
          margin-bottom: 15px; }
        .rule-options {
          display: flex;
          align-items: center;
          margin: -5px;
          > div {
            margin: 5px; } } } }

    .standard-rules {
      .standard-rules-tabs {
        position: sticky;
        background-color: #ffffff;
        border-bottom: 1px solid #dde1e5;
        margin-bottom: 15px;
        margin-top: 10px;
        top: -8px;
        z-index: 2;
        button {
          text-transform: none;
          min-width: 160px; } }

      .disclaimer-block-text {
        margin-bottom: 10px; }

      .search-icon {
        fill: #666666;
        margin-right: 5px; }

      .stats-row {
        .number-wrapper {
          font-size: 26px;
          padding: 20px 5px;
          &:last-child {
            margin-right: 0; }
          .number {
            height: fit-content;
            margin-bottom: 8px; } }
        .title {
          font-size: 14px;
          line-height: 1.2em; } }

      .successfully-scanned {
        color: #366531;
        font-weight: 500; }

      .successfully-scanned-icon {
        color: #366531; } }

    .uploader {
      border-width: 2px;
      min-height: 200px;
      svg {
        height: 30px;
        width: 30px; }
      .large {
        font-size: 16px;
        font-weight: 400;
        text-align: center; } } }

  .audience-dialog-footer {
    border-top: 1px solid #dde1e5; } }

.audience-search-results-callout {
  min-width: 600px;
  .no-search-results {
    color: #666666; } }

.audience-user-list {
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dde1e5;
    padding: 15px 0;
    &:last-child {
      border-bottom: 0; } }
  .add-button {
    margin-right: -10px;
    &:hover {
      background-color: transparent; } }
  .remove-icon {
    color: red;
    &:hover {
      background-color: transparent; } }
  .user-suggestion {
    display: flex;
    align-items: center;
    .user-avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #aa214e;
      border-radius: 50%;
      color: #ffffff;
      height: 35px;
      margin-right: 10px;
      width: 35px;
      > div {
        line-height: 1em; } }
    .user-name {
      font-size: 14px; }
    .user-email {
      font-size: 12px; } } }
