@import "../../../../../styles/variables.sass";

/**
 * Authoring v1 body styles
 */
.multilingual-editor-field {
    .tox-fullscreen {
        .tox-editor-container {
            background: #ffffff;

            .tox-sidebar-wrap {
                margin: auto;
                width: $sparrow-content-modal-width; } } } }
