.avatar-icon-container {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    text-align: center;
    display: inline-block; }
.avatar-letter {
    color: #fff;
    display: inline-block;
    line-height: 50px;
    font-size: 180%;
    font-weight: 100;
    text-align: center;
    margin-top: 15px; }
