$light-grey: #f3f3f4;

.messaging-center-container {
    display: flex;
    overflow-x: scroll;
    margin: {} }

.messaging-center-sidebar {
    display: flex;
    flex-direction: column; }

.messaging-center-content {
    background: $light-grey;
    width: 100%;
    height: auto;
    padding: 20px; }

.sidebar-tab {
    padding: 10px;
    text-align: center;
    width: 210px;
    height: 50px;
    line-height: 50px;
    border: 1px solid $light-grey;
    font-size: 13px;
    cursor: pointer;
    user-select: none;

    &--active {
        padding: 10px;
        text-align: center;
        width: 210px;
        height: 50px;
        line-height: 50px;
        background: $light-grey;
        border: 1px solid $light-grey;
        font-size: 13px;
        font-weight: bold; }

    &:hover {
        background: $light-grey; } }
