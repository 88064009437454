.callout-content {
    max-height: 305px;

    .content-search-callout {
        width: 343px;
        max-height: 305px;

        .empty-message {
            font-size: 14px;
            color: #aaaaaa; } } }

.floating {
    position: fixed; }

.drop-zone {
    height: 60px;
    transition-property: height;
    transition-duration: 250ms;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);

    &.hidden {
        height: 0; } } // hide but still render

.content-search-input {
    &.disabled {
        .MuiOutlinedInput-root {
            background-color: #f0f0f0; } } }

.search-result {
    display: flex;
    align-items: center;
    padding: 10px 0;

    .search-image {
        flex-grow: 0;
        flex-shrink: 0;
        height: 87px;
        width: 154px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat; }

    .search-content {
        align-self: flex-start;
        font-size: 12px;
        padding: 0px 15px;
        display: flex;
        flex-direction: column;
        flex: 1; }

    .author {
        font-weight: 500; }

    .time {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #888888; }

    .title {
        display: flex;
        font-size: 16px;
        font-weight: 500; } }
