.tile-button {
    display: flex;
    flex-basis: 180px;
    width: 180px;
    height: 112px;
    border-radius: 5px;
    border: 1px solid #d7d7d7;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-size: 14px;
    margin: 9px 9px;

    &:hover {
      cursor: pointer;
      background-color: rgba(248, 248, 245, 1); }

    div {
        width: 100%;
        text-align: center; }

    &.heart-beat {
        animation: heartBeat 500ms 2;
        animation-delay: 1850ms; } } // this should be more than the last delay value of PostSmartContentHome.tileButtons

.tile-button-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -11px; }

@keyframes heartBeat {
    0% {
        border: solid 2px #3b78ab;
        transform: scale(1); }
    50% {
        border: solid 2px #3b78ab;
        transform: scale(1.1); }
    100% {
        border: solid 2px #3b78ab;
        transform:  scale(1.0); } }
