@import "../../common/components/authoring/styles/authoringVariables";

.email-sender-menu-item-container {
    display: flex;
    flex-direction: column;

    .name {
        font-size: 16px; }

    .email {
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis; } }

.email-audience-footer {
    display: flex;
    justify-content: space-between;

    .email-audience-footer-clear-all-button {
        padding-right: 0px; } }

#email-settings-drawer {
    margin-top: $navbar-height;
    overflow: hidden;
    height: calc(100vh - 50px);
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    &.MuiDrawer-paper {
        width: $settings-drawer-width; } }

.email-editor-settings-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    .email-from-label, .reply-email-label, .to-label {
        min-width: 100px; }

    .to-label {
        margin-top: 5px;

        &::after {
            content: "*";
            color: #b72026; } }

    .reply-email-container {
        margin-bottom: 10px;
        min-width: 64px;

        .reply-email-label {
            margin-top: 10px; } }

    .email-from-container {
        margin-top: 25px; }

    .send-test-email-container {
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        .send-test-email-loading-button-container {
            display: flex;
            align-items: center; }

        .test-email-chip {
            margin: 0;

            > div {
                margin-bottom: 4px;
                margin-right: 8px; } } }

    .send-test-email-label-button-container, .send-on-label-container, .test-email-address-text-field {
        margin-bottom: 10px; }

    .email-from-container {
        margin-bottom: 20px;
        align-items: center; }

    .recipient-field-container, .email-from-form-control, .reply-email-text-field {
        width: 100%; }

    .recipient-button-container, .send-test-email-label-button-container {
        justify-content: space-between;
        align-items: center; }

    .email-from-container, .reply-email-container, .to-container, .recipient-button-container, .send-test-email-label-button-container {
        display: flex;
        flex-direction: row; }

    .email-from-container, .reply-email-container, .to-container, .send-on-container, .send-test-email-container {
        margin-left: 25px;
        margin-right: 25px; } }
