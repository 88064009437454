#mobile-overlay {
    color: #ffffff;
    display: flex;
    flex-direction: column;
    background-color: rgba(51, 51, 51, 0.9);

    #mobile-overlay-close {
        position: absolute;
        top: 20px;
        right: 20px;

        #close-icon {
            font-size: 35px; } }

    .content-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 50px;

        #edit-icon {
            font-size: 100px;
            margin-bottom: 20px; }

        .content {
            width: 285px; } }

    #mobile-warning-overlay-action {
        color: #ffffff; }

    #mobile-warning-overlay-back {
        color: #ffffff;
        text-decoration: underline;
        text-transform: unset;
        position: absolute;
        bottom: 60px; } }
