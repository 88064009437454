#post-settings-drawer {
    height: 97%;

    .tab-panel {
        overflow: hidden; } }

.content-analyses-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
    height: 100%;

    .content-analyses-header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        margin-top: 5px;

        .content-analyses-header-item {
            display: flex;
            align-items: center;

            .legend-text {
                font-size: 10px; }

            .legend-text-global {
                font-size: 10px;
                color: #599FA0; } } }

    .content-analyses-content {
        height: 100%;
        width: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 0 21px 0 14px;

        .content-analyses-accordion {
            border-radius: 4px;
            border: 1px solid #dde1e5;

            &.expanded {
                margin: 0; }

            .text-indicator-container {
                display: flex;
                justify-content: space-between;
                width: 100%; }

            .text-indicator-heading {
                margin-top: 30px; }

            .text-percent-indicator-container {
                display: flex;
                justify-content: space-between;
                width: 100%;

                .title {
                    flex: 4;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center; }

                .value {
                    flex: 1; } } } }

    .content-analyses-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-top: solid 1px #dde1e5;
        width: 100%;
        height: 63px;
        padding: 20px;
        box-sizing: border-box;
        margin: auto; } }
