.messaging-center-tab-container {
    margin: 20px; }

.tab-clearfix::after {
    display: block;
    content: '';
    clear: both; }



.dropdown-button {
    border: none;
    color: white;
    font-weight: 600;
    background: #03a9f4;
    padding: 10px 15px;
    border-radius: 3px;
    cursor: pointer; }


.outline-dropdown-button {
    border: none;
    color: white;
    font-weight: 600;
    background: white;
    padding: 10px 15px;
    border-radius: 3px;
    color: #03a9f4;
    border: 1px solid #03a9f4;
    cursor: pointer; }

.dropdown-btn-content {
    display: none;
    position: absolute;
    background: white;
    min-width: 160px;
    z-index: 1;
    top: 35px;
    right: 0px;
    text-align: left; }

.outline-dropdown-button:hover ~ .dropdown-btn-content {
    display: block; }

.dropdown-button:hover ~ .dropdown-btn-content {
    display: block; }

.dropdown-btn-content:hover {
    display: block; }

.send-new-btn {
    position: relative;
    display: inline-block;

    .send-new-dropdown {
        color: black;
        display: block;
        width: 150px;
        padding: 10px;
        border-radius: 3px;
        cursor: pointer;
        background: #f3f3f4;

        &:hover {
            background: #c9eeff;
            text-decoration: none; } } }

.email-blast-container {
    background: white;
    border-radius: 5px;
    height: auto;
    width: 800px;
    position: absolute;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 0px 10px #BEBEBE;
    display: flex;
    flex-direction: column;
    padding: 30px; }

.email-blast-header {
    clear: both;
    display: block;
    content: ''; }

.email-blast-fields {
    display: flex;
    flex-direction: column; }

.text-area-body {
    resize: none;
    width: 100%;
    border: 1px solid #bfbfbf;
    min-height: 150px;
    overflow-y: scroll; }

.messaging-center-page {
    height:  !important; }

.email-insights {
    display: flex;
    height: 200px;
    width: 900px; }

.email-tab-container {
    display: flex;
    margin-bottom: 20px;
    min-height: 300px; }
.vertically-center-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 50px; }

.center-div {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap; }

.email-toolbox-container {
    background: #f5f5f5;
    padding: 10px;
    width: 300px;
    border-radius: 5px;
    max-height: 220px; }

.blast-disabled-message {
    background: red;
    height: 34px;
    float: right;
    color: #fff;
    text-align: center;
    font-size: 13px;
    line-height: 34px;
    margin: 0px 10px;
    padding: 0px 10px;
    border-radius: 5px; }
