#content-bands-one-last-check {
    .sub-header {
        display: flex;
        justify-content: space-between;
        align-items: center; }

    .setting-container {
        font-size: 14px;
        display: flex;
        margin: 6px 0;

        .setting-label, .setting-off {
            color: #7f7f7f; }

        .setting-label {
            width: 90px; }

        .setting {
            display: flex;
            flex-direction: column;

            .chip-container {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap; }

            .setting-on {
                color: #366531; }

            .setting-on,.setting-off {
                display: flex;
                align-items: center; } } }

    .cb-one-last-check-header {
        min-height: 68px;
        max-height: 68px;
        flex-wrap: wrap;
        padding: 0 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: solid 1px #dde1e5;

        .title {
            font-size: 18px;
            font-weight: 500; } }

    .cb-one-last-check-content {
        .cb-one-last-check-accordion {
            border-radius: 4px;
            border: 1px solid #dde1e5;

            &.expanded {
                margin: 0px; } }

        .no-header-text {
            color: #999999; } } }
