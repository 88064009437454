.smart-content-paper {
    display: flex;
    flex-direction: column;
    width: 432px;
    box-sizing: border-box;
    height: calc(100vh - 60px); // the view port height minus the height of the app bar and some offset

    .padding {
        padding: 0 21px 0 24px; }

    .row {
        box-sizing: border-box;
        width: 100%;

        .title {
            font-size: 18px;
            font-weight: 500;
            margin: 12px 0 29px 0; }

        .blurb {
            font-size: 15px; } }

    .footer {
        height: 65px;
        border-top: 1px solid #dde1e5;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 17px;
        box-sizing: border-box;
        margin-top: auto; } }

.smart-content-fab-callout {
    width: 330px;
    padding: 8px 12px;
    font-size: 14px; }

@media (max-width: 960px) {
    .smart-content-paper {
        width: 100%;
        height: calc(100vh - 50px); } } // the view port height minus the height of the app bar
