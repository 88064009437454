.filled-input-container {
    margin-bottom: 25px;

    .required:after {
        margin-left: 3px;
        content: "*";
        color: #b72026; }

    .filled-input-label-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;

        .filled-input-label {
            font-size: 16px;
            margin-bottom: 10px;
            color: #333333; } }

    .filled-input {
        font-size: 21px;
        min-height: 50px;
        &.Mui-focused {
            border-bottom: solid 1px #3b78ab; } }

    .filled-input-outlined {
        font-size: 16px;
        border: lightgrey 1px solid;
        border-radius: 2px;
        padding: 7px;

        &.error {
            border: #b72026 1px solid;
            color: black; } } }

.filled-input-helper-container {
    display: flex;
    justify-content: space-between;

    .filled-input-helper-text {
        font-size: 11px;
        color: #b72026; }

    .filled-input-char-count {
        color: #aaaaaa;
        font-size: 11px;

        &.error {
            color: #b72026;
            font-size: 11px !important; } } }
