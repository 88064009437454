.lang-list {
  list-style: none;
  padding-left: 0px;
  li {
    padding: 5px; }
  label {
    font-weight: 500;
    font-size: 14px;
    color: #595959; } }

.material-icons {
  font-size: inherit;
  line-height: inherit;
  vertical-align: bottom; }

.invisible, a.invisible:hover {
  color: transparent;
  cursor: default;
  text-decoration: none; }

.lang-dropdown {
    display: inline-block;
    margin-left: 15px; }

.lang-dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    span {
        color: black;
        padding: 6px 8px;
        text-decoration: none;
        display: block;
        &:hover {
            background-color: #ddd; } } }

.lang-dropdown:hover {
    .lang-dropdown-content {
        display: block; } }
