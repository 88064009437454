.newsletter-dialog.newsletter-issue-dialog {
  width: 800px;

  .newsletter-issue-section {
    margin: 30px 0;
    &:first-child {
      margin-top: 0; }

    .add-featured-content {
      margin-top: 5px; }

    .featured-content {
      display: flex;
      align-items: flex-start;
      margin-bottom: 10px;
      margin-top: 15px;
      > div:first-child {
        display: inline-flex;
        align-items: center;
        min-height: 42px;
        width: 190px; }
      > button {
        flex-grow: 0;
        margin-left: 30px;
        margin-top: -8px; }
      .featured-content-options {
        flex-grow: 1;
        > fieldset {
          width: 100%; } }
      .featured-content-option {
        display: inline-flex;
        margin-bottom: 8px;
        > label {
          width: 140px; }
        .search-icon {
          fill: #666666;
          margin-right: 5px; }
        .search-text {
          flex-grow: 1; } } }

    .image-loading {
      justify-content: center; }

    .pin {
      display: flex;
      align-items: flex-start;
      border: 2px solid #dddddd;
      flex-grow: 1;
      margin-top: 10px;
      position: relative;
      .remove-pin {
        position: absolute;
        top: -15px;
        right: -15px;
        background-color: #f3f3f4;
        box-shadow: 2px 2px 7px -1px #888888; }
      .pinned-image {
        flex-grow: 0;
        flex-shrink: 0;
        width: 200px;
        div {
          padding-top: 56.25%;
          width: 100%; }
        img {
          width: 100%; } }
      .pinned-content {
        font-size: 14px;
        padding: 15px;
        .author {
          font-weight: 500;
          margin-bottom: 0; }
        .published {
          color: #888888; }
        .title {
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 8px; } } } }

  .newsletter-issue-section-header {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px; }

  .newsletter-issue-details {
    > div {
      display: flex;
      align-items: flex-start;
      margin: 10px 0;
      > div {
        display: inline-flex;
        align-items: center;
        min-height: 40px;
        &:first-child {
          flex-shrink: 0;
          width: 190px; }
        &:last-child {
          flex-grow: 1; } } }

    .date-picker {
      margin-right: 10px;
      width: 250px;
      .MuiInputBase-root.Mui-disabled {
        color: inherit; } }

    .newsletter-issue-date,
    .newsletter-title {
      font-weight: 500; }

    .requirement-message {
      color: #a80000;
      font-size: 12px;
      margin-top: 5px; }

    .uploader {
      border-width: 2px;
      margin: 10px 0;
      min-height: 180px;
      width: 100%;
      svg {
        height: 30px;
        width: 30px; }
      .large {
        font-size: 16px;
        font-weight: 400; }
      .uploader-message {
        text-align: center; } } } }

.newsletter-insights {
  > div {
    margin: 10px 0;
    &:first-child {
      margin-top: 0; }
    &:last-child:not(.primary-stats) {
      margin-bottom: 0; }
    &:last-child.primary-stats {
      margin-bottom: 75px; } }
  min-height: 749px;

  .newsletter-insights-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    button {
      margin-left: 8px; }

    .header {
      display: inline-flex;
      align-items: center;
      font-size: 18px;
      font-weight: 500; } }

  .analytics-widget {
    padding: 15px;
    header {
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 8px; } }

  .no-insights-message {
    color: #888888;
    font-style: italic; }

  .primary-stats {
    .number-wrapper {
      font-size: 26px;
      padding: 20px 5px;
      .number {
        height: fit-content;
        margin-bottom: 8px; } }
    .subtitle {
      color: #888888;
      font-size: 13px;
      font-weight: 400;
      line-height: 1em;
      margin-top: 3px; }
    .title {
      line-height: 1.2em; } }

  .secondary-stats {
    .number-wrapper {
      font-size: 16px;
      padding: 20px 5px;
      .number {
        height: fit-content;
        margin-bottom: 5px; } } }

  .stats-row {
    .number-wrapper {
      position: relative;
      &:hover .stats-hover-info {
        height: fit-content;
        visibility: visible;
        > span {
          opacity: 1; } }
      &:last-child {
        margin-right: 0; } }
    .stats-hover-info {
      position: absolute;
      left: -1px;
      right: -1px;
      top: calc(100% - 5px);
      background-color: #fcfcf9;
      border: 1px solid #dde1e5;
      border-radius: 4px;
      border-top: 0;
      box-sizing: border-box;
      font-size: 10px;
      font-weight: 400;
      height: 0;
      padding: 5px;
      visibility: hidden;
      > span {
        opacity: 0;
        transition: opacity 0.1s 0.1s linear; } } }

  .swatches {
    font-size: 11px;
    margin-bottom: 20px;
    margin-left: 5px;
    margin-top: 15px;
    &::after {
      content: "";
      clear: both;
      display: table; } } }

.newsletter-issues {
  > div {
    margin: 25px 0;
    &:first-child {
      margin-top: 0; }
    &:last-child {
      margin-bottom: 0; } }
  min-height: 749px;

  .newsletter-issues-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    button {
      margin-left: 8px; }

    .header {
      display: inline-flex;
      align-items: center;
      font-size: 18px;
      font-weight: 500; } }

  .header-info-hover {
    display: flex;
    align-items: center; }

  .no-insights-message,
  .no-issues-message {
    color: #888888;
    font-style: italic; }

  .skip {
    color: #b72026; }

  .skipped {
    color: #888888;
    font-style: italic; } }

.newsletter-dashboard-contents {
  font-size: 12px;
  padding-bottom: 15px;

  .dashboard-contents-header {
    font-size: 14px;
    font-weight: 500;
    padding: 20px 20px 10px 20px; }

  .dashboard-contents-menu {
    .menu-item {
      color: #888888;
      cursor: pointer;
      line-height: 1.35em;
      padding: 0 15px;
      transition: background-color 0.1s linear;
      user-select: none;
      &:hover, &.selected {
        background-color: #f3f3f4; }
      > div {
        border-bottom: 1px solid #dde1e5;
        padding: 15px 5px; }
      .item-header {
        color: #333333;
        font-size: 14px;
        font-weight: 500; } } } }

.newsletter-dashboard-left-column {
  margin: -2px;
  padding: 2px;
  overflow-y: auto; }

.newsletter-details {
  font-size: 14px;

  .details-section {
    margin: 15px 0;
    &:last-child {
      margin-bottom: 0; }

    > div {
      display: flex;
      align-items: flex-start;
      margin: 10px 0;
      > div:first-child {
        flex-shrink: 0;
        width: 120px; }
      > div:last-child {
        font-weight: 500;
        &.audiences, &.topics {
          font-weight: 400; } } } }

  .description {
    margin: 15px 0; }

  .title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
    .disabled {
      display: inline-block;
      color: #888888;
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
      margin-left: 8px; } } }

.newsletter-search-results-callout {
  width: 400px;
  .search-result {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #dde1e5;
    padding: 10px 0;
    cursor: pointer;
    &:last-child {
      border-bottom: none; }
    .search-image {
      flex-grow: 0;
      flex-shrink: 0;
      width: 150px;
      div {
        padding-top: 56.25%;
        width: 100%; }
      img {
        width: 100%; } }
    .search-content {
      font-size: 12px;
      padding: 10px 15px;
      .author {
        font-weight: 500; }
      .time {
        color: #888888; }
      .title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 8px; } } } }

.no-newsletter-data {
  padding: 30px; }
