.audience-chips,
.audience-chip-labels,
.basic-chips,
.category-tag-chips,
.category-tag-chip-labels,
.chip-labels {
  margin: 0 -8px -2px 0;
  > div {
    align-items: center;
    background-color: #dde1e5;
    display: inline-flex;
    margin-right: 8px;
    margin-bottom: 2px; }
  .all-users-audience,
  .empty-chip,
  .uncategorized-tag {
    font-style: italic; } }

.audience-chips,
.basic-chips,
.category-tag-chips {
  > div {
    margin-bottom: 8px; } }
