
.post-insights {
    margin: 0 -8px;
    .action-buttons {
        display: flex;
        justify-content: space-between;
        margin-bottom: 14px;
        .right-buttons {
            display: flex;
            > button, > div {
                margin-right: 8px;
                &:last-child {
                    margin-right: 0; } } }
        .list-button {
            margin-top: 2px;
            position: relative; }
        .action-button-list {
            position: absolute;
            background-color: #ffffff;
            box-shadow: 0 0 4px #aaaaaa;
            z-index: 1;
            width: 100%; } }
    .content {
        display: flex;
        position: relative;
        .overview-card {
            position: absolute;
            width: 75%;
            left: 5%;
            top: -8px;
            z-index: 2; }
        .show-sibar-button {
            height: 50px;
            min-width: 45px;
            border-radius: 0px 4px 4px 0px;
            margin-top: 10px; }
        .post-side {
            max-width: 305px;
            min-width: 305px; }
        .insights-side {
            width: calc(100% - 305px); }
        .nav-header {
            position: relative;
            min-width: 0;
            .nav-buttons {
                display: flex;
                padding-bottom: 8px;
                margin-left: 10px;
                overflow-x: hidden;
                .nav-buttons-wrapper {
                    display: flex;
                    flex-wrap: nowrap;
                    align-self: center;
                    .round-button {
                        border-radius: 25px;
                        margin-right: 5px;
                        min-width: fit-content;
                        height: 30px; } } }
            .arrow-button {
                background-color:  rgba(22, 155, 213, 0.5);
                color: #ffffff;
                min-width: 2px;
                border-radius: 0px;
                height: 50px;
                position: absolute;
                top: -10px;
                &.right {
                    right: 10px; }
                &.left {
                    left: 14px; } }
            .align {
                padding-left: 5px;
                margin-right: -3px; }
            .arrow-wrapper {
                display: flex;
                position: absolute;
                top: -10px;
                justify-content: space-between;
                width: 100%; } }
        .warning {
            font-size: 12px;
            margin-left: 20px;
            display: flex;
            align-items: center;
            span {
                margin-left: 10px;
                color: #333; } }
        .insight-row {
            display: flex; }
        .insight-column {
            display: flex;
            flex-direction: column; }
        .insights-wrapper {
            position: relative;
            border-radius: 8px;
            border: 1px solid #dde1e5;
            background-color: #ffffff;
            box-sizing: border-box;
            padding: 20px;
            margin: 10px 0px 10px 10px;
            box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.15);
            .header {
                display: flex;
                justify-content: space-between; }
            .heading {
                font-weight: 700;
                color: #333333;
                font-size: 18px; }
            .info {
                font-style: italic;
                text-decoration: underline;
                color: #169bd5;
                font-size: 14px; }
            .legend-text {
                font-size: 10px; }
            .legend-text-global {
                font-size: 10px;
                color: #599FA0; } }
        .data-disclaimer {
            font-weight: normal;
            font-size: 12px;
            margin-left: 10px;
            padding-bottom: 3px;
            font-style: italic; }
        .empty-widget {
            font-style: italic;
            min-height: 250px;
            font-weight: normal;
            display: flex;
            font-size: 16px;
            flex-direction: column;
            text-align: center;
            justify-content: center; } } }
