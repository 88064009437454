.associated-newsletters-dialog {

    .associated-newsletters-dialog-title {

        .sender-title-container {
            color: #2f4050;
            display: flex;
            justify-content: space-between;
            align-items: center; } }

    .associated-newsletters-dialog-content {
        padding-bottom: 6px;

        .loading-container {
            padding: 8px; }

        .sender-description-container {
            word-break: break-word; }

        .newsletters-list {
            max-height: 200px;
            overflow-y: auto;
            padding-inline-start: 20px;

            .newsletters-item {
                cursor: pointer;
                &:hover {
                    text-decoration: underline; } } } }

    .associated-newsletters-dialog-actions {
        padding: 0px 24px 16px 24px; } }


