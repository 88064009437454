.document-preview-paper {
    max-width: 800px;
    &.document-preview-full {
        height: 100%; } }

.document-preview-content {
    overflow-y: clip; }

.document-view-paper {
    max-width: 500px; }

.document-view, .document-preview {
    .action-section {
        display: flex;
        justify-content: space-between; }

    .doc-footer {
        padding: 16px 24px; }

    .document-info-properties {
        > div {
            display: flex;
            align-items: flex-start;
            margin: 15px 0;

            > div:first-child {
                align-items: center;
                color: #888888;
                display: inline-flex;
                flex-shrink: 0;
                width: 150px; }

            .revision {
                margin-top: -2px; } } } }

.document-preview {
    .file-type {
        display: block;
        height: 100px;
        margin-bottom: 15px; }

    .attached-post {
        cursor: pointer;
        text-decoration: underline; }

    .document-info {
        font-size: 14px;
        padding: 20px 25px; }

    .document-info-header {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #dde1e5;
        font-size: 16px;
        font-weight: 500;
        margin: -8px -25px 20px -25px;
        padding: 0 25px 8px 8px; }

    .preview-screen {
        background-color: #dde1e5;
        display: flex;
        overflow-y: auto;
        > div {
            margin: 0 auto;
            img {}
            max-width: 100%; } }

    .action-section {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-end; }

    .more-info-link {
        color: #3b78ab;
        margin-top: 5px; }

    .document-title {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 5px;
        margin-right: 30px;
        word-break: break-word; }

    .document-views {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        > div {
            display: inline-flex;
            align-items: center;
            font-weight: 500;
            margin-right: 15px;
            &:last-child {
                margin-right: 0; } }
        svg {
            color: #666666;
            margin-right: 4px; } } }
