.file-attachment {
    .file-attachment-button {
        marging-left: 0px;
        margin-right: 0px;
        border: 0px;
        box-shadow: none;
        padding-right: 0px; }

    .grey-background {
        background-color: #f3f3f4;
        padding: 10px 15px; }

    .white-background {
        background-color: #ffffff;
        padding: 0px; } }
