@mixin cropline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

@mixin tileLayout($titleSize, $fontSize, $padding, $iconSize) {
  .tile {
    font-size: $fontSize; }
  .image {
    padding: $padding; }
  .stats {
    top: $padding + 2px;
    right: $padding;
    font-size: $iconSize; }
  .title {
    font-size: $titleSize; } }

#news-feed, #event-feed {
  .window {
    margin-bottom: -10px;
    &::after {
      content: '';
      display: block;
      clear: both; }

    .tile-cell {
      display: inline-block;
      width: 100%;
      margin-bottom: 10px;

      @include tileLayout(15px, 12px, 15px, 18px); }

    .tile {
      position: relative;
      color: #ffffff;
      cursor: pointer;

      width: 100%;
      padding-bottom: 56.25%;

      background: #f9f9f9 url('/images/image_unavailable.png');

      &:hover {
        .gradient-overlay {
          opacity: 0.5; } }

      .gradient-overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 50%;
        background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1));
        transition: 0.5s; }

      .image {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        box-sizing: border-box;

        display: flex;
        justify-content: flex-end;
        flex-flow: column;
        & > * {
          position: relative;
          z-index: 1; } }
      .video-indicator {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 25%;
        height: 25%;
        margin-left: -12.5%;
        margin-top: -12.5%; }
      .stats {
        position: absolute;
        top: 15px;
        right: 15px;
        margin-top: 2px;
        font-size: 18px;
        text-shadow: 0 0 2px #000000;
        svg {
          margin-right: 5px; }
        & > div {
          display: inline-flex;
          &:not(:first-child) {
            margin-left: 8px; } } }

      .date {
        font-style: italic; }
      .summary {
        @include cropline();
        > div {
          display: inline-block; } }
      .title {
        @include cropline();

        font-weight: 600;
        padding-top: 0.4em;
        padding-bottom: 0.15em;
        word-break: break-word; } }

    @media screen and (min-width: 640px) {
      .tile-cell {
        &.tile-cell-1 {
          @include tileLayout(21px, 12px, 15px, 25px);
          .summary {
            white-space: normal; } }

        &.tile-cell-2 {
          width: calc(50% - 7.5px);
          &:nth-child(2n+1) {
            margin-right: 15px; } }

        &.tile-cell-3 {
          width: calc(33.33333% - 10px);
          &:not(:nth-child(3n)) {
            margin-right: 15px; } } } }

    @media screen and (min-width: 640px) and (max-width: 1023px) {
      .tile-cell {
        &.tile-cell-2 {
          @include tileLayout(15px, 12px, 15px, 15px); } } }

    @media screen and (min-width: 1024px) {
      .tile-cell {
        &.tile-cell2 {
          @include tileLayout(21px, 12px, 15px, 25px);
          .summary {
            white-space: normal; } } } } } }
