.pagination {
    display: flex;
    flex-direction: row;
    align-items: center;

    .pagination-item {
        color: #aaaaaa;
        font-size: 12px;

        &.action {
            &:not(:disabled) {
                color: #3b78ab; } }

        .dropdown {
            width: 60px; }

        .pagination-form-control {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: row;
            font-size: 12px;

            .pagination-select-label {
                font-size: 12px;
                margin-right: 10px; }

            .pagination-select {
                &:focus {
                    background-color: #ffffff; } } } } }
