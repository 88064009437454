
.manager-wrapper {
    background-color: #fcfcfc;
    padding: 15px;
    border-radius: 3px;
    border: 1px solid #BCBCBC; }

.radio-wrapper {
    text-align: center;
    padding-bottom: 10px;
    label {
        margin: 10px;
        font-size: 18px;
        vertical-align: bottom; } }

.result-list {
    margin: 0 auto;
    background-color: #fff;
    width: 90%;
    padding: 15px;
    border-radius: 3px;
    margin-top: 15px;
    border: 1px solid #e5e6e7;
    min-height: 400px;
    height: 400px;
    overflow-y: auto;
    .item {
        padding: 10px 5px;
        border-bottom: 1px solid #ddd;
        &:first-child {
            padding-top: 0px; }
        &:last-child {
            padding-bottom: 0px;
            border-bottom: none; } } }
