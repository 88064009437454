$dark-grey: #505050;

.settings-section {
    display: flex;
    flex-direction: column;
    margin: 20px;
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 1000px; }

.suggestions-section-text {
    margin-top: 3px;
    margin-right: 3px;
    font-size: 13px;
    color: $dark-grey; }

.word-bubble-formatting {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px; }

.disabled-placeholder-input {
    height: 28px;
    padding-left: 10px;
    margin-left: 5px;
    width: 50px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 2px;
    border-width: 1px; }
