.tooltip {
    position: relative;
    display: inline-block; }

.tooltip {
    .tooltiptext {
        visibility: hidden;
        width: 100px;
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1; } }

.tooltip:hover {
    .tooltiptext {
        visibility: visible; } }
