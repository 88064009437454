.successful-action-container {
    .dialog {
        text-align: center; }

    .button-wrapper {
        text-align: center;

        &:after {
            display: block;
            content: "";
            clear: both; }

        .button {
            padding: 12px 32px;
            margin: 0px 8px;
            background: #fff;
            border: none;
            outline: none;
            color: #444;
            cursor: pointer;

            &:hover {
                background-color: #f3f3f3; }

            &.primary {
                background-color: #2196F3;
                color: white;

                &:hover {
                    background-color: #1d8ce4; } } } }

    .modal-info {
        margin: 18px 0px 24px 0px;

        p {
            color: #324148;
            padding: 4px 12px;
            font-size: 14px;
            margin: 6px 0px; } }

    .title {
        margin-top: 12px;
        margin-bottom: 24px; }

    .gif-wrapper {
        width: 50%; }

    .hyperlink {
        font-size: 14px;
        text-decoration: underline; } }
