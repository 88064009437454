.editing-form {
    margin: 16px;
    width: 100%;
    max-width: 600px;
    box-sizing: border-box;
    float: left; }

.rightSideHelperContainer {
    display: block;
    position: absolute;
    left: 620px;
    top: auto;
    width: 380px;
    padding: 16px; }

.error-message {
    margin: 16px;
    list-style: none;
    padding: 5px;
    float: left;
    color: red;
    display: block;
    position: relative;
    top: auto;
    float: left;
    width: 100%;
    padding: 16px;
    margin-bottom: 16px;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0px 3px 5px -2px rgba(0, 0, 0, 0.32);

    li {
        padding: 5px; } }

.settings-group {
    margin-top: 24px; }

.surveys-form-group {
    padding-bottom: 16px;
    margin-bottom: 16px;
    font-size: 14px;

    .survey-answers {
        margin-left: 10px; } }

.form-group-border-bottom {
    @extend .surveys-form-group;

    border-bottom: solid 1px #ddd; }

.button {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 2px;
    padding: 8px 22px;
    font-size: 14px;
    cursor: pointer;
    box-shadow: 0 2px 3px rgba(0,0,0,0.1);
    margin-right: 15px;
    transition: all 0.25s ease-in;
    &.action {
        background: #01b5fa;
        border: 1px solid #00adff;
        color: #fff; }

    &:hover {
        box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.1); } }

.save-button {
    @extend .button;
    background-color: #01b5fa;
    color: white; }

.delete-button {
    @extend .button; }

.remove-answer {
    @extend .button;
    color: red; }

.toggle-item-description {
    color: #aaa;
    font-size: 90%; }

.surveys-form-section {
    background: #fff;
    padding: 10px 16px;
    margin-bottom: 15px;
    clear: both;
    position: relative;
    box-shadow: 0px 3px 5px -2px rgba(0, 0, 0, 0.32); }

.title-toggle-group {
    p {
        float: left; }

    &:after {
        clear: both;
        content: "";
        display: block; } }

.date-picker-area {
    padding: 24px;

    .label-date-picker {
        float: left;
        padding: 6px 12px;
        padding-left: 0; }

    .error {
        color: red; } }
