@import "../styles/variables.sass";

.event-page {
  .no-event-data {
    padding: 25px 20px;
    text-align: center; }

  @media screen and (min-width: 1500px) {
    .event-view {
      > div {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        box-sizing: border-box;

        &:first-child {
          border-right: 1px solid #dddddd; } }

      .comments {
        display: inline-block; } } } }

.event-preview {
  font-size: 14px;
  margin-top: 10px;
  max-width: $sparrow-content-modal-width;
  box-sizing: content-box; }

.event-view {
  .comments {
    display: none; } }

.event-views.event-preview,
.event-views.event-view,
.post-preview {
  font-size: 16px;

  .preview-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #888888;
    font-weight: 500; }

  .preview-label {
    color: #888888; }

  .preview-secondary-label {
    color: #888888;
    font-size: 13px; }

  .preview-detail {
    display: flex;
    align-items: flex-start;
    margin: 10px 0 10px 15px;

    .preview-label {
      width: 200px; }

    .preview-date {
      font-weight: 500; }

    .selected-notification,
    .unselected-notification {
      display: flex;
      align-items: center;
      margin: 4px 0;
      > svg {
        margin-right: 4px;
        width: 20px; } }

    .selected-notification {
      color: #008000; }

    .unselected-notification {
      color: #aaaaaa; } }

  .preview-detail-divider {
    margin: 20px 0; }

  .preview-detail-times {
    margin: 15px 0; }

  .preview-page {
    border: 1px solid #aaaaaa;
    border-radius: 3px;
    margin: 10px 0;
    padding: 3px;
    max-width: $sparrow-content-modal-width; }

  .view-page {
    margin: 0 auto;
    max-width: $sparrow-content-modal-width; }

  .post-preview-page {
    margin-top: 10px;
    position: relative;
    max-width: $sparrow-content-modal-width;
    flex-basis: $sparrow-content-modal-width; }

  .banner {
    height: 5px;
    width: 100%; }

  .comments {
    color: #666666;
    text-align: center; }

  .hero-banner {
    position: relative;
    display: flex;
    margin: 0;
    overflow: hidden;
    text-align: center;

    &:hover {

      .hero-banner-arrow {
        display: flex; }

      .hero-banner-counter {
        display: block; } }

    .hero-banner-arrow {
      display: none;
      position: absolute;
      top: calc(50% - 37px);
      font-size: 50px;

      &.left-arrow {
        left: 0; }

      &.right-arrow {
        right: 0; } }

    .hero-banner-counter {
      display: none;
      position: absolute;
      bottom: 12px;
      left: 0;
      right: 0;
      text-align: center;

      > span {
        background-color: rgba(0,0,0,0.5);
        border-radius: 27px;
        color: #efefef;
        font-size: 12px;
        text-shadow: 0 0 4px rgba(0,0,0,0.5);
        padding: 5px 15px; } }

    .hero-banner-images {
      display: flex;
      height: $sparrow-hero-img-height;

      .hero-banner-image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        transition: transform 1.5s ease-in-out;
        width: $sparrow-content-modal-width;

        > img {
            height: $sparrow-hero-img-height;
            width: $sparrow-content-modal-width; } } } }

  .hero-banner-steps {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #cccccc;
    margin: 10px 0;

    > button {
      padding: 0; } }

  .page-content {
    font-size: 14px;
    margin: 0 20px 24px 20px;

    iframe, img {
      max-width: 100%; }

    .content-heading {
      margin: 25px 0; }

    .info-field {
      display: flex;
      font-size: 12px;
      margin-bottom: 8px;
      .info-icon {
        color: #666666;
        margin-top: -3px;
        width: 34px; } }

    .add-time-to-calendar {
      margin-left: 10px;
      margin-top: -2px;
      padding: 0 3px; }

    .author {
      flex-direction: column;
      margin: 15px 0;

      .author-avatar {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: #000000;
        border-radius: 50%;
        color: #ffffff;
        font-size: 21px;
        height: 46px;
        width: 46px;
        margin-right: 8px;
        user-select: none; }

      .author-details {
        display: flex;
        align-items: center;
        color: #888888;
        font-size: 12px;
        margin: 8px 0; }

      .author-name {
        font-size: 13px;
        font-weight: 500; } }

    .available-languages {
      color: #666666;
      font-size: 12px;
      text-align: right;

      .inactive-language {
        color: #2196f3;
        cursor: pointer; } }

    .body {
      margin: 15px 0;

      img, iframe {
        margin: 10px;
        max-width: 100%;
        height: auto;
        padding: 0; } }

    .documents {
      margin: 15px 0;

      .documents-label {
        font-weight: 500;
        margin-bottom: 5px; } }

    .emphasis {
      font-weight: 500; }

    .multi-day-event-times {

      > div {
        margin: 4px 0;

        &:first-child {
          margin-top: 0; } }

      .event-times-day {
        display: inline-block;
        min-width: 150px; }
      .event-times-time {
        display: inline-block;
        min-width: 115px; } }

    .title {
      font-size: 24px;
      font-weight: 500;
      word-break: break-word; } }

  .send-publishing-notifications {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f3f3f4;
    margin-bottom: 20px;
    padding: 10px 15px; } }
