.authoring-filter {
  margin-bottom: 20px;
  padding: 10px;

  .MuiFormControl-root {
    margin: 8px; }

  .event-types {
    width: 300px; }

  .filter-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; }

  .filter-callout-button {
    position: relative;
    > button {
      margin: 8px 0 8px 8px; } }

  .filter-commands {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    margin: 8px;
    button {
      margin: 0 8px;
      &:last-child {
        margin: 0; } }
    hr {
      margin: 0 8px; } }

  .filter-options {
    display: inline-flex;
    align-items: center;
    flex-grow: 1;
    flex-wrap: wrap;
    .filter-options-group {
      display: inline-flex;
      align-items: center;
      flex-wrap: wrap; } }

  .filter-selection {
    margin: 0 4px;
    > div {
      background-color: #dde1e5;
      margin: 8px 4px 0 4px; } }

  .lcid-to-search {
    width: 250px; }

  .post-types {
    width: 300px; }

  .search-icon {
    fill: #666666;
    margin-right: 5px; }

  .tags {
    width: 300px; }

  .text-to-search {
    max-width: 400px;
    width: 100%; } }

.callout {
  .callout-content {
    .audiences {
      width: 200px; }

    .date-picker {
      .MuiInputBase-root.Mui-disabled {
        background-color: #ffffff;
        color: inherit; } }

    .published-time {
      padding: 0 8px;
      width: 300px;
      > div {
        display: flex;
        align-content: center;
        margin: 8px 0;
        > div {
          flex-grow: 1;
          flex-shrink: 1;
          &:first-child {
            align-self: center;
            width: 100px; } } } }

    .search-icon {
      fill: #666666;
      margin-right: 5px; }

    .text-to-search {
      margin-bottom: 8px; } } }
