.success-checkmark-pop-dialog {
    text-align: center; }

.success-checkmark-pop-wrapper {
	width: 100px;
	height: 100px;
	position: relative;
	display: inline-block;
	animation-duration: 1.7s;
	animation-timing-function: ease;
	animation-name: success-checkmark-load-outer;
	margin: 10px;

	.success-checkmark-pop-bg {
		border-radius: 50%;
		width: 100%;
		height: 100%;
		background-color: #5cb85c;
		animation-duration: 0.5s;
		animation-timing-function: ease;
		animation-name: success-checkmark-load-bg; }

	.success-checkmark-pop-checkmark {
		top: 45%;
		left: 45%;
		width: 60px;
		height: 25px;
		position: absolute;
		border: dashed 0px green;
		transform: translate(-50%, -50%) rotate(-45deg);

		&:after {
			content: ' ';
			top: 0;
			left: 0;
			width: 60px;
			height: 25px;
			position: absolute;
			border: solid 8px #fff;
			border-top: none;
			border-right: none;
			animation-duration: 1.3s;
			animation-timing-function: ease;
			animation-name: success-checkmark-load; } } }

@keyframes success-checkmark-load-bg {
    0% {
        background-color: #fff; }
    100% {
        background-color: #5cb85c; } }

@keyframes success-checkmark-load {
    0% {
        border-left: solid 0px #fff;
        border-bottom: solid 0px #fff;
        width: 0px;
        height: 0px; }
    54% {
        border-left: solid 0px #fff;
        border-bottom: solid 0px #fff;
        width: 0px;
        height: 0px; }
    55% {
        border-left: solid 8px #fff;
        border-bottom: solid 8px #fff;
        width: 0px;
        height: 0px; }
    80% {
        width: 0px;
        height: 25px; }
    100% {
        width: 60px;
        height: 25px; } }

@keyframes success-checkmark-load-outer {
    0% {
        transform: scale(0.9); }
    60% {
        transform: scale(0.9); }
    75% {
        transform: scale(0.8); }
    80% {
        transform: scale(1.15); }
    85% {
        transform: scale(0.95); }
    90% {
        transform: scale(1.05); }
    95% {
        transform: scale(0.98); }
    100% {
        transform: scale(1); } }

