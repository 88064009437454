.newsletter-page {

  .event-validator {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    margin: -15px;
    padding: 15px;

    &:hover, &.expand-progress-list {
      .progress-list, .progress-list-arrow {
        display: block; } }

    > div {
      flex-grow: 1; } }

  .progress-info {
    position: relative; }

  .progress-bar {
    position: relative;
    background-color: #dddddd;
    border-radius: 27px;
    height: 22px;
    margin-right: 65px;
    overflow: hidden; }

  .progress-bar-fill {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #008000;
    border-radius: inherit;
    display: flex;
    align-items: center;
    transition: width linear 0.25s;
    overflow: hidden; }

  .progress-bar-text {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: inline-flex;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    height: 22px;
    padding: 0 10px;
    white-space: nowrap; }

  .progress-list {
    background-color: #ffffff;
    margin-left: -12px;
    margin-top: 15px;
    min-width: 250px;
    padding: 4px 12px;
    z-index: 1;

    .progress-list-item {
      position: relative;
      margin: 15px 0;

      .item-description-header {
        margin-left: 26px;

        font-weight: 500; }

      .item-description-text {
        margin-left: 26px;
        margin-top: 0px;
        font-size: 13px; }

      .item-valid-indicator {
        position: absolute;
        top: 0;
        left: 0;

        .item-valid-indicator-icon {
          border: 1px solid #008000;
          border-radius: 50%;
          height: 16px;
          width: 16px; } }

      &.item-valid {

        .item-description {
          color: #008000;
          font-weight: 500; }

        .item-valid-indicator-icon {
          background-color: #008000; } } }

    .emphasis {
      font-weight: 500; } }

  .progress-list-arrow {
    display: none;
    position: absolute;
    height: 15px;
    width: 30px;
    overflow: hidden;
    z-index: 2;

    &::after {
      content: "";
      position: absolute;
      width: 15px;
      height: 15px;
      background: #ffffff;
      transform: rotate(45deg);
      top: 8px;
      left: 8px;
      box-shadow: 0 0 4px #aaaaaa; } }

  .progress-list-info {
    border-top: 1px solid #dddddd;
    color: #888888;
    margin: 5px 0;
    padding: 10px 0 5px 0; } }
