.draft-activity-log {
    max-height: 440px;
    max-width: 500px;

    .content {
        max-width: 300px;
        overflow-wrap: break-word;
        white-space: break-spaces; }

    .legend {
        vertical-align: baseline; }

    .draft-history-cell {
        padding-left: 15px;
        padding-right: 10px; } }
