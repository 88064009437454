.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;
    background: rgba(0,0,0,0.5);
    visibility: hidden;
    opacity: 0;
    transition: all 0.15s ease-out;
    &.active {
        visibility: visible;
        pointer-events: auto;
        opacity: 1;
        .modal-dialog {
            transform: translate(-50%, -50%) scale(1);
            transition: all 0.15s ease-in; } }

    .modal-dialog {
        position: absolute;
        float: left;
        left: 50%;
        top: 50%;
        background: #fff;
        margin: 0px auto;
        box-shadow: 0 3px 5px rgba(0,0,0,0.2);
        border: 1px solid rgba(0,0,0,0.5);
        border-radius: 5px;
        transform: translate(-50%, -50%) scale(0.5);
        min-width: 500px;
        min-height: 150px;
        transition: all 0.15s ease-in;
        box-sizing: border-box;
        max-height: 95vh; }
    .modal-dialog-header {
        position: relative;
        padding: 5px 15px;
        overflow: hidden;
        border-bottom: 1px solid #ddd;
        background: #212d38;
        color: #fff;
        span {
            font-weight: bold;
            font-size: 22px; }
        .modal-dialog-close-btn {
            border: none;
            font-weight: bold;
            box-shadow: none;
            background: none;
            color: #696969;
            font-size: 22px;
            cursor: pointer;
            position: absolute;
            right: 10px;
            top: 2px;
            span {
                line-height: 28px;
                display: inline-block; }
            &:hover {
                color: #fff; } } }
    .modal-dialog-content {
        padding: 15px; }
    .modal-dialog-controls {
        height: 50px;
        width: 100%;
        button {
            background: #fff;
            border: 1px solid rgba(0,0,0,0.1);
            border-radius: 2px;
            padding: 8px 22px;
            font-size: 14px;
            cursor: pointer;
            margin-right: 15px;
            transition: all 0.25s ease-out;
            &.primary {
                background: #01b5fa;
                border: 1px solid #00adff;
                color: #fff; }

            &.delete {
                background-color: #d9534f;
                float: left;
                margin-left: 22px;
                color: #fff; } } } }

