@import '../../node_modules/noty/lib/noty.css';
@import '../../node_modules/noty/lib/themes/mint.css';

.noty_theme__mint.noty_bar {
    font-size: 16;
    width: 500px;

    .noty_body {
        margin-right: 12px;
        font-family: Open Sans; }

    .noty_buttons {
        button {
            width: 80px;
            height: 35px;
            margin-right: 8px;
            margin-bottom: 6px;
            padding: 5px;
            border: 1px solid rgba(0, 0, 0, 0.2);
            font-size: 14px;
            cursor: pointer;
            border-radius: 3px;
            box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
            transition: all 0.25s ease-in;
            float: right; } } }
