.mobile-preview-container {
    width: 407px;
    height: 873px;
    padding: 15px 9px;
    border-radius: 5px;
    border: 1px solid #dde1e5;
    background-color: #f2f2f2;
    max-height: 873px;
    box-sizing: border-box;

    .header-bar {
        background-color: #014a75;
        padding: 15px;
        min-height: 61px;
        max-height: 61px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        position: sticky;
        top: 0;

        .search {
            flex: 1;
            display: flex;
            justify-content: flex-end; }

        .app-name {
            font-size: 20px;
            color: #ffffff;
            font-weight: 500; } }

    .mobile-preview-content {
        overflow-y: scroll;
        background-color: #ffffff;
        position: relative;
        min-height: 716px;
        max-height: 785px;

        .footer-bar {
            background-color: #ffffff;
            width: 100%;
            position: sticky;
            bottom: 0;

            .footer-actions {
                height: 66px;
                display: flex;
                justify-content: space-evenly;
                align-items: center; }

            .icon {
                color: #7f7f7f;

                &.active {
                    color: #014a75; } } } } }
