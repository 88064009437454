.attending-in-person,
.attending-online,
.not-attending,
.response-required,
.waitlisted {
    font-weight: 500; }

.attending-in-person,
.attending-online {
    color: #366531; }

.waitlisted {
    color: #faaa2b; }

.response-required {
    color: #b72026; }

.not-responded-yet {
    color: #7f7f7f; }

.not-responded-yet {
    font-style: italic; }
