.sender-emails-dialog {
    .sender-emails-dialog-paper {
        width: 660px;
        max-width: 660px; }

    .sender-emails-dialog-title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title-text-container {
            display: flex;
            align-items: center; } } }
