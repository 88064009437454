.moreOptionsButton {
    width: 100%; }

.moreOptionsButton-ml {
    margin-left: 8px !important; }

.filters-display {
    width: 100%;
    display: flex;
    flex-direction: row; }

.calendar-section {
    display: flex;
    flex-direction: row;

    .calendar-element {
        position: relative;
        top: -10px;
        max-width: 200px; }

    .calendar-text {
        padding-left: 5px;
        padding-right: 5px;
        position: relative;
        top: 10px; } }

.MuiInputBase-root.Mui-disabled {
    background-color: #FFFFFF; }

.MuiPickersCalendar-transitionContainer {
    margin-top: 12px;
    min-height: 216px;
    margin-bottom: 5px; }

.totalSubscribers {
    font-size: 14px;
    color: #333333; }
