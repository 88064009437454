.image-selector-tab {
    display: block;
    height: 360px;
    position: relative;

    .image-preview {
        display: block;
        position: fixed !important;
        z-index: 3;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        overflow-y: scroll;
        background-color: rgba(0,0,0,0.85);

        i {
            position: fixed;
            top: 30px;
            right: 30px;
            font-size: 40px;
            color: white;
            cursor: pointer; }

        img {
            margin: auto;
            padding: 80px 0 0 0;
            display: block;
            max-width: 80%;
            max-height: calc(99% - 80px); } } }

.library-image {
    display: block;
    float: left;
    width: calc(25% - 8px);
    height: 100px;
    margin: 4px;
    background-color: #ccc;
    position: relative;

    .img {
        width: 100%;
        height: 100%;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        box-sizing: border-box;
        cursor: pointer; }

    .preview-icon {
        display: none; }



    &:hover .preview-icon {
        z-index: 2;
        display: inline;
        position: absolute;
        color: white;
        font-size: 40px;
        text-shadow: 0px 0px 3px black;
        cursor: pointer;
        padding-left: 8px; }

    .selector-overlay {
        display: inline-block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        i {
            font-size: 40px;
            color: white;
            text-align: right;
            position: absolute;
            top: 0;
            right: 0;
            cursor: default;
            padding-right: 8px; } }

    &.active {
        .overlay {
            background-color: rgba(3, 169, 244, 0.2); }

        .img {
            border: solid 6px #03A9F4; } } }

.image-selector-container {
    box-sizing: border-box;
    padding: 15px;

    &:after {
        display: block;
        clear: both;
        content: ""; } }
