@import './form-variables';

.textInput {
    position: relative;
    margin: 0px;

    .inputWrapper {
        display: block;
        width: 100%;
        padding-top: 20px; }

    label {
        color: $default-label-color;
        font-size: 12px;
        position: absolute;
        transition: all 0.25s;
        pointer-events: none; }

    .input {
        display: block;
        box-shadow: none;
        box-sizing: border-box;
        width: 100%;
        padding: 8px 0px;
        margin: 0px;
        border: none;
        border-bottom: solid 1px $default-border-color;
        outline: none;
        font-size: 14px;
        background-color: #fff;
        overflow: hidden; }

    .input-textarea {
        @extend .input;

        resize: none;
        overflow: hidden;
        font-family: inherit; }

    .highlightBar {
        display: block;
        content: "";
        width: 0%;
        margin: 0 auto;
        border-bottom: solid 2px $focused-border-color;
        transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1); }

    .hint {
        padding: 6px 0px;
        display: inline-block;
        color: #aaa;
        font-size: 90%; } }

.textInput-empty {
    @extend .textInput;

    label {
        font-size: 14px;
        top: 0px; } }

.textInput-focused {
    @extend .textInput;

    label {
        color: $focused-label-color; }

    .highlightBar {
        width: 100%;
        margin-top: -1px;
        margin-bottom: 1px; } }

.isTitle {
    padding-bottom: 10px;
    .input {
        font-size: 200%;
        line-height: 110%;
        font-weight: bold; } }
