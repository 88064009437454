.newsletter-listing {
  .header-view {
    display: inline-flex;
    align-items: center; }

  .newsletter-list {
    .newsletter-list-item {
      cursor: pointer; }
    .newsletter-state {
      position: relative;
      margin-right: -12px;
      &::before {
        content: "";
        position: absolute;
        left: -16px;
        border-radius: 50%;
        height: 8px;
        top: 5px;
        width: 8px; }
      &.green::before {
        background-color: #3b6531; }
      &.grey::before {
        background-color: #adb7c1; }
      &.yellow::before {
        background-color: #e9b848; } }
    .newsletter-subscribers {
      .subscriber-icon {
        margin-left: 10px;
        &:first-child {
          margin-left: 0; }
        svg {
          color: #888888;
          padding-right: 4px;
          transform: translateY(25%); } } }
    .newsletter-title {
      display: inline-flex;
      align-items: center;
      svg {
        color: #888888;
        padding-left: 4px; } } }

  .newsletter-results {
    margin-bottom: 15px; } }
