.personalization-token-menu-item {
    display: flex !important;
    flex-direction: column;
    align-items: flex-start !important;

    .personalization-token-title {
        font-size: 14px;
        color: #333333; }

    .personalization-token-description {
        font-size: 11px;
        color: #7f7f7f; } }

