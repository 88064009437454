@import "../../../../../styles/variables";
@import "./authoringVariables";

#app-base {
    &.authoring {
        background-image: none;
        background-color: #fcfcf9;
        min-height: 100vh !important;

        #admin-main-container {
            max-height: none;
            height: 100vh;

            #admin-main {
                padding: 0;
                overflow: initial; } // this allows us to handle scrolling manually

            .authoring-page {
                .authoring-container {
                    display: flex;
                    justify-content: center;
                    height: 100%;

                    .main-editor-paper {
                        flex-basis: $sparrow-content-modal-width;
                        max-width: $sparrow-content-modal-width;
                        -moz-box-sizing: border-box;
                        -webkit-box-sizing: border-box;
                        box-sizing: border-box;
                        padding: 15px 18px 15px 23px;
                        display: flex;
                        flex-direction: column;
                        overflow: auto;
                        margin-right: 100px;

                        .main-editor-content {
                            flex-basis: 100%; } } // height

                    .main-editor-paper-open {
                        margin-right: $settings-drawer-width;
                        transition: margin-right 500ms cubic-bezier(0, 0, 0.2, 1) 0ms;
                        -moz-transition: margin-right 500ms cubic-bezier(0, 0, 0.2, 1) 0ms;
                        -webkit-transition: margin-right 500ms cubic-bezier(0, 0, 0.2, 1) 0ms; }

                    .main-editor-paper-closed {
                        transition: margin-right 500ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
                        -moz-transition: margin-right 500ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
                        -webkit-transition: margin-right 500ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
                        margin-right: 100px; } } } } } }
