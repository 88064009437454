.Select-option:first-child {
    height: 30px; }

.loading {
    margin-top: 10px;
    height: 20px;
    position: relative; }

.dates-error-message {
    display: inline-block;
    height: 20px;
    font-size: 15px;
    width: 500px;
    padding-left: 8px;
    color: #000000;
    background-color: rgba(255, 0, 0, 0.4);
    border-radius: 4px; }
.external-users-wrapper {
    text-align: right;
    margin-top: -50px;
    margin-bottom: 20px;
    margin-right: 20px;
    .external-users {
        width: 70%;
        background-color: #f4f4f4;
        border-radius: 3px;
        padding: 10px 10px 10px 10px;
        display: inline-flex; }
    .loading-wrapper {
        display: inline-block;
        position: relative;
        margin-right: 50px; } }

.posts {
    width: 72%;
    background-color: #fcfcfc;
    border-radius: 4px;
    padding: 10px 10px 8px 10px; }
.users {
    width: 85%;
    border-radius: 3px;
    padding: 10px 10px 10px 10px; }
.activity {
    width: 75%;
    background-color: #fcfcfc;
    border-radius: 4px;
    padding: 10px 10px 8px 10px; }

.document-status-report {
    padding: 10px 10px 10px 10px;
    margin: 0 auto;

    input {
        float: left;
        line-height: 22px;
        &.standard {
            border: 1px solid #D1CFCF; }
        &.name-filter {
            margin-left: 15px;
            margin-right: 15px;
            width: 210px; }
        &.author-filter {
            margin-right: 15px;
            width: 230px;
            margin-left: 15px; }
        &.email-filter {
            width: 290px; }
        &.title-filter {
            width: 310px; }
        &.version-filter {
            width: 50px;
            margin-left: 15px; } }
    input.num {
        margin-top: 1px;
        width: 20px;
        display: inline-block;
        margin-left: 15px;
        border: 1px solid #D1CFCF; }
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0; }

    label {
        display: inline-block;
        margin-left: 7px;
        &.published {
            padding-top: 8px; }
        &.log {
            padding-top: 6px; } }
    .label-wrapper {
        display: flex; }

    .spacing-fix {
        margin-right: 5px;
        &.log {
            margin-right: 10px; } }

    .select-wrapper {
        margin-left: 5px;
        width: auto;
        display: inline-flex;
        text-align: left;
        .label-style {
            padding-top: 5px;
            padding-right: 5px; } }

    .tags-wrapper {
        text-align: left;
        flex-basis: 100%;
        &.comp {
            margin-left: 11px; }
        &.browser-fix {
            margin-left: 8px; } }

    .dates-between-wrapper {
        display: inline-flex; } }

.page-filters {
    margin: 0 auto;
    border: 1px solid #BCBCBC;
    text-align: center;

    input {
        float: left;
        line-height: 22px;
        &.standard {
            border: 1px solid #D1CFCF; }
        &.name-filter {
            margin-left: 7px;
            margin-right: 8px;
            width: 210px; }
        &.author-filter {
            margin-right: 8px;
            width: 230px;
            margin-left: 4px; }
        &.email-filter {
            width: 290px; }
        &.title-filter {
            width: 310px; }
        &.version-filter {
            width: 50px;
            margin-left: 8px; } }
    input.num {
        margin-top: 1px;
        width: 20px;
        display: inline-block;
        margin-left: 8px;
        border: 1px solid #D1CFCF; }
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0; }

    label {
        display: inline-block;
        margin-left: 7px;
        &.published {
            padding-top: 8px; }
        &.log {
            padding-top: 6px; } }
    .label-wrapper {
        display: flex; }

    .spacing-fix {
        margin-right: 5px;
        &.log {
            margin-right: 10px; } }

    .select-wrapper {
        margin-left: 5px;
        width: auto;
        display: inline-flex;
        text-align: left;
        .label-style {
            padding-top: 5px;
            padding-right: 5px; } }

    .tags-wrapper {
        text-align: left;
        flex-basis: 100%;
        &.comp {
            margin-left: 11px; }
        &.browser-fix {
            margin-left: 8px; } }

    .dates-between-wrapper {
        display: inline-flex; } }

.page-filters-2 {
    -moz-box-shadow: 0 0 4px black;
    -webkit-box-shadow: 0 0 4px black;
    box-shadow: 0 0 7px #E0E2E5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    input {
        float: left;
        line-height: 22px;
        &.standard {
            border: 1px solid #D1CFCF; }
        &.name-filter {
            margin-left: 7px;
            margin-right: 8px;
            width: 210px; }
        &.author-filter {
            margin-right: 8px;
            width: 230px;
            margin-left: 4px; }
        &.email-filter {
            width: 290px; }
        &.title-filter {
            width: 310px; }
        &.version-filter {
            width: 50px;
            margin-left: 8px; } }
    input.num {
        margin-top: 1px;
        width: 20px;
        display: inline-block;
        margin-left: 8px;
        border: 1px solid #D1CFCF; }
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0; }

    label {
        display: inline-block;
        margin-left: 7px;
        &.published {
            padding-top: 8px; }
        &.log {
            padding-top: 6px; } }
    .label-wrapper {
        display: flex; }

    .spacing-fix {
        margin-right: 5px;
        &.log {
            margin-right: 10px; } }

    .select-wrapper {
        margin-left: 5px;
        width: auto;
        display: inline-flex;
        text-align: left;
        .label-style {
            padding-top: 5px;
            padding-right: 5px; } }

    .tags-wrapper {
        text-align: left;
        flex-basis: 100%;
        &.comp {
            margin-left: 11px; }
        &.browser-fix {
            margin-left: 8px; } }

    .dates-between-wrapper {
        display: inline-flex; } }

.button-wrapper {
    padding-top: 10px;
    button {
        &.btn {
            background: #fff;
            border-radius: 3px;
            padding: 8px 10px;
            font-size: 15px;
            cursor: pointer;
            margin-right: 8px;
            transition: all 0.25s ease-in;
            min-height: 33px; }
        &.btn-primary {
            background: #2196f3;
            color: #fff; } } }

.responsive-row {
    &.log {
        display: flex; }
    &.tags {
        display: flex;
        padding-top: 4px;
        margin-left: -6px; } }

@media screen and (max-width: 1700px) {
    .posts {
        width: 73%; }
    .users {
        width: 80%; }
    .external-users-wrapper {
        display: inline;
        .external-users {
            width: 93%;
            display: inline-block;
            margin-bottom: 20px; } }
    .page-filters {
        .spacing-fix {
            &.log {
                margin-bottom: 5px; } }
        .label-wrapper {
            &.log {
                margin-left: 25%; } } }
    .responsive-row {
        display: inline-block;
        .select-wrapper {
            padding-bottom: 5px; }
        &.log {
            display: inline-block; }
        &.tags {
            margin: 0 auto;
            width: 640px; }
        &.comp {
            width: 510px; } } }

@media screen and (max-width: 1000px) {
    .responsive-row {
        &.tags {
            display: grid; } }
    .external-users-wrapper {
        display: none; }
    .activity {
        display: none; } }

/* IE10+ CSS styles go here */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .browser-fix {
        margin-left: 35px !important; } }
