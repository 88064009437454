.teams-font {
    font-family: "SegoeUI", "Segoe UI", sans-serif;
    font-size: 13px; }

.teams-bg {
    background-color: #f5f5f5; }

.teams-preview {
    width: 1200px;
    height: 826px;
    padding: 2px 2px 2px 2px;
    border-radius: 5px;
    border: 1px solid #dde1e5;
    background-color: #dde1e5;
    box-sizing: border-box;

    .teams-logo-bar {
        height: 36px;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        background-color: #6266a3;
        padding: 2px;
        box-sizing: border-box; }

    .tabs {
        @extend .teams-font;
        height: 47px;
        padding: 2px;
        border-bottom: 1px solid #dde1e5;
        background-color: #ffffff;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        .company-name {
            font-weight: 600;
            margin-left: 16px; }

        .logo {
            margin: 0px 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 33px;
            width: 33px;
            padding: 2px;
            border-radius: 8px;
            border: solid 1px #dde1e5;
            font-size: 8px; }

        .tab {
            height: 100%;
            width: 75px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 2px;

            &.active {
                font-weight: 600;
                border-bottom: 3px solid #6266a3; } } }

    .content-container {
        @extend .teams-font;
        @extend .teams-bg;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        height: 736px;
        max-height: 736px;
        overflow-y: scroll;

        .side-bar {
            width: 200px;
            display: flex;
            flex-direction: column;
            margin-top: 12px;

            .item {
                display: flex;
                align-items: center;
                height: 30px;
                padding: 9px 0 9px 17px;
                margin: 7px;

                &.active {
                    background-color: #6266a3;
                    color: #ffffff;
                    font-weight: 500;
                    border-radius: 2px; }

                .side-bar-label {
                    margin-left: 10px; }

                .side-bar-icon {
                    font-size: 20px; } } }

        .content {
            flex: 1;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            max-width: 980px; } } }
