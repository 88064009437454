.callout {
    background-color: #ffffff;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    right: 24px;
    box-sizing: border-box;

    #callout-search-close {
        &:hover {
            cursor: pointer; } }

    &.full-size {
        height: 100%;
        width: 100%;
        overflow-x: hidden; } }

.callout-content {
    height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 8px 12px;
    box-sizing: border-box;

    .callout-checkbox {
        margin-top: -6px;
        margin-bottom: -6px;
        min-width: 32px; } }

.callout-footer {
    background-color: #ffffff;
    border-top: 1px solid #dde1e5;
    display: flex;
    justify-content: flex-end;
    padding: 4px 12px; }

.callout-header {
    background-color: #ffffff;
    border-bottom: 1px solid #dde1e5;
    font-weight: 500;
    line-height: 1.5em;
    min-height: 10px;
    padding: 8px 12px;
    text-align: left; }

.callout-list-item-text-dense {
    display: flex;
    justify-content: space-between; }

.callout-arrow {
    position: relative;

    &.top {
        left: calc(50% - 20px);
        overflow: hidden;
        height: 15px;
        width: 30px;

        &::after {
            content: "";
            position: absolute;
            width: 15px;
            height: 15px;
            background: #ffffff;
            transform: rotate(45deg);
            top: 8px;
            left: 8px;
            box-shadow: 0 0 4px #aaaaaa; } }

    &.right {
        float: none;
        left: 99%;
        top: 65px;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-left: 15px solid #ffffff;
        -webkit-filter: drop-shadow(3px 1px 1px #aaaaaa);
        filter: drop-shadow(3px 1px 1px #aaaaaa); } }

.callout-hover-component {
    display: inline-block; }
