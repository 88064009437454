.theme-section {
    margin-bottom: 20px;

    .left-theme-label {
        margin-left: 42px;
        font-size: 16px;
        width: 33%; }

    .right-theme-label {
        margin-left: 10%;
        font-size: 16px;
        width: 33%; } }

.more-theme-options-kebab {
    visibility: visible;
    margin-right: 20px; }
