#document-feed {
  .view-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    > div:first-child {
      flex-grow: 1; }
    .view-options {
      display: inline-flex;
      align-items: baseline;
      margin-bottom: 20px; } }

  .layout-selection {
    display: none; }

  .sort-selection {
    color: #888888;
    font-size: 12px;
    margin-right: 15px;
    cursor: pointer;
    .selected-sort {
      font-weight: 500;
      margin-left: 5px; } }

  .feed-results {
    margin-bottom: 15px; }

  .no-feed {
    color: #666666; }

  .share-button {
    display: inline-block; } }

.document-request-access {
  position: relative;
  max-width: 350px;
  width: 100%;

  .close-command {
    position: absolute;
    top: 0;
    right: 0; }

  .request-access-icon {
    font-size: 96px; }

  .request-access-image {
    align-items: center;
    background-color: #dde1e5;
    display: flex;
    height: 150px;
    justify-content: center;
    user-select: none; }

  .request-access-message {
    margin: 10px 20px 20px 20px; }

  .request-access-option {
    margin: 0 20px 20px 20px;
    text-align: right; }

  .request-access-title {
    font-size: 20px;
    margin: 20px 20px 10px 20px; } }

@media screen and (min-width: 640px) {
  #document-feed {
    .layout-selection {
      display: block; } } }
