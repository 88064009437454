.documents-listing {
  .header-view {
    display: inline-flex;
    align-items: center; }

  .documents-list {
    .document-state {
      position: relative;
      margin-right: -12px;
      &::before {
        content: "";
        position: absolute;
        left: -16px;
        border-radius: 50%;
        height: 8px;
        top: 5px;
        width: 8px; }
      &.green::before {
        background-color: #3b6531; }
      &.grey::before {
        background-color: #adb7c1; }
      &.yellow::before {
        background-color: #e9b848; } }
    .document-views {
      display: inline-flex;
      align-items: center;
      flex-wrap: nowrap;
      > div {
        display: inline-flex;
        align-items: center;
        flex-wrap: nowrap;
        margin-right: 15px;

        &:last-child {
          margin-right: 0; }

        svg {
          color: #888888;
          margin-right: 4px; } } }

    tbody {
      tr:hover {
        cursor: pointer; } } }

  .document-results {
    margin-bottom: 15px; } }

.documents-attached-article {
  cursor: pointer;
  &:hover {
    text-decoration: underline; } }
