.dashboard {
    padding: 15px 25px;
    .dashboard-tiles {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: -20px;
        margin-bottom: -20px; }

    .dashboard-tile {
        background: #ffffff;
        color: #888888;
        position: relative;
        width: calc(100% - 20px);
        margin-right: 20px;
        margin-bottom: 20px;
        height: 180px;
        border: 1px solid #dde1e5;
        border-radius: 4px;
        cursor: pointer;
        text-align: center;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
            color: #36404a;
            text-decoration: none;
            .dashboard-tile-icon {
                color: rgba(0,0,0,0.4);
                opacity: 1.0; } } }
    .dashboard-tile-icon {
        font-size: 104px;
        line-height: 150px;
        color: rgba(0,0,0,0.2);
        opacity: 0.8;
        margin-bottom: 30px; }
    .dashboard-tile-action {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 15px;
        box-sizing: border-box; } }

.clearfix {
    height: 20px; }

@media screen and (min-width: 600px) {
    .dashboard {
        .dashboard-tile {
            width: calc(50% - 20px); } } }

@media screen and (min-width: 960px) {
    .dashboard {
        .dashboard-tile {
            width: calc(33.333333% - 20px); } } }

@media screen and (min-width: 1280px) {
    .dashboard {
        .dashboard-tile {
            width: calc(25% - 20px); } } }
