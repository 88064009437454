#event-feed, #view-event-dialog, #preview-event-dialog, .content-band-card, .content-band-carousel-item {
  .date-block {
    position: absolute;

    align-items: center;
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column;
    font-size: 14px;
    height: 100px;
    justify-content: center;
    max-width: 115px;
    min-width: 100px;
    padding: 10px;
    width: 25%;

    > div {
      line-height: 1.25em; }

    .day {
      font-size: 32px;
      font-weight: 500;
      line-height: 1em; }

    .month {
      font-size: 16px;
      font-weight: 500; } } }

#event-feed {
  .date-block {
    bottom: 15px;
    left: 15px; } }

#view-event-dialog, #preview-event-dialog {
  .date-block {
    bottom: 20px;
    left: 20px;

    &.date-block-0 {
      z-index: 3; }

    &.date-block-1 {
      bottom: 28px;
      left: 28px;
      z-index: 2; }

    &.date-block-2 {
      bottom: 36px;
      left: 36px;
      z-index: 1; } } }
