.details-container {
    width: 100%;

    .MuiOutlinedInput-root {
        &:hover {
            fieldset {
                border-color: #3b78ab; } } }

    .required {
        &::after {
            content: "*";
            color: #b72026; } }

    .detail-input-group {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        margin-top: 17px;
        justify-content: flex-start;

        .detail-label {
            flex: 1; }

        .detail-input {
            width: 300px;
            text-align: left; } }

    .author-inputs {
        width: 300px; } }

.icon-text-input-error {
    fieldset {
        border-color: #b72026; } }
