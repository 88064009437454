.topic-permission-drawer {
    .interactive-description {
        display: flex;
        flex-direction: column;

        .description {
            display: flex;
            flex-direction: column;

            .red {
                color: red; } }

        .subtitle {
            font-size: 12px;
            color: #333; } } }
