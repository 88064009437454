@import "../../authoring/styles/authoringVariables.sass";

#drawer-bookmark-button {
    height: 44px;
    width: 46px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    position: absolute;
    border-left: solid 2px rgba(0, 0, 0, 0.12);
    border-top: solid 2px rgba(0, 0, 0, 0.12);
    border-bottom: solid 2px rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    z-index: 1201; } // one more than default mui drawer z-index so we can cover its border

.drawer-bookmark-open {
    right: 468px !important;
    transition: right 500ms cubic-bezier(0, 0, 0.2, 1) 0ms !important; }

.drawer-bookmark-closed {
    right: 0px !important;
    transition:  right 500ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important; }
