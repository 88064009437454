.text-area-container {
    background: #f3f3f4;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 5px;
    box-sizing: border-box; }

.text-area-language-body {
    width: 99%;
    height: 150px;
    overflow-y: scroll;
    resize: none;
    padding: 10px;
    box-sizing: border-box;
    border: none;

    &::-webkit-scrollbar-track {
        background: none; } }

textarea[disabled] {
    background: #d1d1d1; }

.text-area-lcid-tab {
    padding: 10px;
    margin-right: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid #f3f3f4;
    background: white;
    user-select: none;
    font-size: 14px;
    display: inline-block;

    &.active {
        font-weight: bold;
        background: #f3f3f4; } }



.dropdown-button-lcid {
    border: none !important;
    color: #03a9f4;
    padding: 10px 15px;
    background: none !important;
    font-size: 24px;
    box-shadow: none !important; }

.dropdown-btn-content-lcid {
    display: none;
    position: absolute;
    background: white;
    z-index: 1;
    text-align: left; }

.dropdown-button-lcid:hover ~ .dropdown-btn-content-lcid {
    display: block; }

.dropdown-btn-content-lcid:hover {
    display: block; }

.language-section {
    overflow-wrap: break-word; }

.language-button-selector {
    position: relative;
    display: inline-block;

    .lcid-options-dropdown {
        color: black;
        display: block;
        width: 150px;
        padding: 10px;
        border-radius: 3px;
        cursor: pointer;
        background: #f3f3f4;
        font-size: 14px;

        &:hover {
            background: #c9eeff;
            text-decoration: none; } } }



.text-area-container-small {
    background: #f3f3f4;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 5px;
    box-sizing: border-box;
    padding-right: 20px;
    padding-top: 20px; }

.text-area-container-single {
    width: 100%;
    display: flex;
    align-content: flex-start;
    padding-right: 10px;
    padding-bottom: 10px; }

.text-area-prompt {
    padding-top: 10px;
    width: 20%;
    padding-left: 5px; }

.text-area-language-double-body {
    width: 99%;
    height: 85px;
    overflow-y: scroll;
    resize: none;
    padding: 10px;
    box-sizing: border-box;
    border: none;
    font-size: 16px;
    font-family: inherit;

    &::-webkit-scrollbar-track {
        background: none; } }
