@import "../../../styles/variables";

#event-feed {
  .feed-results {
    margin-bottom: 15px; }

  .no-feed {
    color: #666666; } }

#view-event-dialog {
  .dialog-content {
    min-width: 200px;
    overflow: hidden; }
  .event-view {
    position: relative;
    max-width: $sparrow-content-modal-width;
    overflow-x: hidden;
    overflow-y: auto; }
  .no-event-data {
    padding: 30px; }
  .close-view-event-dialog {
    position: absolute;
    top: 0;
    right: 0;
    color: #ffffff;
    z-index: 2; }

  .attendance {
    margin: 0 12px;
    padding: 8px 0; }

  .sign-up {
    padding: 10px 20px; }

  .sign-up-button {
    position: relative;
    margin-left: 8px;
    .sign-up-list {
      position: absolute;
      left: -21px;
      right: 1px;
      background-color: #ffffff;
      box-shadow: 0 0 4px #aaaaaa;
      margin-top: 1px;
      z-index: 1; }
    .sign-up-list-item {
      white-space: nowrap; } }

  .sign-up-form {
    font-size: 14px;

    > div {
      margin: 25px 0; } }

  .sign-up-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dde1e5;
    margin: 0 -20px;
    padding: 0 20px 10px 8px;

    > div {
      display: inline-flex;
      align-items: center;

      > button {
        margin-left: 8px; } } }

  .sign-up-info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 14px;
    justify-content: space-between;
    margin-left: -12px;

    > div {
      display: inline-flex;
      align-items: center;
      flex-wrap: wrap; } } }
