.centered-hint-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    p {
        text-align: center; }

    .gif-wrapper {
        width: 50%; } }
