#news-feed {
  .feed-results {
    margin-bottom: 15px; }

  &.horizontal-scrollable {
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 10px;
    &::-webkit-scrollbar {
      height: 8px; }

    @media screen and (min-width: 1280px) {
      &::-webkit-scrollbar-thumb {
        background: transparent; }
      &::-webkit-scrollbar-track {
        background: transparent; }
      &:hover {
        &::-webkit-scrollbar-thumb {
          background: #888888; }
        &::-webkit-scrollbar-track {
          background: #f1f1f1; } } } } }

#post-dialog {
  .no-post-data {
    padding: 30px; } }
