.needs-review-div {
    display: inline-flex;
    align-items: baseline;
    margin-right: 23px;

    .needs-review-dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #ff0000;
        margin-right: 5px;
        align-self: start;
        margin-top: 7px; } }
