@import '../../node_modules/croppie/croppie.css';
@import '../../node_modules/react-datetime/css/react-datetime.css';
@import '../modules/common/components/forms/styles/form-variables.sass';

/* Post Creation */
.expandable-delayed-publishing-setting {
    height: auto;
    overflow: visible;
    margin-top: 8px;
    background-color: #f8f8f8; }

.expandable-delayed-publishing-setting-enter {
    transition: height 400ms;
    height: 0px;
    overflow: hidden; }

.expandable-delayed-publishing-setting-enter-active {
    height: 60px;
    overflow: hidden; }

.expandable-delayed-publishing-setting-leave {
    transition: height 300ms;
    height: 60px;
    overflow: hidden; }

.expandable-delayed-publishing-setting-leave-active {
    height: 0px;
    overflow: hidden; }


/* Croppie */
.croppie-container .cr-slider-wrap {
    display: none; }

.cropping-drag-prompt svg {
    float: left; }

.cropping-drag-prompt {
    position: absolute;
    background-color: rgba(50, 59, 68, 0.53);
    color: white;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.28);
    font-weight: 500;
    line-height: 1.65em;
    padding: 6px;
    display: inline-block;
    z-index: 7;
    margin-left: 50%;
    width: 290px;
    left: 0;
    box-sizing: border-box;
    top: 85%;
    border-radius: 8px;
    border: solid 1px rgba(255, 255, 255, 0.1);
    pointer-events: none;
    font-size: 14px;
    transform: translateX(-50%) translateY(-50%);
    animation: fade-in 1s ease-in-out;
    @keyframes fade-in {
        0% {
            opacity: 0; }
        100% {
            opacity: 1; } } }


.cropping-drag-prompt-enter {
    transform: translateX(-50%) translateY(0%);
    opacity: 0;
    transition: all .4s; }

.cropping-drag-prompt-enter.cropping-drag-prompt-enter-active {
    opacity: 1;
    transform: translateX(-50%) translateY(-50%); }

.cropping-drag-prompt-leave {
    transform: translateX(-50%) translateY(-50%);
    opacity: 1;
    transition: all 50ms ease-in; }

.cropping-drag-prompt-leave.cropping-drag-prompt-leave-active {
    transform: translateX(-50%) translateY(0%);
    opacity: 0; }


/* Image Uploader */
.uploader-error-list-enter {
    transform: translateX(-50%) translateY(-50px);
    opacity: 0.01;
    transition: all 200ms ease-in; }

.uploader-error-list-enter.uploader-error-list-enter-active {
    opacity: 1;
    transform: translateX(-50%) translateY(12px); }

.uploader-error-list-leave {
    transform: translateX(-50%) translateY(12px);
    opacity: 1;
    transition: all 100ms ease-in; }

.uploader-error-list-leave.uploader-error-list-leave-active {
    transform: translateX(-50%) translateY(-50px);
    opacity: 0; }


/* react datetime picker */
.form-control:disabled {
    color: #909090;
    background-color: #f3f3f3; }


/* react select*/

.Select--multi .Select-value {
    color: $focused-label-color; }

// fix for bug https://github.com/JedWatson/react-select/issues/1287
span.Select-value-label {
    white-space: nowrap; }


/* Content Tips */
.content-tip-slide-enter {
    transform: translateY(25%);
    opacity: 0;
    transition: all .6s ease-in-out; }

.content-tip-slide-enter.content-tip-slide-enter-active {
    opacity: 1;
    transform: translateY(0%); }

.content-tip-slide-leave {
    transform: translateY(0%);
    opacity: 1;
    transition: all .4s ease-in-out; }

.content-tip-slide-leave.content-tip-slide-leave-active {
    transform: translateY(15%);
    opacity: 0; }
