#content-bands-main-editor {
    height: 100%;
    max-width: 800px;

    .language-bar-container {
        height: 54px;
        border-radius: 4px;
        border: solid 1px #dde1e5;
        padding: 9px 7px;
        box-sizing: border-box;
        margin-top: 10px; }

    .empty-state {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        .info-text {
            color: #8c8c8c;
            width: 350px;
            text-align: center; } } }
