h4 {
    padding-bottom: 10px;
    font-size: 20px;
    margin: 5px 0; }
.form-section {
    background: #fff;
    padding: 10px 0;
    clear: both;
    box-shadow: none;
    position: relative;
    &.external-users {
        margin-top: 60px; }

    .form-group {
        position: relative;
        &.has-error {
            .form-control {
                border-color: #a00404;
                color: #a00404;
                outline-color: #a00404; } }
        .switch {
            width: 15%; }
        .add-on {
            display: block;
            float: left;
            background: #efefef;
            line-height: 25px;
            height: 31px;
            box-sizing: border-box;
            padding: 2px 5px;
            border-top: 1px solid #e5e6e7;
            border-right: 1px solid #e5e6e7;
            border-bottom: 1px solid #e5e6e7; } } }

.form-control {
    background-color: #FFFFFF;
    background-image: none;
    border: 1px solid #e5e6e7;
    color: inherit;
    display: block;
    padding: 6px 12px;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    font-size: 14px;
    border-radius: 3px;
    margin-bottom: 5px;
    &.date {
        border: 1px solid #D1CFCF; }
    &.error {
        border: 1px solid red; } }

.input-group {
    .form-control {
        display: inline-block;
        float: left; }
    .add-on {
        display: block;
        float: left;
        background: #efefef;
        line-height: 25px;
        height: 31px;
        box-sizing: border-box;
        padding: 2px 5px;
        border-top: 1px solid #e5e6e7;
        border-right: 1px solid #e5e6e7;
        border-bottom: 1px solid #e5e6e7; }
    &:after {
        visibility: hidde;
        display: block;
        font-size: 0;
        content: " ";
        clear: both;
        height: 0; } }
ul.tab-btns {
    list-style: none;
    margin: 0;
    padding: 15px 15px 15px 0;
    li {
        float: left;
        line-height: 22px; }
    &:after {
        visibility: hidden;
        display: block;
        font-size: 0;
        content: " ";
        clear: both;
        height: 0; }
    .tab-btn {
        padding: 15px 15px 12px 15px;
        cursor: pointer;
        transition: border-bottom-width 0.1s ease-in;
        &.active {
            box-sizing: border-box;
            border-bottom: 0 solid #03a9f4;
            border-bottom-width: 3px; } } }

.settings-page, .surveys-page, .activity-page, .support-page, .messaging-center-page {
    padding-bottom: 20px;
    div.tab {
        display: none;
        border: 1px solid #ddd;
        &.non-list-view {
            padding: 20px 15px; }
        &.active {
            display: block; } }
    div.tab-noborder {
        display: none;
        border: 0px solid #ddd;
        &.non-list-view {
            padding: 10px 5px 10px 20px; }
        &.active {
            display: block; } } }

button {
    &.btn {
        background: #fff;
        border: 1px solid rgba(0,0,0,0.2);
        border-radius: 2px;
        padding: 8px 22px;
        font-size: 14px;
        cursor: pointer;
        box-shadow: 0 2px 3px rgba(0,0,0,0.1);
        margin-right: 15px;
        transition: all 0.25s ease-in; }
    &.btn-small {
        padding: 4px 11px;
        font-size: 12px; }
    &.btn-primary {
        background: #2196f3;
        color: #fff; } }
table.table {
    width: 100%;
    border-collapse: collapse;
    th {
        text-align: left;
        font-weight: 100; }
    td {
        border-top: 1px solid #ddd;
        padding: 10px 0; } }
.alert {
    border-radius: 3px;
    padding: 5px;
    font-weight: 100;
    border: 1px solid #bce8f1;
    color: #31708f;
    background-color: #d9edf7;
    ul {
        margin: 0; } }
.alert-info {
    @extend .alert;
    &.wrapped {
        display: inline-block;
        padding-left: 15px;
        padding-right: 15px;
        text-align: center; } }

.alert-danger {
    background: #fff;
    border-color: rgba(160, 4, 4, 0.39);
    color: #a00404; }
.validation-error-msg {
    color: #b93329;
    font-size: smaller;
    position: absolute; }
.badge {
    font-size: 10px;
    background: #ddd;
    border: 1px solid rgba(0,0,0,0.2);
    margin: 5px;
    border-radius: 2px;
    padding: 1px; }
.badge-danger {
    @extend .badge;
    background: #fff;
    color: #a00404;
    border: 1px solid rgba(160, 4, 4, 0.39); }
.toggle-btn-group {
    box-shadow: 0 2px 3px rgba(0,0,0,0.1);
    display: inline-block;
    border-radius: 2px;
    button.btn {
        box-shadow: none;
        margin: 0;
        border-radius: 0;
        font-size: 12px;
        &.active {
            background: #ddd;
            box-shadow: 0 2px 3px rgba(0,0,0,0.1) inset;
            position: relative;
            z-index: 1; } }
    button.btn:first-child {
        border-right: none;
        border-radius: 2px 0 0 2px; }
    button.btn:last-child {
        border-left: none;
        border-radius: 0 2px 2px 0; } }

