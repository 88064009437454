@import './variables';

html,
body {
	height: 100%; }

body {
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-size: 16px;
	line-height: 1.42857143;
	color: #333;
	background: #f3f3f4;
	margin: 0;
	padding: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: bold;
	line-height: 1.1;
	color: #2f4050; }

h1,
h2 {
	font-size: 36px; }

h3 {
	font-size: 24px;
	margin-bottom: 10px; }

a {
	color: #2196F3;
	text-decoration: none;
	cursor: pointer;
	&:hover {
		color: #23527c;
		text-decoration: underline; } }

img {
	vertical-align: middle;
	border: 0; }

p {
	margin: 0 0 10px; }
table.fixed {
	table-layout: fixed;
	th.fixed-50 {
		width: 50px; }
	th.fixed-100 {
		width: 100px; } }
.page-header {
	background: #2196F3;
	color: #fff;
	font-size: 22px;
	padding: 15px; }

.logout {
	.page-header {
		background: none;
		padding: 0;
		h2 {
			font-size: 32px; } }
	.btn-wrapper {
		text-align: center; } }

.external-app {
	#locale-selector {
		float: right;
		margin: 5px; }
	.continue-btn {
		background: #92acc3; } }

$default-image-width: 560px;
$default-image-height: 315px;

#react-app,
#app-base {
	min-height: 100%;
	height: 100%; }

.load-fallback {
	background: #fff;
	padding: 8px;
	border-radius: 2px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	font-size: 14px;
	cursor: pointer;
	margin-top: 15px;
	outline: none;
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1); }

.all {
	color: #222; }

.left {
	float: left; }

.right {
	float: right; }

.clearfix::after {
	display: block;
	content: '';
	clear: both;
	visibility: hidden; }

.disclaimer-block-text {
	display: flex;
	align-items: center;
	background-color: #f4f4f1;
	border-radius: 4px;
	font-size: 14px;
	padding: 10px 15px;

	.info {
        margin-right: 8px;
        svg {
            color: #e9b848; } } }

.primary-content-wrapper {
	margin-left: 225px;
	min-height: 100%;
	box-sizing: border-box;
	position: relative;
	background: #f3f3f3; }

.pause-window-execution {
	background: rgba(0, 0, 0, 0.5);
	z-index: 500;
	position: fixed;
	display: none;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%; }

.primary-content-wrapper-external {
	background: #fff;
	min-height: 100%;
	background: url('/images/sparrow-pricing-banner.jpg');
	background-position: 50% 0 !important {
    background-size: cover !important; }
	.wrapper {
		position: absolute;
		right: 0;
		top: 0;
		left: 0;
		bottom: 0;
		background: url('/images/textured_bg.png'); }
	div.logo-wrapper {
		padding-top: 150px;
		text-align: center;
		width: 400px;
		margin: 0 auto;
		color: #fff;
		.logo {
			height: 35px; }
		.text {
			font-size: 20px;
			line-height: 35px;
			position: relative;
			top: -5px;
			margin-left: 5px; } }
	.links {
		padding: 10px 20px;
		text-align: center;
		a {
			color: #fff;
			margin-right: 15px; } }
	h2 {
		margin: 0; }
	.logout {
		background: rgba(255,255,255,0.9);
		max-width: 400px;
		margin: 0 auto;
		top: 100px;
		padding: 25px;
		box-shadow: 0 2px 3px rgba(0,0,0,0.8);
		border-radius: 2px;
		input {
			line-height: 34px;
			height: 34px !important;
			outline: none;
			border: 1px solid #ddd;
			border-radius: 2px;
			font-size: 16px;
			padding: 5px;
			width: 250px; }
		.spinner-wrapper {
			position: absolute;
			display: inline-block;
			top: -8px;
			left: 0; }
		.btn-wrapper {
			padding-top: 5px; }
		.continue-btn {
			color: $sparrow-color-white;
			background: #337ab7;
			font-size: 22px;
			border: 1px solid rgba(0,0,0,0.3);
			border-radius: 2px;
			padding: 5px 8px;
			cursor: pointer;
			&:disabled {
				cursor: not-allowed;
				opacity: 0.7; } }
		.input-section {
			margin-top: 15px; }
		.valid-indicator {
			position: relative;
			display: inline-block;
			height: 24px; } }

	.options-wrapper {
		margin: 0 auto;
		background-color: #f4f4f4;
		border-radius: 3px;
		position: relative;
		width: 30%;
		height: 40%;
		text-align: center;
		padding: 20px;
		label {
			font-size: 22px;
			color: #666;
			font-weight: 500; } }
	.disabled-wrapper {
		width: 65%;
		padding-top: 18%;
		font-size: 18px;
		margin: 0 auto; }
	.select-wrapper {
		margin: 0 auto;
		padding-top: 20px;
		width: 80%; }
	.go {
		float: right;
		position: absolute;
		bottom: 0;
		right: 0;
		margin-bottom: 15px;
		margin-right: 25px; } }

.select-popover-group-header {
	text-transform: uppercase; }

.select-popover-group-header-clickable {
	font-size: 0.875rem;
	box-sizing: border-box;
	list-style: none;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 500;
	line-height: 48px;
	padding-top: 0px;
	padding-bottom: 0px;
	line-height: 1.43; }

.select-popover-item {
	&.Mui-selected {
		font-weight: 500; } }

@media screen and (max-width: 450px) {
	.logout {
		border-radius: 0 !important; } }
.footer,
.sf-push {
	height: 100px; }

@media screen and (max-width: 575px) {
	.footer,
	.sf-push {
		height: 90px; } }

.post-list .post-list-item {
	opacity: 0;
	cursor: pointer;

	a {
		text-decoration: none; }
	&:hover {
		a {
			color: #337ab7;
			text-decoration: none; } } }

.post-list-item {
	position: relative;
	overflow: hidden;

	.read-indicator {
		width: 75px;
		height: 75px;
		position: absolute;
		right: 0px;
		top: 0px;
		z-index: 10; } }

.post-list-item .post-list-item-summary h3 {
	font-weight: 600; }

.post-list-item.read .post-list-item-summary h3 {
	font-weight: 400; }

.post-list .post-list-item.active {
	transition: opacity linear 0.25s;
	opacity: 1;

	&.tombstone {
		cursor: auto; } }

.post-list-item-summary {
	padding: 15px;
	background: #FFF;
	border-top: 1px solid #ddd;

	h3 {
		margin-bottom: 16px;
		margin-top: 8px;
		font-size: 26px; }

	p.excerpt {
		$font-size: 16px;
		font-size: $font-size;
		color: #595959;
		line-height: $font-size * 1.48;
		margin-bottom: 16px; } }

.author-info {
	color:#aaa {}
	font-style: italic;
	margin-bottom: 0; }

.post-list .post-list-item {
	border: 1px solid #ddd;
	list-style-type: none;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	margin-bottom: 25px;
	box-shadow: 0px 6px 5px -4px rgba(0, 0, 0, 0.13); }

.post-list-heading {
	margin-top: 50px;
	margin-bottom: 50px; }

.post-list .post-list-item.infinite-scroll-continue-block {
	@extend .post-list-item-summary;
	color: #aaa; }

.post-detail {
	animation-name: fade-in;
	animation-duration: 0.25s;
	animation-timing-function: ease-in;
	background: #FFF;
	border: 1px solid #ddd;

	.post-detail-media {
		min-height: 315px;
		max-height: 375px;
		background: $sparrow-color-white url('/images/textured_bg.png');
		border-bottom: 1px solid #ddd;

		&.post-media-is-video {
			min-height: auto; } }

	.post-detail-header {
		padding: 15px;
		h2 {
			margin: 0;
			font-size: 28px;
			color: #595959;
			font-weight: 400;
			margin: 16px 0px; } }

	.media-wrapper {
		border-top: 1px solid #ddd;
		border-bottom: 1px solid #ddd; } }

div.std-img-ratio-wrapper-outer {
	width: 100%;
	display: inline-block;
	position: relative;
	overflow: hidden;
	&:after {
		padding-top: 66.96%;
		display: block;
		content: ''; } }
div.std-img-ratio-wrapper-inner {
	background: url('/images/textured_bg.png');
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	color: white;
	vertical-align: middle; }

// This is designed to reduce "jumpiness" when images are
// loaded on smaller screens and then conatiners are resized to fit images
@media screen and (max-width: 575px) {
	$tmpHeight: 300px;
	.post-detail {
		margin-top: 12px; }

	.post-list .post-list-item {
		border: none; }
	.post-image,
	.post-image-outside-container,
	.post-detail .post-detail-media {
		height: $tmpHeight;
 }		// min-height: 300px
	.post-image.tombstone {
		height: $tmpHeight; } }

// This is designed to reduce "jumpiness" when images are
// loaded on smaller screens and then conatiners are resized to fit images
// (specifically narrow screens like iphone 5)
@media screen and (max-width: 400px) {
	$tmpHeight: 200px;
	.post-detail {
		margin-top: 12px; }

	.post-list .post-list-item {
		border: none; }
	.post-image,
	.post-image-outside-container,
	.post-detail .post-detail-media {
		height: $tmpHeight;
 }		// min-height: 300px
	.post-image.tombstone {
		height: $tmpHeight; } }



@keyframes fade-in {
	0% {
		opacity: 0; }
	100% {
		opacity: 1; } }



.tombstone-loading {
	position: absolute;
	top: -30px;
	width: 100%;
	opacity: 1;
	transition: all ease-in 0.75s;
	z-index: 1;
	&.loaded {
		top: -100px;
		opacity: 0; } }

.tagItem {
	@extend .clearfix;
	box-sizing: border-box;
	border-bottom: solid 1px #ddd;

	label {
		float: left;
		width: 100%;
		cursor: pointer;
		padding: 10px 20px; }

	&:hover {
		background-color: #e9e9e9;

		input[type='checkbox'] + .slider {
			&:after {
				box-shadow: 0px 0px 4px rgb(0, 173, 255);
				border-color: rgb(0, 173, 255); } } } }

.loading-spinner-basic {
	animation: basic-spin-infinite .5s infinite;
	height: 25px; }

.toggleswitch {
	float: right;
	margin-right: 40px;
	cursor: pointer;
	display: inline-block;
	.loading-spinner-basic {
		float: left;
		position: relative;
		right: 35px;
		margin-top: -2px; }
	input[type='checkbox'] {
		display: none; }

	input[type='checkbox'] + .slider {
		width: 50px;
		height: 20px;
		background-color: #ccc;
		border-radius: 14px;
		position: relative;
		display: inline-block;
		&:after {
			content: "";
			display: block;
			width: 24px;
			height: 24px;
			border-radius: 20px;
			background-color: #fff;
			border: solid 1px #aaa;
			margin-top: -3px;
			position: absolute;
			box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.12);
			transition: all .1s;
			transform: translateX(0px); } }

	input[type='checkbox']:checked + .slider {
		background-color: #badce7;

		&:after {
			background-color: #01b5fa;
			border: solid 1px rgb(0, 173, 255);
			transform: translateX(24px);
			box-shadow: -1px 2px 1px rgba(0, 0, 0, 0.12); } } }

@keyframes basic-spin-infinite {
	0% {
		transform: rotate(0deg); }
	100% {
		transform: rotate(360deg); } }

.last-saved-message {
	font-weight: 100;
	color: #aaa;
	font-size: 14px;
	margin-left: 24px; }

.reaction-item {
	float: left;
	width: 25px;
	margin-left: -8px;
	position: relative;
	transition: all 0.3s;
	padding: 3px;
	border-radius: 50%;
	background: rgba(255,255,255,0.9);

	// reverse z-indexing so that first reaction is top
	@for $i from 1 through 10 {
		&:nth-child(#{$i}) {
			z-index: #{10 - $i}; } }

	img {
		width: 100%;
		height: auto; } }


.reaction-timeline-display {
	display: block;
	float: right;
	position: relative;
	margin-top: -66px;

	.reaction-item {
		margin-left: 6px;
		position: relative;
		transition: all 0.3s;
		padding: 6px;
		border-radius: 50%;
		background: rgba(255,255,255,0.9);
		box-shadow: 0px 2px 3px rgba(0,0,0,0.3); } }

.reactions-in-post {
	padding: 2px 10px;
	float: left; }

.reaction-picker {
	@extend .clearfix;
	position: absolute;
	padding: 10px 16px;
	box-shadow: 0 3px 12px -3px rgba(0,0,0,0.5);
	border-radius: 24pt;
	float: left;
	margin-top: -65px;
	z-index: 50;
	background-color: white;
	visibility: visible;
	pointer-events: none;
	opacity: 0;
	transform: translateY(-4px);
	transition: all 0.2s ease-out;

	.reaction-item {
		margin-left: 6px;
		margin-right: 24px;
		width: 40px; } }

.sentiment-name {
	display: none; }

.sentiment-count {
	display: none;
	float: left;
	position: absolute;
	right: -16px;
	top: 12px;
	pointer-events: none; }

.add-reaction-button {
	line-height: 20px;
	font-size: 16px;
	padding-top: 5px;
	display: block;
	padding-left: 5px;
	float: left;
	width: 100px; } // larger hover target area

.reactions-post-picker {

	@media screen and (max-width: 575px) {
		.reaction-item {
			transition: none; }
		.reaction-item {
			transition: none;
			width: 30px;
			margin-right: 10px;

			.sentiment-count {
				font-size: 12px;
				right: -10px; } }
		&:hover {
			img {
				transform: scale(1); } }

		.reaction-picker {
			left: 5px;
			right: 5px;
			height: 46px;
			box-sizing: border-box;
			padding: 4px 8px; } }

	&:hover {
		.reaction-picker {
			visibility: visible;
			opacity: 1;
			transform: translateY(0px);
			pointer-events: all;

			.reaction-item {
				cursor: pointer;
				position: relative;

				img {
					float: left; }

				.sentiment-name {
					position: absolute;
					top: -32px;
					background-color: rgba(0,0,0,0.5);
					color: white;
					padding: 1px 6px;
					border-radius: 10pt;
					text-align: center;
					font-size: 14px;
					transition-delay: 0.1s;
					transition: all 0.1s;
					left: -10px;
					right: -10px; }

				.sentiment-count {
					display: block; }

				&:hover {
					img {
						transform: scale(1.25); }

					.sentiment-name {
						display: block; } } }

			.reaction-item.selected {
				.sentiment-count {
					font-weight: bold; } } } } }

div.fetching-message {
	margin: 25px; }

@import './menu';
@import './header';
@import './forms';
@import './vendor-components';
@import './dashboard';
@import './users';
@import './analytics';
@import './charts';
@import './settings';
@import './successfullyPublishedDialog';
@import './imageGallery.sass';
@import './filtersItem';
@import './notyPopups.sass';

.main-content-spacer {
	padding: 50px 150px; }

.login-to-view {
	text-align: center; }

.loading-overlay {
	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.5);
	&.absolute {
		position: absolute; }
	&.token {
		padding-top: 0; }
	.loading-spinner {
		position: absolute;
		top: calc(50% - 20px);
		left: calc(50% - 20px);
		padding: 0; } }

.loading-spinner {
	padding: 12px; }

.loading-token {
	z-index: 15;
	height: 60%;
	width: 60%;
	position: absolute;
	top: 20%;
	left: 20%;
	background-color: rgba(255, 255, 255, 0.8);
	text-align: center;
	.refresh {
		color:  rgba(102, 102, 102, 0.8);
		font-size: 18px;
		padding-bottom: 10px;
		font-weight: bold; } }

.sparrow-post-type-selector {
	display: inline-block;
	width: 40%;
	float: right;
	z-index: 1; }

.post-read-dropoff {
	img, iframe {
		max-width: 100%; } }

.progressive-image {
	max-height: 350px;
	width: auto;
	max-width: 100%;
	display: inherit;
	margin: 0 auto; }

.view-button {
	color: white;
	background-color: #4794e5;
	padding: 10px 20px;
	margin-top: 8px;
	float: right;
	border: none;
	outline: none;
	cursor: pointer;
	margin-right: 10px; }
