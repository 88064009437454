.teams-purple {
    background-color: #6266a3; }

.cb-teams-preview {
    margin-top: 25px;
    padding-bottom: 90px;

    .cb-header {
        font-size: 21px;
        margin-left: 16px; }

    .cb-content {
        &.window {
            padding-bottom: 20px; } } }
