$colors: ("2196F3", #2196F3), ("E91E63", #E91E63), ("009688", #009688), ("5E35B1", #5E35B1), ("D81B60", #D81B60),("E53935", #E53935),("673AB7", #673AB7),("388E3C", #388E3C),("F44336", #F44336),("43A047", #43A047),("3949AB", #3949AB),("00897B", #00897B),("1E88E5", #1E88E5),("8E24AA", #8E24AA), ("9C27B0", #9C27B0), ("3F51B5", #3F51B5);

@each $color in $colors {
  .mentions-icon-formatting.avatar-color-#{nth($color, 1)} {
    background-color: #{nth($color, 2)}; } }

.post-view, .post-preview {

  .comments-heading {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eaeaea;
    box-sizing: border-box;
    font-size: 18px;
    font-weight: 500;
    height: 30px;
    opacity: 0.74;
    padding-bottom: 5px; }

  .sort {
    position: relative;
    color: #888888;
    font-size: 12px;
    text-align: right;
    margin: 5px 0;

    .sort-selector {
      background-color: transparent;
      color: inherit;
      font-size: inherit;
      font-weight: 500;
      height: 1em;
      padding: 7px 0;
      text-transform: none;
      i {
        color: inherit; } } }

  .sort-menu {
    position: absolute;
    top: 24px;
    right: 1px;
    background-color: #ffffff;
    box-shadow: 0 0 4px #aaaaaa;
    margin-top: 1px;
    z-index: 1; }

  .comment {
    position: relative;
    display: flex;
    padding: 2px 0;
    &:last-child {
      border-bottom-width: 0; }
    > div {
      position: relative;
      flex-grow: 1;
      &.commands {
        position: absolute;
        display: inline-flex;
        align-items: center; }
      &.star-comment {
        flex-grow: 0;
        top: 2px; } } }

  .star-comment {
    opacity: 0.74;
    font-size: 12px;
    text-align: center;
    margin-right: 10px;
    flex-shrink: 0;
    transition: opacity 0.2s linear;
    user-select: none;
    .star {
      height: 30px;
      width: 30px; }
    &.starrable {
      &:hover {
        svg {
          cursor: pointer; } } }

    &.starred {
      color: #2196f3;
      svg {
        fill: #2196f3;
        cursor: pointer; } }
    &.not-destarrable {
      svg {
        cursor: default; } } }

  .avatar {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    .author-image {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 45px;
      width: 45px;
      border-radius: 50%;
      background-color: #2196f3;
      color: #ffffff;
      font-size: 24px;
      user-select: none; } }

  .comment-info {
    font-size: 12px;
    margin-top: 4px;
    .author {
      display: inline-block;
      opacity: 0.74; }

    .date {
      display: inline-block;
      opacity: 0.52;
      margin-left: 16px; }

    .body {
      font-size: 14px;
      white-space: pre-wrap;
      padding: 3px 0;
      word-break: break-word; }

    .edited {
      opacity: 0.64;
      font-size: 10px;
      user-select: none;
      &::before {
        content: '';
        display: inline-block;
        height: 3px;
        width: 3px;
        background-color: #bbbbbb;
        border-radius: 50%;
        vertical-align: middle;
        margin-right: 6px; } } }

  .commands {
    top: 12px;
    right: 0;
    user-select: none; }

  .maximum-word-count {
    opacity: 0.74;
    font-size: 12px;
    line-height: 1em; }

  .new-comment {
    margin: 10px 0;

    > div {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px; } }

  .edit-comment {
    margin: 10px 0;
    position: relative;

    .tenor-positioning {
      z-index: 2;
      position: absolute;
      top: 30px;
      background-color: white;
      box-shadow: 0px 10px 10px 1px rgba(0,0,0,0.75);
      margin: 10px;
      padding: 10px;
      width: 94.7%;
      left: -9px;

      .react-tenor {
        display: flex;
        flex-direction: column;
        position: relative;
        margin: auto; }

      .react-tenor--search {
        padding-top: 5px;
        padding-bottom: 5px; } }

    > div {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px; } }

  .cancel-edit {
    display: inline-block;
    background-color: transparent;
    color: #aaaaaa;
    margin-left: 10px;
    &:hover {
      background-color: inherit;
      color: inherit; } }

  .pending-deletion {
    filter: grayscale(1);
    pointer-events: none;
    user-select: none;
    > div {
      opacity: 0.5; }

    .command {
      cursor: default;
      &:hover {
        i {
          color: #cccccc; }
        svg {
          fill: #cccccc; } } } }

  .pending-star {
    .star-comment {
      .star {
        opacity: 0; } }

    .star-pending {
      position: absolute;
      top: 22px;
      width: 30px; } } }

.comment-bottom-border {
  border-bottom: 1px solid rgb(234, 234, 234); }

.comment-footer-section {
  display: flex;
  flex-direction: row;
  margin-left: -15px;

  .star-icon {
    position: relative;
    top: 5px;
    margin-left: 8px;
    margin-right: 4px; }

  .divider-padding {
    margin-left: 20px;
    margin-top: 8px; }

  .reply {
    position: relative;
    top: -3px;

    .button-padding {
      margin-left: 4px; }

    .reply-icon {
      position: relative;
      top: 6px;
      marginleft: 8px;
      transform: scale(1, -1);
      margin-right: 4px; } } }

.post-view,
#comment-command-menu {
  .command {
    display: inline-block;
    min-height: 1px;
    height: 22px;
    max-height: 32px;
    width: 14px;
    margin: 0 4px;
    vertical-align: middle;
    flex-shrink: 0;
    &.flagged {
      height: 32px;
      svg {
        fill: #d64c5d; } } } }

@media screen and (min-width: 640px) {
  .post-view {
    .avatar {
      display: block; }

    .comment-info {
      margin-left: 60px; } } }

.mentions-formatting {
  position: relative;
  .tiny-text-holder {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px; }

  .add-comment-tenor {
    min-height: 410px;
    background-color: white;
    box-shadow: 0px 10px 10px 1px rgba(0,0,0,0.75);
    margin: 10px;
    padding: 10px;
    width: 95.8%;
    padding-bottom: 15px;
    position: absolute;
    top: 40px;
    left: -9px;
    z-index: 3; } }

.tox :not(svg):not(rect) .mentions-email-formatting {
  font-size: 12px; }

.tox :not(svg):not(rect) .mentions-icon-formatting {
  display: inline-block;
  font-size: 18px;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  text-align: center;
  line-height:  35px;
  margin-right: 15px;
  color: white; }

.react-tenor {
  width: 100%;
  margin: auto;
  margin-top: 10px;

  .react-tenor--search {
    padding-top: 5px;
    padding-bottom: 5px; } }

.react-tenor-active {
  box-shadow: 0px 0px 0px 0px; }

.react-tenor--search:focus {
  box-shadow: 0 0 2px 2px #3B78AB; }

.react-tenor--page-left, .react-tenor--page-right {
  background: #3B78AB; }

.react-tenor--suggestions button {
  background: #3B78AB; }

.image-area-container {
  max-width: 90%;
  margin: auto;
  margin-top: 10px;

  .prompt-text {
    font-size: 16px; }

  .image-url-input {
    width: 100%; }

  .button-container {
    margin-top: 10px;
    margin-bottom: 10px;
    float: right; } }
