.one-last-check {
    max-height: 905px;

    .title-container {
        display: flex;
        align-items: center;
        height: 64px;
        padding: 22px 17px 13px 24px;
        border-bottom: solid 1px #dde1e5;
        box-sizing: border-box;

        .title {
            font-weight: 500;
            flex: 9; } }

    .content-container {
        padding: 0;

        .info-row {
            display: flex;
            margin-bottom: 13px;
            align-items: flex-start;

            .data {
                flex: 2;

                .reminder-label {
                    margin-bottom: 9px; }

                .title {
                    font-weight: 500;
                    color: #7f7f7f;
                    margin-bottom: 10px; }

                .selected-option, .unselected-option {
                    display: flex;
                    align-items: center; }

                .selected-option {
                    color: #366531; }

                .unselected-option {
                    color: #aaaaaa; } }

            .info-label {
                color: #7f7f7f;
                flex: 0.5;
                font-size: 16px;
                display: flex;
                align-items: center; } }

        .tabs-container {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding-right: 21px;
            padding: 0 21px;
            border-bottom: solid 1px #adb7c1;

            .preview-info {
                margin-left: auto;
                font-size: 13px;
                color: #666666;
                font-style: italic; }

            .one-last-check-tabs {
                display: inline-flex; }

            .tab-label {
                text-transform: none; }

            .selected {
                color: #3b78ab;
                font-weight: 700; } }

        .preview-tab, .more-details-tab {
            overflow-y: scroll;
            height: 563px; }

        .preview-tab {
            background-color: #dde1e5;

            .post-preview-page {
                margin-top: 1px; } }

        .more-details-tab {
            padding-top: 19px;
            .info-row {
                .info-label {
                    flex: 0.8; }

                .data {
                    .reminder-row {
                        margin-bottom: 7px; } } } } } }
