#event-feed {
	.card, .list, .window {
		.truncated-text-1 {
			display: block;
			word-break: break-word;
			word-wrap: break-word;
			text-wrap: wrap; }

		.topics {
			color: #888888;
			margin-top: 1.2em;

			svg {
				margin-right: 5px; }

			&.truncated-text-1 {
				display: -webkit-box!important;
				overflow: hidden;
				text-overflow: ellipsis;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical; } }

		.title {
			display: flex;
			flex-wrap: nowrap;
			align-items: baseline;
			font-size: 18px;
			font-weight: 500;
			line-height: 1.2em;
			margin: 0 0 10px 0;
			word-break: break-word; }

		.info {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			font-size: 14px;
			padding: 15px; } }

	.attendance {
		padding: 0 10px 10px 10px;

		.attending-in-person,
		.attending-online,
		.not-attending,
		.response-required,
		.waitlisted {
			font-weight: bold; }

		.attending-in-person,
		.attending-online {
			color: #008000; }

		.not-responded-yet {
			font-style: italic; }

		.response-required {
			color: #a80000; }

		.waitlisted {
			color: #faaa2b; } } }
