.group {
    background-color:#f4f4f4 {}
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    height: 35px;
    padding-top: 8px;
    color: #666;
    p {
        margin-left: 10px;
        font-weight: bold;
        font-size: 18px; }
    .new-tag {
        margin-left: 15px;
        display: flex;
        button {
            padding: 0px 10px;
            margin-left: 10px;
            background: #2196f3;
            color: #ffffff;
            height: 30px;
            &:disabled {
                color: #ccc;
                cursor: default; } } }
    .add-tag {
        height: 28px;
        margin-right: 10px;
        padding: 5px 10px;
        margin-left: 10px; }
    .disable-btn {
        margin-left: auto;
        height: 28px;
        margin-right: 10px;
        padding: 5px 10px; }
    .grp-delete-btn {
        height: 28px;
        margin-right: 10px;
        padding: 5px 10px; }
    .edit-btn {
        height: 28px;
        padding: 0px 5px;
        margin-right: 10px; } }

.tag-detail {
    line-height: 35px;
    margin-left: 35px;
    padding-top: 5px;
    font-size: 18px;
    width: 95%;
    min-height: 35px;
    &.border:not(:last-child) {
        border-bottom: 1px solid #ddd; }

    .stats-table {
        width: 25%;
        table-layout: fixed; }

    .stat {
        min-width: 350px;
        display: flex;
        justify-content: space-evenly; }

    .tag-row {
        margin-left: 5px;
        position: relative;
        display: flex;
        justify-content: space-between; }

    .drag-handle {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        color: #ddd;
        display: block;
        cursor: move;
        top: 10px;
        position: absolute;
        line-height: 5px;
        margin-left: -28px; } }

.new-tag-modal {
    .option-wrapper {
        display: flex;
        margin-bottom: 20px;
        label {
            font-weight: bold;
            margin-right: 10px; } }
    p {
        margin-top: -10px; }
    .select-wrapper {
        margin-bottom: 10px; }
    .error-mssg {
        left: 20px;
        bottom: -30px;
        width: 50%;
        position: absolute;
        color: red; } }
