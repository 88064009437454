.post-preview-page {
    margin: 0; }

.desktop-preview-container {
    display: flex;
    justify-content: flex-start;
    max-width: 1200px;
    flex-basis: 1200px;

    .desktop-preview-content {
        max-width: 1200px;
        flex-basis: 1200px;
        height: 787px;
        padding: 7px 9px 4px;
        border-radius: 5px;
        border: solid 1px #dde1ef;

        .desktop-preview-fake-browser-toolbar {
            height: 36px;
            background-color: #dde1e5;
            flex-basis: 1188px;
            border-top-left-radius: 7px;
            border-top-right-radius: 7px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .circle {
                width: 15px;
                margin-right: 7px;
                height: 15px;
                padding: 2px 2px 2px 2px;
                border-radius: 27px;
                background-color: #7f7f7f;
                box-sizing: border-box; } }

        .desktop-preview-fake-browser-content {
            flex-basis: 1188px;
            height: 731px;
            border-bottom-left-radius: 7px;
            border-bottom-right-radius: 7px;
            background-color: #cfcfcf;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            justify-content: center;

            .desktop-preview-modal {
                flex-basis: 800px;
                max-width: 800px;
                background-color: #ffffff;
                overflow-y: scroll;

                .post-preview-page {
                    margin-top: 0; } } } } }
