.date-time-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .picker-label {
        width: 40px; }

    .date-picker {
        width: 200px; }

    .time-picker {
        width: 150px; } }

