.sidebar {
    background: #2f4050;
    height: 100%;
    width: 225px;
    position: fixed;
    color: #fff;
    box-sizing: border-box;
    overflow-y: auto;
    ul.menu {
        margin-top: 0;
        padding-left: 0;
        list-style: none;
        li {
            font-size: 14px;
            a {
                color: #a7b1c2;
                text-decoration: none; }
            &.active, &:hover {
                background: #293846;
                a {
                    color: #fff; } }

            .inner-menu-wrap {
                width: auto;
                display: block;
                padding: 8px 25px; } } } }

.account {
    padding: 25px;
    box-sizing: border-box;
    position: relative;
    min-height: 180px;
    .background {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        opacity: 0.25;
        background: url(/images/textured_bg.png);
        z-index: -1; }
    .account-img img {
        height: 48px; }

    .dropdown-container {
        .account-name {
            margin-top: 8px;
            line-height: 16px;
            a {
                color: #fff;
                display: block; }
            span {
                color: #d0d0d0;
                font-size: 12px;
                padding-top: 8px;
                .caret {
                    font-size: 8px;
                    margin-left: 5px; } } }
        .dropdown {
            height: 0;
            opacity: 0;
            position: absolute;
            padding: 15px;
            font-size: 12px;
            border-radius: 2px;
            width: 175px;
            box-sizing: border-box;
            box-shadow: 0 2px rgba(0,0,0,0.5); }

        .change-tenant {
            height: 0px; }

        &:hover {
            .dropdown {
                transition: all 0.25s ease-in;
                display: block;
                height: auto;
                opacity: 1;
                background: #fff;
                color: #595959; }

            .change-tenant {
                height: 35px;
                color: #2196F3;
                text-decoration: none;
                cursor: pointer;
                &:hover {
                    text-decoration: underline; } } } } }

