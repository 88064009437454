@import './common.sass';

#news-feed {
    .list, .column {
        .tile {
            border-bottom: 1px solid #dde1e5;

            .stats {
                color: #888888;
                display: flex;
                flex-direction: row;

                div {
                    font-size: 14px;

                    svg {
                        width: 15px; }

                    span {
                        position: relative;
                        top: -7px;
                        padding-left: 2px;
                        padding-right: 4px; } } } } }

    &.scrollable {
        .card {
            display: flex;
            flex-wrap: nowrap;
            .tile {
                max-width: 350px;
                margin-right: 15px;
                min-width: 300px; } } } }
