#document-feed {
  .list {
    .document {
      cursor: pointer;
      button {
        color: #aaaaaa;
        margin-left: 8px; }

      .category-tags {
        max-width: 100px; }

      .file-type {
        height: 45px;
        width: 36px; }

      .new,
      .unread,
      .updated {
        display: inline-block;
        color: #ffffff;
        font-size: 11px;
        line-height: 1em;
        padding: 4px; }

      .new,
      .updated {
        font-weight: 500;
        text-transform: uppercase;
        width: 50px; }

      .new {
        background-color: #3b6531; }

      .unread {
        background-color: #aaaaaa; }

      .updated {
        background-color: #e6911a; }

      .read {
        color: #aaaaaa;
        font-size: 12px; } } } }
