.survey-details-wrapper {
  padding: 5px 30px;
  max-width: 800px;
  h1, h2 {
    margin: 10px 0px; }

  h2.total-responses {
    font-size: 32px; }

  h4 {
    text-align: center; }

  .survey-metadata {
    border-bottom: 1px solid #aaa;
    margin-bottom: 30px;
    border-top: 1px solid #aaa;
    padding: 5px;
    min-height: 90px;

    .left {
      display: inline-block;
      width: 50%;
      margin: 0;
      text-align: center; }
    .right {
      display: inline-block;
      width: 50%;
      margin: 0;
      li {
        padding: 3px;
        font-size: 1.05em;
        list-style: none; } } }

  .survey-data {
    h2 {
      border-bottom: 1px solid #aaa;
      margin-bottom: 20px;
      padding: 5px; }

    .survey-question {
      font-size: 1.1em;
      padding: 5px; } } }
