#email-editor-content {
    margin-top: 50px;

    .email-editor-paper {
        flex-basis: 880px !important;
        max-width: 880px !important;

        .tox.tox-tinymce.tox-fullscreen {
            .tox-editor-container {
                background: #ffffff;

                .tox-sidebar-wrap {
                    margin: auto;
                    width: 800px; } } } } }
