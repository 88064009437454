.portal-pages-dialog-header {

  &.MuiDialogTitle-root {
    padding-bottom: 0;
    padding-top: 0;
    border-bottom: 1px solid #dde1e5; }

  .portal-pages-dialog-header-title {
    display: flex;
    justify-content: space-between;
    align-items: center; }

  button {
    margin-right: -16px; } }

.portal-pages-dialog-footer {

  &.MuiDialogActions-root {
    padding: 16px 24px; } }

.portal-page-dialog-content {
  font-size: 14px;
  width: 400px;
  padding: 8px 24px;

  .MuiInputLabel-outlined {
    transform: translate(14px, 13px) scale(1); }

  .MuiOutlinedInput-input {
    padding: 10px 15px; }

  .audiences-list {
    > div {
      background-color: #dde1e5;
      margin-bottom: 5px;
      margin-right: 5px;
      &:last-child {
        margin-right: 0; } }
    .all-users-audience {
      font-style: italic; } }

  .audiences-list-label {
    align-self: flex-start;
    padding: 8px 0; }

  .emphasis {
    font-weight: 500; }

  .portal-page-dialog-content-form {
    > div {
      display: flex;
      align-items: center;
      margin: 15px 0;

      &:first-child {
        margin-top: 0; }

      &:last-child {
        margin-bottom: 0; }

      > div:first-child {
        flex-grow: 0;
        flex-shrink: 0;
        width: 130px; } } }

  > div {
    margin: 5px 0; } }

.portal-pages-list-drawer {
  width: 450px;

  .portal-pages-list-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dde1e5;
    font-size: 20px;
    font-weight: 500;
    padding: 10px 0; }

  .global-components-list {
    font-size: 14px;
    > div {
      box-sizing: border-box;
      cursor: pointer;
      margin: 16px 24px;
      padding: 10px;
      text-align: center;
      width: calc(50% - 24px);
      img {
        height: 110px;
        max-width: 100%; }
      div {
        padding: 5px 0; } } } }

#portal-page-preview {
  .MuiDialog-paperScrollPaper {
    width: 100%; }
  .portal-page-preview-page {
    border: 1px solid #aaaaaa;
    border-radius: 3px;
    margin: 10px 0;
    padding: 3px; }
  .preview-disclaimer {
    color: #888888;
    font-size: 13px; }
  .portal-page-preview-header {
    &.MuiDialogTitle-root {
      padding-bottom: 0; }
    .portal-page-preview-header-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      > div {
        display: inline-flex;
        align-items: center;
        > div, > button {
          margin-left: 10px; } } } } }
