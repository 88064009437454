.category-tags-listing {
  .category-tags-list {
    .category-tag-state {
      position: relative;
      margin-right: -12px;
      &::before {
        content: "";
        position: absolute;
        left: -16px;
        border-radius: 50%;
        height: 8px;
        top: 5px;
        width: 8px; }
      &.green::before {
        background-color: #3b6531; }
      &.grey::before {
        background-color: #adb7c1; } }
    .category-tag-time {
      display: inline-flex;
      align-items: center;
      svg.modified-time {
        color: #3b6531;
        padding-left: 4px; }
      svg.published-time {
        color: #e9b848;
        padding-left: 4px; } }
    .category-tag-title {
      display: inline-flex;
      align-items: center;
      svg {
        color: #888888;
        padding-left: 4px; } } }

  .category-tag-results {
    margin-bottom: 15px; } }
