.hover-add-btn-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    height: 38px;
    padding: 0px 27px;

    .child {
        display: none;

        &.action {
            max-width: 14px;
            padding: 0;

            .add {
                color: #ffffff;
                font-size: 28px;
                position: relative;
                right: 45px; }

            .circle {
                font-size: 62px; } }

        &.line {
            flex: 1;
            border-bottom-width: 2px; } }

    &:hover {
        .child {
            display: inline-flex; } } }
