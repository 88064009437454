.callout-multiselect-content {
    width: 246px;
    padding: 6px 0 9px !important;
    display: flex;
    flex-direction: column;

    &.full-size {
        height: 100vh;
        width: 100vw; }

    .callout-multiselect-item {
        flex: 1;
        color: #333333;
        height: 50px;
        max-height: 50px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        &:hover {
            background-color: #f8f8f5; }

        &.selected {
            background-color: #f8f8f5; }

        .main-item {
            flex: 3;
            justify-content: flex-start;
            color: #333333;
            font-weight: normal;
            &:hover {
                background-color: transparent; }
            &.selected {
                font-weight: 500; } }

        .action {
            flex: 1;
            &:hover {
                background-color: transparent; } } }


    .callout-multiselect-default-item {
        font-size: 12px;
        color: #aaaaaa;
        text-transform: none;
        font-weight: normal; } }

.chip-list-container {
    display: flex;
    flex-wrap: wrap; }

.regular-case-button {
    text-transform: none; }
