@import "../../../../../styles/variables.sass";

.post-main-panel {
    .tox.tox-tinymce {
        border: none;

        // keep view content modal width when fullscreen
        &.tox-fullscreen {
            .tox-editor-container {
                background: #ffffff;

                .tox-sidebar-wrap {
                    margin: auto;
                    width: $sparrow-content-modal-width; } } } }

    .tox-editor-header {
        border:  1px solid #dde1e5 !important;
        border-radius: 10px 10px 0 0;
        box-shadow: none !important;
        z-index: 0 !important; } } // so smart content drag covers editor
