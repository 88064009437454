#document-feed {
  .card {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -10px;

    .document {
      position: relative;
      display: inline-flex;
      background-color: #ffffff;
      border: 1px solid #dde1e5;
      box-sizing: border-box;
      flex-direction: column;
      margin-bottom: 15px;
      overflow: hidden;
      width: 100%;
      cursor: pointer;
      &:hover {
        box-shadow: 0 0 5px 1px #dde1e5; }

      .details {
        box-sizing: border-box;
        flex-grow: 1;
        font-size: 12px;
        min-height: 100px;
        padding: 15px 10px;

        .category-tag {
          text-decoration: underline;
          word-break: break-word; }

        .file-type {
          height: 75px;
          margin-bottom: 15px; }

        .title {
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 8px;
          word-break: break-word; } }

      .footer {
        display: flex;
        align-items: center;
        border-top: 1px solid #dde1e5;
        box-sizing: border-box;
        height: 30px;
        justify-content: space-between;
        padding: 5px 10px;

        .footer-options {
          display: inline-flex;
          align-items: center;
          flex-wrap: nowrap; }

        button {
          color: #aaaaaa;
          margin-left: 8px; } }

      .new,
      .unread,
      .updated {
        display: inline-block;
        color: #ffffff;
        font-size: 11px;
        line-height: 1em;
        padding: 4px; }

      .new,
      .updated {
        position: absolute;
        font-weight: 500;
        text-transform: uppercase;
        top: 15px;
        right: 10px; }

      .new {
        background-color: #3b6531; }

      .unread {
        background-color: #aaaaaa; }

      .updated {
        background-color: #e6911a; }

      .read {
        color: #aaaaaa;
        font-size: 12px;
        line-height: 1em;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap; } } }

  @media screen and (min-width: 640px) {
    .card {
      .document {
        padding: 0; } } }

  @media screen and (min-width: 640px) and (max-width: 799px) {
    .card {
      .document {
        flex-basis: calc(50% - 7.5px);
        &:nth-child(even) {
          margin-left: 15px;
          margin-right: 0; } } } }

  @media screen and (min-width: 800px) and (max-width: 1023px) {
    .card {
      .document {
        flex-basis: calc(33.33333% - 10px);
        &:nth-child(3n+2) {
          margin-left: 15px;
          margin-right: 15px; } } } }

  @media screen and (min-width: 1024px) {
    .card {
      .document {
        flex-basis: calc(25% - 11.25px);
        &:not(:nth-child(4n+4)) {
          margin-left: 0;
          margin-right: 15px; } } } } }
