#user-profile {
  .flagging-notifications-subscription {
    color: #333333;
    margin-top: -5px;
    > span {
      font-size: 14px; } }
  .role-permission {
    display: flex;
    align-items: center;
    margin: 15px 0;
    > div:first-child {
      flex-shrink: 0;
      margin-right: 16px; } }
  .submission-manager {
    > div {
      display: flex;
      justify-content: space-between;
      > div:last-child {
        margin: -12px; } } } }

.user-profile-menu {
  font-size: 12px;
  padding: 10px 15px 15px 15px;

  .menu-item {
    color: #888888;
    cursor: pointer;
    line-height: 1.3em;
    margin: 0 -15px;
    padding-left: 10px;
    transition: background-color 0.1s linear;
    user-select: none;
    &:hover, &.selected {
      background-color: #f3f3f4; }
    > div {
      align-items: center;
      border-bottom: 1px solid #dde1e5;
      display: flex;
      padding-right: 15px;
      > svg {
        margin: 0 5px; }
      > div {
        flex-grow: 1;
        padding: 15px 5px; } }
    .item-header {
      color: #333333;
      font-size: 14px;
      font-weight: 500; } } }

.user-profile-left-column {
  margin: -2px;
  padding: 2px;
  overflow-y: auto; }

.delete-account-button {
  background-color: #a80000;
  color: #ffffff;
  &:hover {
    background-color: #a80000;
    color: #ffffff; } }


.submission-manager {
  > div {
    display: flex;
    justify-content: space-between;
    > div:last-child {
      margin: -12px; } } }
