.authoring-gallery-vfs {
    padding: 0px;

    .chonky-chonkyRoot {
        border: none; }

    span.chonky-extraInfoSpan {
        display: none; }

    .item-selected-number {
        position: absolute;
        right: 0;
        background-color: #3b78ab;
        color: #ffffff;
        font-size: 14px;
        font-weight: 500;
        padding: 6px 6px 2px 6px;
        text-align: center;
        width: 22px;
        border-radius: 5px 0px 5px 0px; }

    div.image-link-textfield {
        width: 100%;

        div.MuiInputBase-root {
            width: 95%;
            margin-left: auto;
            margin-right: auto; } }

    div[data-test-id="file-entry"] {
        border: 4px solid transparent;
        border-radius: 5px;

        &[class^="listFileEntry"] {
            -webkit-box-sizing: border-box;
            border: 2px solid transparent;

            div.item-selected-number {
                bottom: 0px;
                padding: 4px 2px 6px 4px;
                height: 65%;
                border-radius: 0px 1px 1px 0px; } }

        &[class^="gridFileEntry"] {
            div.item-selected-number {
                bottom: -4px; } }

        &:hover, &.item-selected {
            border-color: #3b78ab; }

        span[title]:not([title=""]) {
            background-color: transparent; }

        div[class^="selectionIndicator"] {
            display: none; }

        div[class^="focusIndicator"] {
            display: none; }

        div[class^="previewFile"] {
            box-shadow: rgba(255, 255, 255, 0.4) 0px 0px 0px 999px inset; }

        div[class^="fileIcon"] {
            display: none; }

        div[class^="folderBackSideMid"] {
            div[class^="fileIcon"] {
                opacity: 1;
                color: rgb(255, 255, 255);
                display: block; } } } }

.events-dialog-footer {
    justify-content: space-between;

    .events-dialog-actions {
        flex-shrink: 0; } }
