#tutorial-dialog {
  img {
    max-width: 100%; }
  .close-tutorial {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2; }
  .emphasis {
    font-weight: 500; }
  .tutorial-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px;
    .finish-tutorial,
    .next-slide {
      width: 90px; }
    .previous-slide {
      color: #888888;
      margin: 0 8px; } }
  .tutorial-slides {
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    overflow-y: auto; }
  .tutorial-slide {
    flex-grow: 0;
    flex-shrink: 0;
    transition: transform 1.5s ease-in-out;
    width: 100%; }
  .tutorial-slide-content {
    margin: 20px; }
  .tutorial-slide-title {
    font-size: 20px;
    margin: 20px; }
  .tutorial-steps {
    display: inline-flex;
    align-content: center;
    color: #cccccc;
    > button {
      padding: 0; } } }
