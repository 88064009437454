@import "../../../../common/components/authoring/styles/authoringVariables";

.content-analyses-callout {
    width: 290px;
    padding: 8px 12px;
    font-size: 14px; }

.font-14 {
    font-size: 14px; }

#post-settings-drawer {
    margin-top: 50px;
    overflow: hidden;
    height: calc(100% - 50px); // 50px is the offset of the nav bar
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    &.MuiDrawer-paper {
        width: $settings-drawer-width; }

    .settings-padding {
        padding: 18px 21px 8px 15px; }

    .progress-section {
        padding: 14px 18px 22px 21px;
        border-radius: 8px;
        background-color: #f3f3f4;

        .static-progress-list {
            margin-top: 21px; }

        .progress-list-item {
            display: flex;
            margin-top: 13px;
            justify-content: flex-start;
            align-item: center;

            .item-description {
                margin-left: 17px; }

            .item-valid-indicator {
                .item-valid-indicator-icon {
                    border: 1px solid #008000;
                    border-radius: 50%;
                    background-color: rgba(54, 101, 49, 0);
                    height: 16px;
                    width: 16px; } }

            &.item-valid {
                .item-valid-indicator-icon {
                    background-color: #008000; } }

            .item-description {
                font-size: 13px; } } }
    .tabs-container {
        .tabs {
            height: 50px;
            border-bottom: solid 1px #dde1e5;

            .tab-label-icon-container {
                flex: 2;

                .tab-label-icon {
                    max-height: 50px;
                    flex-direction: row;

                    .MuiSvgIcon-root {
                        margin-top: 3px;
                        margin-right: 1px; } } }

            .tab-label {
                opacity: 1;
                max-height: 50px;
                font-size: 14px;
                text-transform: none;
                color: #333333;
                min-width: 135px; // this makes the tab fit into container

                &.selected {
                    color: #3b78ab;
                    font-weight: 700; } } } }
    .tab-panel {
        overflow: scroll;
        min-width: $settings-drawer-width; }

    /* MUI theme small screen break point*/
    @media (max-width: 960px) {
        &.MuiDrawer-paper {
            width: 100%; } } }
