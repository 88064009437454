.portal-pages-page {

  .add-text-button {
    display: flex;
    letter-spacing: inherit;
    margin-left: -8px;
    text-transform: none;

    > span {
      font-family: "Roboto", "Helvetica", "Arial", sans-serif; } }

  .command-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    margin-top: -16px;
    .unpublished-changes-icon {
      margin-top: 5px;
      svg {
        color: #e9b848; } } }

  .empty-image-target {
    background-color: #e6e6e6;
    border: dashed 2px #c5c5c5;
    box-sizing: border-box;
    font-size: 14px;
    margin: 0 auto;
    padding: 10px;
    text-align: center;
    transition: all 0.1s;

    svg {
      fill: #c5c5c5;
      transition: all 0.1s; }

    &:hover {
      cursor: pointer;
      border: dashed 2px #8d8d8d;
      svg {
        fill: #8d8d8d; } }

    .instruction {
      color: #aaaaaa;
      display: block; } }

  .image {
    position: relative;

    button {
      position: absolute;
      top: 0;
      right: 0;
      color: #dde1e5; }

    img {
      margin-bottom: 10px;
      width: 100%; } }

  .mui-checkbox {
    margin-top: -9px;
    span {
      font-size: 14px; } }

  .mui-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between; }

  .page-details {
    font-size: 14px;
    .page-details-info {
      display: flex;
      align-items: flex-start;
      color: #888888;
      margin: 24px 0 16px 0;
      white-space: break-spaces;
      > div {
        display: inline-block;
        margin-left: 8px; } }
    .page-detail {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 16px;
      > div:last-child {
        font-weight: 600; } } }

  .percentage-slider {
    margin-top: -10px; }

  .section-label {
    color: #888888;
    font-size: 16px;
    margin-bottom: 24px; }

  .text-button {
    letter-spacing: inherit;
    margin-left: -8px;

    > span {
      font-family: "Roboto", "Helvetica", "Arial", sans-serif; } }

  .tinymce {
    position: relative;
    margin-bottom: var(--tina-padding-big); }

  .tox.tox-tinymce-aux {
    z-index: 2500; } }

.portal-page {
  p {
    margin: 0; }

  .navigation {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 30px;
    .navigation-icon {
      border: 1px solid #ffffff;
      color: inherit;
      min-width: 32px; }
    .navigation-links {
      > div {
        display: inline-flex;
        align-items: center;
        margin: 0 15px;
        &:first-child {
          margin-left: 0; }
        &:last-child {
          margin-right: 0; } }
      a {
        color: inherit;
        text-decoration: none; } } }

  .header {
    .banner-block {
      .banner-image {
        background-image: url('/images/header-bg.jpg'); }
      .banner-text {
        padding: 20px 30px; } } }

  .banner-block {
    position: relative;
    background-color: #adb7c1;
    width: 100%;
    .banner-image {
      position: absolute;
      background-position: center;
      background-repeat: no-repeat;
      height: 100%;
      width: 100%; }
    .banner-text {
      position: relative;
      box-sizing: border-box;
      padding: 20px;
      width: 100%;
      z-index: 2;
      h1, h2, h3, h4, h5, h6 {
        color: #000000;
        margin: 0; } }
    .image-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1; } }

  .section-block {
    display: flex;
    flex-wrap: wrap;
    margin: 30px;

    iframe, img {
      max-width: 100%; }

    > div {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      box-sizing: border-box;
      margin-bottom: 30px;
      padding: 20px;
      width: 100%;
      &:last-child {
        margin-bottom: 0; } }

    @media screen and (min-width: 1024px) {
      > div {
        margin-bottom: 0;
        margin-right: 30px;
        &:last-child {
          margin-right: 0; } }

      &.two-columns {
        > div {
          width: calc(50% - 15px);
          &.one-third-column {
            width: calc(33.3333% - 15px); }
          &.two-thirds-column {
            width: calc(66.6666% - 15px); } } }
      &.three-columns {
        > div {
          width: calc(33.3333% - 20px); } } } } }

.portal-page-callout {
  width: 275px;

  .items-list-text {
    > span {
      overflow: hidden;
      text-overflow: ellipsis; } } }
