.top-description {
    padding: 15px; }

.popout-text {
    width: 100px;
    padding-right: 10px; }

.full-list {
    width: 770px;
    padding-left: 30px; }


.top-options {
    padding-top: 20px;
    padding-left: 9px;
    display: inline-flex; }

.top-text {
    font-size: 22px;
    font-weight: bold;
    display: block;
    margin-top: 8px;
    margin-right: 10px;
    margin-left: 10px; }

.authoring-prompt {
    padding: 10px; }

.overrides-prompt {
    padding: 10px; }

.tabs-adjustment {
    padding-left: 11.5px; }

.separator-line-popout {
    width: 800px;
    position: absolute;
    right: 0; }

.drawer-line-break {
    padding-bottom: 15px; }

.extra-description-padding {
    padding-top: 10px; }

.override-titles {
    padding-top: 20px;
    padding-bottom: 15px; }

.authoring-settings-zone {
    padding: 20px;
    padding-bottom: 40px;
    margin-top: 15px;
    margin-right: 28px;
    border-radius: 5px;
    background-color: #f2f2f2; }

.authoring-elements {
    padding-left: 20px;
    padding-top: 10px; }

.authoring-checkboxes {
    display: flex;
    flex-direction: column; }

.authoring-checkboxes-input {
    position: relative;
    margin-top: 20px;
    right: 3px; }

.radio-button-author-spacing {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px; }

.reminder-text {
    font-size: 14px;
    cursor: pointer;
    color: #2196f3; }

.reminder-background {
    position: relative;
    right: 20px;
    padding: 20px;
    padding-bottom: 40px;
    margin-top: 20px;
    border-radius: 5px;
    background-color: white; }

.reminder-trash {
    float: right;
    cursor: pointer;
    color: #333; }

.smaller-text-reminders {
    font-size: 12px; }

.range-channel-selection {
    display: flex;
    flex-direction: column;

    label {
        margin-top: 10px;
        margin-bottom: 10px;

        span {
            font-size: 14px;
            padding-left: 10px; } } }

.checkbox-display-vertical {
    margin-left: 10px;
    display: flex;
    flex-direction: column; }

.reminder-spacing {
    margin-left: 20px;
    margin-right: -15px;
    padding-bottom: 15px; }

.range-dropdown {
    padding-left: 25px;
    padding-right: 25px; }

.warning-label-text {
    position: relative;
    bottom: 3px;
    font-size: 14px;
    color: #e6911a; }
