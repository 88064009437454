.content-band-card-see-more {
    position: relative;
    margin-right: 10px;

    &.sm {
        max-height: 183px; }

    &.lg {
        max-height: 288px; }

    .see-more {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); } }
