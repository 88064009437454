.categorytag-page {
    background-color: white; }

.categorytag-container {
    margin: 20px; }

.add-tag-modal-header {
    display: flex;
    justify-content: space-between; }

.close-modal-icon {
    color: #333333;
    cursor: pointer;
    vertical-align: middle; }

.audiences-list-drawer {
  width: 410px;

  .audiences-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    padding: 6px 15px; }

  .audiences-list-commands {

    button {
      margin-left: 15px; } }

  .audiences-list-checkbox {
    margin-top: -6px;
    margin-bottom: -6px;
    min-width: 32px; }

  .audiences-list-tag-group-text {
    font-weight: 500; } }

.add-tag-modal-container {
    position: absolute;
    width: 600px;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none; }

.category-tag-table-head {
    font-weight: bold; }

.categorytag-badge-container {
    display: flex;
    width: 300px;
    height: 50px;
    line-height: 50px;
    background: #f4f4f5;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 3px;
    margin: 0 auto;

    .material-icons-outlined {
        color: #a5a5a5;
        padding-right: 20px;
        font-size: 35px;
        display: flex;
        align-items: center;
        justify-content: center; } }

.categorytag-badge-name {
    color: #333333;
    font-size: 16px; }

.categorytag-badge-icon {
    width: 16px;
    height: 16px;
    color: #a5a5a5;
    display: inline-flex;
    align-items: center; }

.userlist-item-container {
    display: flex;
    margin: 5px;
    padding: 10px;
    cursor: pointer;
    &:hover {
        background: #f1f1f1; }

    &.selected {
        background: #9ED3FF;

        &:hover {
            background: #6EBEFF; } } }



.userlist-item-details {
    margin-left: 20px; }
.userlist-item-name {
    font-size: 14px; }
.userlist-item-email {
    font-size: 12px; }

.categorytag-input-label {
    margin-right: 10px;
    width: 15%;
    display: inline-block;
    font-size: 14px;
    float: left;
    overflow-wrap: break-word; }

.text-input-container {
    background: white;
    margin: 5px;
    padding: 20px; }

.categorytag-description-block {
    display: flex;
    flex-wrap: wrap; }

.adduseraudience-container {
    display: grid;
    grid-template-columns: [first] 25% [second] 75%;
    margin: 15px 0px; }

.adduseraudience-label {
    font-size: 14px; }

.add-modal-buttons {
    display: flex;
    justify-content: flex-end; }

.category-tag-language-select {
    width: 100%;
    overflow: visible; }

.category-tag-dialog-content {
  font-size: 14px;
  width: 600px;

  .MuiInputLabel-outlined {
    transform: translate(14px, 13px) scale(1); }

  .MuiOutlinedInput-input {
    padding: 10px 15px; }

  .audiences-list {
    > div {
      background-color: #dde1e5;
      margin-bottom: 10px;
      margin-right: 5px;
      &:last-child {
        margin-right: 0; } } }

  .audiences-list-label {
    align-self: flex-start;
    padding: 8px 0;
    min-width: 215px;

    .help-icon {
      position: relative;
      top: 8px; } }

  .emphasis {
    font-weight: 500; }

  .category-tag-dialog-content-form {
    > div {
      display: flex;
      align-items: center;
      margin: 15px 0;

      &:first-child {
        margin-top: 10px; }

      &:last-child {
        margin-bottom: 0; }

      > div:first-child {
        flex-grow: 0;
        flex-shrink: 0;
        width: 130px; } } }

  > div {
    margin: 5px 0; } }

.category-tag-data-container {
  display: inline-flex;
  flex-direction: row;
  flex: content;
  padding-top: 10px;
  padding-bottom: 15px;

  .category-tag-data-display {} }


.category-tag-text-display {
  display: inline-flex;
  flex-direction: row;
  flex: content;

  .category-tag-text-title {
    min-width: 200px;
    max-width: 250px; }

  .category-tag-text-description {
    min-width: 150px;
    overflow-wrap: break-word; } }

.category-tag-icon-positioning {
  position: relative;
  top: 2px; }

.category-tag-dialog-options {
  float: right; }

.category-tag-enable {
  position: relative;
  left: -12px; }

.category-tag-headers {

  .help-icon {
      position: relative;
      top: 7px; } }

.category-tag-prompt-left {
  min-width: 215px; }

.filter-checkbox {
  margin-top: -6px;
  margin-bottom: -6px;
  min-width: 32px; }
