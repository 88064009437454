.content-guideline-page {
    display: flex;
    justify-content: center;
    height: 90vh;

    .background {
        width: 800px;
        overflow-y: scroll; } }

.content-guidelines {
    .content-guideline-settings-title {
        display: flex;
        justify-content: space-between; }

    .content-guideline-subtext {
        margin-bottom: 30px;
        font-size: 14px;
        color: #7C7C7C; }

    .content-guideline-button {
        border: 0;
        margin-left: 0;
        margin-right: 0;
        box-shadow: none; } }

.content-guidelines-preview {
    .MuiDialog-paper {
        min-width: 800px;
        min-height: 80vh;
        justify-content: space-between; }

    .authoring-actions {
        justify-content: start;
        margin-left: 10px; } }

.content-guidelines-preview-body {
    .wrapped-text {
        overflow-wrap: anywhere; }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center; } }
