.share-button {
  &.link {
    display: inline-block; }

  .link-text {
    text-decoration: underline;
    cursor: pointer; } }

.share-button-dialog {

  .share-button-dialog-header {
    max-width: 350px;
    padding-bottom: 0;
    .share-button-dialog-header-title {
      display: flex;
      align-items: flex-start;
      justify-content: space-between; }
    button {
      margin-top: -12px; } }

  .share-button-content {
    padding-bottom: 24px;
    padding-top: 0;
    max-width: 300px; }

  .warning-message {
    display: flex;
    align-center: flex-start;
    color: #666666;
    font-size: 12px;
    margin-top: 8px;
    .warning-icon {
      color: #e6911a;
      margin-right: 8px; } }

  .copy {
    vertical-align: sub; } }
