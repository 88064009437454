.read-date {
    &.has-been-read {
        color: #888888; }
    &.unread {
        background-color: #bbbbbb;
        color: #ffffff;
        font-weight: 600;
        padding: 1px 5px; }

    &.unread-event {
        position: absolute!important;
        right: 0px;
        top: 14px; } }
