.post-view, .post-preview {

  .reactions {
    position: relative;
    text-align: center;

    .available-reactions {
      padding: 10px 8px;
      .reaction {
        &:not(.preview) {
            &:hover {
                cursor: pointer;

                img {
                    transform: scale(1.15); } } } } }

    .reaction {
      position: relative;
      display: inline-block;
      width: 26px;
      font-size: 12px;
      text-align: center;
      padding: 3px;
      margin: 0 4px;
      user-select: none;
      img {
        max-width: 100%;
        transition: all 0.2s; }
      .name {
        display: none;
        position: absolute;
        top: -27px;
        background-color: rgba(0,0,0,0.8);
        color: #f3f2f1;
        padding: 3px 8px;
        border-radius: 10pt;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap; }
      .count {
        display: block;
        margin: 5px 2px 0 2px;
        width: 25px; }
      &.selected {
        font-weight: 700; }
      &:not(.preview) {
        &:hover {
            .name {
                display: block; } } } }

    .puff-out {
      pointer-events: none;
      img {
        pointer-events: none;
        animation: puffOut 0.8s ease-out 0.1s; } } }

  @keyframes puffOut {
    0% {
      visibility: hidden;
      transform: scale(1); }
    50% {
      visibility: visible;
      transform: scale(2); }
    100% {
      visibility: hidden;
      transform: scale(1); } } }
