@import "../../../../styles/variables.sass";

.post-page {
  .post-view {
    .comments {
      > div {
        margin: 0 auto;
        width: 536px; } }

    .page-content {
      margin: 0 12px 24px 12px; } }

  .no-post-data {
    padding: 25px 20px;
    text-align: center; } }

.post-view, .post-preview-page {
  font-size: 14px;

  .view-page {
    margin: 0 auto;
    position: relative;
    max-width: $sparrow-content-modal-width; }

  .banner {
    height: 5px;
    width: 100%; }

  .comments {
    color: #666666;
    padding: 20px; }

  .hero-banner {
    position: relative;
    display: flex;
    margin: 0;
    overflow: hidden;
    text-align: center;

    &:hover {

      .hero-banner-arrow {
        display: flex; }

      .hero-banner-counter {
        display: block; } }

    .hero-banner-arrow {
      display: none;
      position: absolute;
      top: calc(50% - 37px);
      font-size: 50px;

      &.left-arrow {
        left: 0; }

      &.right-arrow {
        right: 0; } }

    .hero-banner-counter {
      display: none;
      position: absolute;
      bottom: 12px;
      left: 0;
      right: 0;
      text-align: center;

      > span {
        background-color: rgba(0,0,0,0.5);
        border-radius: 27px;
        color: #efefef;
        font-size: 12px;
        text-shadow: 0 0 4px rgba(0,0,0,0.5);
        padding: 5px 15px; } }

    .hero-banner-images {
      display: flex;
      height: $sparrow-hero-img-height;

      .hero-banner-image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        transition: transform 1.5s ease-in-out;
        width: $sparrow-content-modal-width;

        > img {
          height: $sparrow-hero-img-height;
          width: $sparrow-content-modal-width; } } } }

  .hero-banner-steps {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #cccccc;
    margin: 10px 0;

    > button {
      padding: 0; } }

  .page-content {
    margin: 0 20px 24px 20px;

    .author {
      flex-direction: column;
      margin: 15px 0;

      .author-avatar {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: #2196f3;
        border-radius: 50%;
        color: #ffffff;
        font-size: 21px;
        height: 46px;
        width: 46px;
        margin-right: 8px;
        user-select: none; }

      .author-details {
        display: flex;
        align-items: center;
        color: #888888;
        font-size: 12px;
        margin: 8px 0; }

      .author-name {
        font-size: 13px;
        font-weight: 500; } }

    .available-languages {
      color: #666666;
      font-size: 12px;
      text-align: right;

      .inactive-language {
        color: #2196f3;
        cursor: pointer; } }

    .body {
      margin: 15px 0;
      overflow-y: auto;

      img, iframe {
        max-width: 100%;
        height: auto;
        padding: 0;
        margin: 10px; } }

    .documents {
      margin: 15px 0;

      .documents-label {
        font-weight: 500;
        margin-bottom: 5px; } }

    .emphasis {
      font-weight: 500; }

    .published-time {
      color: #888888;
      font-size: 12px;
      font-weight: 500; }

    .tags {
      color: #888888;
      font-size: 12px; }

    .title {
      position: relative;
      font-size: 21px;
      font-weight: 500;
      margin: 15px 0 8px 0;
      padding-right: 50px;
      word-break: break-word;

      .share-button {
        position: absolute;
        top: -8px;
        right: 0; } } } }

@media screen and (min-width: 1500px) {
  .post-page {
    .post-view {
      > div {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        box-sizing: border-box;

        &:first-child {
          border-right: 1px solid #dddddd; } }

      .comments {
        padding: 0 20px;
        > div {
          width: 100%; } } } } }
