
.hero-banner-container {
    display: flex;
    flex-wrap: wrap;

    .hero-banner-placeholder {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        height: 234px;
        width: 416px;
        background-color: #f2f2f2;
        color: #aaaaaa; }

    .hero-banner-previews {
        overflow: hidden;
        max-height: 250px;

        &:hover {
            cursor: pointer; }

        .instruction {
            margin-top: 15px; } }

    .hero-banner-header {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .hero-banner-label {
            &::after {
                margin-left: 3px;
                content: "*";
                color: #b72026; } } }

    .hero-banner-actions {
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: flex-end;
        padding: 0 15px 0 0;
        bottom: 240px;
        height: 225px;
        box-sizing: border-box;

        .fab-action {
            color: #3b78ab;
            background-color: #f2f2f2;
            margin-top: 7px;

            &.edit {
                margin-top: auto; } } } }

