$maxLimit: 20;

.carousel {
    position: relative;

    .arrow {
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        align-items: center;
        color: #efefef;
        text-shadow: 0 0 4px #000000;
        opacity: 0.6;
        font-size: 45px;
        font-weight: 700;
        width: 50px;
        cursor: pointer;
        user-select: none;

        &:first-child {
            left: 0; }

        &:last-child {
            right: 0; }

        &:hover {
            opacity: 0.9; }

        &.right {
            right: 0; }

        &.left {
            left: 0; } }

    &:hover {
        .arrow {
            display: flex; }

        .mediaCounter {
            display: block; } }

    .mediaCounter {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 10px;
        text-align: center;
        user-select: none;

        > div {
            display: inline-block;
            background-color: rgba(0,0,0,0.5);
            border-radius: 27px;
            color: #efefef;
            font-size: 12px;
            text-shadow: 0 0 4px rgba(0,0,0,0.5);
            padding: 5px 15px; } }

    .items {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        overflow: hidden;

        .item {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            width: 100%;
            flex: 1 0 100%;
            transition: transform 1.5s ease-in-out;

            @for $i from 0 through $maxLimit {
                &.itemTransition#{$i} {
                    transform: translateX($i*-100%); } }

            canvas {
                width: 100%; }

            &.image {
                padding-top: 56.25%; } } } }
.bubbles {
    color: #c8c8c8;
    background-color: #ffffff;
    font-size: 12px;
    line-height: 1em;
    text-align: center;
    padding-top: 8px;
    user-select: none;

    > div {
        display: inline-block;
        padding: 0 1px;
        transition: color 0.2s;
        vertical-align: middle;
        cursor: pointer;

        .selected {
            color: #2196f3; }

        &:hover {
            color: #2196f3; }

        .feature {
            font-size: 1.5rem; } } }
