.email-content-view {
    padding-left: 15px;
    padding-right: 15px;

    .info-span {
        font-size: 12px;
        color: #888888; }

    .email-page-content {
        .body {
            font-size: 14px;
            color: #000000;
            line-height: normal;

            p {
                margin: 1em 0; }

            h1, h2, h3, h4, h5, h6 {
                color: #000000;
                padding: 0px; }

            h1 {
                font-size: 2em;
                margin: 0.67em 0; }
            h2 {
                font-size: 1.5em;
                margin: 0.83em 0; }
            h3 {
                font-size: 1.17em;
                margin: 1em 0; }
            h4 {
                font-size: 14px;
                margin: 1.33em 0; }
            h5 {
                font-size: 0.83em;
                margin: 1.67em 0; }
            h6 {
                font-size: 0.67em;
                margin: 2.33em 0; } }

        img, table {
            max-width: 800px; }

        img {
            vertical-align: baseline; }

        table {
            table-layout: fixed;
            img {
                width: 100%; } }

        .wrap-pretext {
            white-space: pre-wrap;
            word-wrap: break-word; }

        .subject {
            position: relative;
            font-size: 21px;
            font-weight: 500;
            margin: 15px 0 20px 0;
            word-break: break-word; }

        .email-header-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;

            .sender {
                display: flex;
                flex-direction: row;
                align-items: center;

                .sender-image {
                    height: 52px;
                    width: 52px; }

                .sender-info-container {
                    height: 52px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    margin-left: 10px;

                    .sender-name {
                        font-size: 14px; } } }

            .email-action-buttons {
                display: flex;
                flex-direction: column;
                height: 52px;
                justify-content: space-evenly;

                .mock-buttons {
                    display: flex;
                    justify-content: flex-end;

                    .reply-all-icon {
                        margin-left: 15px;
                        margin-right: 15px; }

                    .forward-icon {
                        -webkit-transform: scaleX(-1);
                        transform: scaleX(-1); } } } } } }
