.portal-pages-listing {
  .portal-pages-list {
    .portal-page-state {
      position: relative;
      margin-right: -12px;
      &::before {
        content: "";
        position: absolute;
        left: -16px;
        border-radius: 50%;
        height: 8px;
        top: 5px;
        width: 8px; }
      &.green::before {
        background-color: #3b6531; }
      &.grey::before {
        background-color: #adb7c1; }
      &.yellow::before {
        background-color: #e9b848; } }
    .portal-page-time {
      display: inline-flex;
      align-items: center;
      svg.modified-time {
        color: #3b6531;
        padding-left: 4px; }
      svg.published-time {
        color: #e9b848;
        padding-left: 4px; } }
    .portal-page-title {
      display: inline-flex;
      align-items: center;
      svg {
        color: #888888;
        padding-left: 4px; } } }

  .portal-page-results {
    margin-bottom: 15px; } }
