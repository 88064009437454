#smart-content-snackbar {
    right: 77px;
    bottom: 35; }

#post-smart-content-panel {
    .smart-content-content {
        overflow-y: auto;

        .padding-horizontal {
            padding-left: 13px;
            padding-right: 20px;

            /* the draft input */
            .MuiOutlinedInput-root {
                display: flex;
                align-items: flex-start;
                height: 100%; } } } }
