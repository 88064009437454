.plain-preview-container {
    width: 850px;
    flex-basis: 850px;

    .plain-preview-content {
        width: 855px;
        height: 785px;
        padding: 7px 0px 4px;
        border-radius: 5px;
        border: solid 1px #dde1ef;
        display: flex;
        justify-content: center;

        .plain-preview-modal {
            flex-basis: 847px;
            width: 847px;
            background-color: #ffffff;
            overflow-y: auto;
            overflow-x: hidden; } } }
