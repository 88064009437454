// TODO: relocate file if component extended to more than posts
.content-overview {
    position: relative;
    border-radius: 8px;
    border: 1px solid #dde1e5;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 5px;
    margin: 10px 0px 10px 10px;
    box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.15);
    .top-card {
        background-color: transparent;
        box-shadow: none;
        margin-bottom: 8px;
        margin-left: 15px;
        margin-top: -10px; }
    .side-bar {
        padding: 0px 0px 10px 10px; }
    .card-content-wrapper {
        display: flex;
        justify-content: space-between; }
    .card-header {
        display: flex; }
    .info-wrapper {
        margin-top: 20px;
        margin-bottom: 15px;
        &.flex {
            display: flex; }
        &.card {
            padding-left: 20px; }
        &.card-expanded {
            margin-top: 10px; }
        .post-title {
            font-weight: 600;
            font-size: 18px;
            color: #333333; } }
    .heading {
        font-size: 16px;
        color: #000000;
        margin-bottom: 3px;
        &.post-details {
            width: 145px; }
        &.restricted {
            border-bottom: 1px dashed #000000;
            padding-bottom: 2px; } }
    .post-info {
        display: flex;
        padding-right: 15px;
        justify-content: space-between;
        text-transform: capitalize;
        .item {
            color: #333333;
            font-size: 16px;
            margin-bottom: 3px;
            font-weight: 600;
            align-self: center;
            flex-grow: 1; } }
    .post-topics {
        margin-left: 20px;
        margin-top: -4px;
        color: #000000;
        font-size: 14px;
        flex-grow: 1;
        flex-wrap: wrap;
        display: flex;
        .topic {
            width: fit-content;
            font-weight: normal;
            padding: 5px 15px;
            background-color: #dde1e5;
            height: fit-content;
            border-radius: 20px;
            color: rgb(51, 51, 51);
            font-size: 14px;
            margin-bottom: 5px;
            margin-right: 5px; }
        .topic:last-child {
            margin-right: 0px; } } }
