.content-band-editor-container {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    box-sizing: border-box;
    border-radius: 4px;

    &.floating {
        position: absolute; }

    .actions {
        display: flex; }

    .form-group {
        display: flex;
        align-items: center;
        margin: 4px 0px;
        justify-content: space-between;
        max-width: 445px;
        flex-wrap: wrap;
        font-size: 14px;

        &.offset {
            max-width: 639px;

            .chip-list {
                margin-left: 190px;
                display: flex;
                flex-wrap: wrap;
                max-width: 440px; } }

        &.with-disclaimer {
            justify-content: flex-start;
            width: auto;
            max-width: none; }

        .input {
            height: 44px;
            width: 255px;
            font-size: 14px;
            box-sizing: border-box;

            &.grow {
                height: auto; } }

        .checkbox {
            padding: 0 9px 0 4px; }

        .font-14 {
            font-size: 14; }

        .height-auto {
            height: auto; } }

    .disclaimer-text {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #7f7f7f; } }

.font-14 {
    font-size: 14px; }

.cb-content {
    display: flex;
    justify-content: flex-start;
    overflow-x: scroll;
    min-height: 200px;
    align-items: center;

    &.card {
        column-gap: 16px;
        padding-bottom: 20px;
        align-items: stretch; }

    &.window {
        column-gap: 35px; }

    &.carousel {
        position: relative;
        justify-content: center;
        flex-direction: column; } }

#pinned-drawer {
    .container {
        padding: 13px 23px 13px 18px;
        width: 515px;

        .pinned-header {
            display: flex;
            align-items: center;
            height: 48px;
            border-bottom: solid 1px #dde1e5;
            font-size: 18px; }

        .pinned-list {
            margin-top: 24px;

            .remove-action {
                font-size: 14px;
                text-decoration: underline;
                color: #b72026;
                text-transform: none; } } } }

.info-text-color {
    color: #aaaaaa; }
