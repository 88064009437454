@import "./form-variables";

.react-select-wrap {
    font-size: inherit;

    /* example of overriding styles */
    .Select--multi .Select-value {
        color: $focused-label-color; }

    // fix for bug https://github.com/JedWatson/react-select/issues/1287
    span.Select-value-label {
        white-space: nowrap; } }
