@import "../../../styles/variables.sass";

.newsletter-page {
  .space-top-10 {
    margin-top: 10px; }

  .pull-right {
    float: right; }

  .tx-strong {
    font-weight: 500; }

  .image-container {
    border: 2px solid #ccc;
    font-size: 12px;
    padding: auto;
    width: 250px;
    height: 250px;
    text-align: center; }

  .image-loading {
    text-align: center; }

  .newsletter-checkbox-radio {
    display: flex;
    align-items: center; }

  .template-thumbnail {
    position: relative;
    border-radius: 4px;
    border: 4px solid #dde1e5;
    cursor: pointer;
    height: fit-content;
    width: fit-content;
    &:hover .template-thumbnail-actions {
      display: flex;
      flex-direction: column; }
    .template-thumbnail-image {
      position: relative;
      height: fit-content;
      height: 250px;
      overflow-y: hidden;
      width: 250px;
      > img {
        height: auto;
        width: auto;
        max-height: 250px;
        max-width: 250px; } }
    .template-thumbnail-actions {
      display: none;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.1);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      align-items: center;
      justify-content: center;
      button {
        margin: 5px; } } }

  .template-thumbnail-download-actions {
    margin-top: 10px;
    text-align: right;
    width: 258px; }

  .main-editor {
    display: inline-block;
    padding: 15px 20px;
    vertical-align: top;
    width: 100%;
    box-sizing: border-box; }

  .section-card {
    margin-bottom: 20px; }

  .settings-editor {
    display: inline-block;
    padding: 15px;
    vertical-align: top;
    width: 100%;
    box-sizing: border-box;

    > div {
      margin: 20px 0;

      &:first-child {
        margin-top: 0; } } }

  .section-card-subcontent {
    margin-left: 20px; }

  .tox-fullscreen {
    .tox-editor-container {
      background: #ffffff;

      .tox-sidebar-wrap {
        margin: auto;
        width: $sparrow-content-modal-width; } } } }

.newsletter-preview {
  span {
    font-weight: 400;
    text-align: left;
    color: #7F7F7F; }
  img {
    max-width: 100%; }
  .element-padding {
    padding: 6px 0px; }


  @media screen and (min-width: 1280px) {

    .main-editor {
      width: 60%; }

    .settings-editor {
      width: 40%; } }

  @media screen and (min-width: 1920px) {

    .main-editor {
      width: 70%; }

    .settings-editor {
      width: 30%; } }

  .section-card {
    background-color: #f3f3f4;
    padding: 10px 10px;

    .section-card-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 20px;
      margin: 8px 0; }

    .section-card-subheading {
      font-size: 18px;
      margin: 8px 0; } } }
