@import "../../../../styles/variables";

.authoring-page {
  .editors-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }

  .main-editor {
    display: inline-block;
    padding: 15px 20px;
    vertical-align: top;
    flex: 2 1 70%;
    box-sizing: border-box; }

  .section-card {
    margin-bottom: 20px; }

  .settings-editor {
    display: inline-block;
    padding: 15px;
    vertical-align: top;
    flex: 1 1 30%;
    box-sizing: border-box;

    > div {
      margin: 20px 0;

      &:first-child {
        margin-top: 0; } } }

  .section-card-subcontent {
    margin-left: 20px; }

  .filter-results {
    margin-bottom: 15px; }

  .item-state {
    position: relative;
    margin-right: -12px;
    &::before {
      content: "";
      position: absolute;
      left: -16px;
      border-radius: 50%;
      height: 8px;
      top: 5px;
      width: 8px; }
    &.green::before {
      background-color: #3b6531; }
    &.grey::before {
      background-color: #adb7c1; }
    &.yellow::before {
      background-color: #e9b848; }
    &.red::before {
      background-color: #ff0000; } }

  @media screen and (max-width: 1285px) {
    .editors-container {
      flex-wrap: wrap; } }

  .section-card {
    background-color: #f3f3f4;
    padding: 10px 15px;

    .section-card-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 20px;
      margin: 8px 0; }

    .section-card-subheading {
      font-size: 18px;
      margin: 8px 0; } }

  .add-new-item {
    margin-left: 18px; }

  .add-languages-list {
    position: absolute;
    left: 0;
    background-color: #ffffff;
    box-shadow: 0 0 4px #aaaaaa;
    margin-top: 10px;
    z-index: 3;

    .add-languages-list-item {
      white-space: nowrap; } }

  .language-tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      display: inline-flex;
      align-items: center; }

    .add-language {
      position: relative;
      margin: 0 10px; }

    .language-tab {
      display: inline-flex;
      align-items: center;
      border-color: #f3f3f4;
      border-radius: 5px 5px 0 0;
      border-style: solid;
      border-width: 1px 1px 0 1px;
      height: 20px;
      margin-right: 8px;
      padding: 15px;
      text-align: center;
      cursor: pointer;
      user-select: none;

      .language-tab-text {
        font-size: 14px;

        &::after {
          display: block;
          content: attr(data-text);
          color: transparent;
          font-weight: 500;
          height: 0;
          overflow: hidden;
          visibility: hidden; } }

      .delete-language-tab {
        margin-left: 8px;
        margin-right: -4px; }

      &.active-tab {
        background-color: #f3f3f4;
        border-color: #f3f3f4; } } }

  .additional-information {
    color: #666666;
    font-size: 14px;
    margin: 4px 0;

    .emphasis {
      font-weight: 500; } }

  .header-view {
    display: inline-flex;
    align-items: center; }

  .more-info-text {
    border-bottom: 1px dashed #666666; }

  .author {
    display: flex;
    margin-bottom: 10px;

    .custom-author-label {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      width: 50%;
      position: relative;
      top: -2px; }

    > div:first-child {
      margin-right: 5px; }

    > div:last-child {
      margin-left: 5px; } }

  .capacities {
    margin-top: 20px;

    .capacity {
      display: flex;
      align-items: center;
      margin: 4px 0 4px 10px; }

    .capacity-label {
      width: 135px; }

    .capacity-input {
      display: inline-flex;
      flex-grow: 1; } }

  .cutoff-time {
    margin-top: 20px;

    .cutoff-time-field {
      display: flex;
      align-items: center;
      margin: 4px 0 4px 10px; }

    .MuiInputBase-root.Mui-disabled {
      color: inherit; }

    .cutoff-time-label {
      flex-shrink: 0;
      width: 135px; }

    .cutoff-time-input {
      flex-grow: 1; } }

  .date-and-location {

    .date-and-location-field {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      .MuiInputBase-root.Mui-disabled {
        color: inherit; } }

    .date-and-location-time-field {
      display: flex;
      align-items: flex-start;
      margin-bottom: 8px; }

    .date-and-location-label {
      display: inline-flex;
      align-items: center;
      height: 38px;
      width: 75px; }

    .date-and-location-input {
      flex-grow: 1; } }

  .notification-options {
    font-weight: 400;
    margin-bottom: 8px;
    padding: 0;
    text-transform: none;
    &:hover {
      background-color: transparent; } }

  .date-picker {
    .MuiInputBase-root.Mui-disabled {
      background-color: #ffffff; } }

  .event-times {
    margin-bottom: 15px;

    .add-event-time {
      margin-left: 10px; }

    .event-time-day-label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      margin: 10px 0; }

    .event-time-day-content {
      margin-left: 10px; } }

  .authoring-divider {
    color: #dde1e5;
    margin: 20px 0 17px; }

  .file-attachment, .question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px;
    padding: 10px 10px 10px 15px;

    > div {
      display: inline-flex;
      align-items: center; }

    .question-required {
      color: #aaaaaa;
      font-size: 11px;
      margin: 0 5px; } }

  .hero-banner {
    position: relative;
    margin: 10px auto;
    overflow: hidden;

    &:hover {
      .hero-banner-arrow {
        display: flex; }

      .hero-banner-counter {
        display: block; } }

    .empty-image-target {
      background-color: #e6e6e6;
      border: dashed 2px #c5c5c5;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      height: 450px;
      width: $sparrow-content-modal-width;
      max-width: $sparrow-content-modal-width;
      transition: all 0.1s;

      svg {
        fill: #c5c5c5;
        transition: all 0.1s; }

      &:hover {
        cursor: pointer;
        border: dashed 2px #8d8d8d;
        svg {
          fill: #8d8d8d; } }
      .instruction {
        color: #aaaaaa;
        display: block; } }


    .image-preview-options {
      position: absolute;
      top: 5px;
      right: 5px;
      z-index: 1;

      > button {
        margin-left: 5px; } }

    .hero-banner-arrow {
      display: none;
      position: absolute;
      top: calc(50% - 37px);
      font-size: 50px;

      &.left-arrow {
        left: 0; }

      &.right-arrow {
        right: 0; } }

    .hero-banner-counter {
      display: none;
      position: absolute;
      bottom: 12px;
      left: 0;
      right: 0;
      text-align: center;

      > span {
        background-color: rgba(0,0,0,0.5);
        border-radius: 27px;
        color: #efefef;
        font-size: 12px;
        text-shadow: 0 0 4px rgba(0,0,0,0.5);
        padding: 5px 15px; } }

    .hero-banner-images {
      display: flex;
      height: 450px;

      .hero-banner-image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        transition: transform 1.5s ease-in-out;
        width: $sparrow-content-modal-width; } } }

  .hero-banner-steps {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #cccccc;
    margin: 10px 0;

    > button {
      padding: 0; } }

  .location {

    .location-field {
      display: flex;
      align-items: center;
      margin-bottom: 8px; }

    .location-icon {
      margin-right: 5px; }

    .location-options {
      margin-bottom: 8px; }

    .location-option:not(:last-child) {
      margin-right: 30px; } }

  .multilingual-editor {
    margin-bottom: 20px;

    .multilingual-editor-field {
      margin: 8px 0;
      &:first-child {
        margin-top: 0; }
      &:last-child {
        margin-bottom: 0; }

      > label {
        display: block;
        font-size: 14px;
        margin: 4px 0; } } }

  .optional-content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 17px;
    margin-bottom: 5px; }

  .optional-content-disclaimer {
    color: #aaaaaa;
    font-size: 12px;
    margin-top: 15px;
    text-align: right; }

  .no-attachment-message {
    color: #aaaaaa;
    text-align: left;
    font-style: italic; }

  .remove-optional-content {
    color: #b72026;

    button {
      font-size: 11px; } }

  .mixed-restrictions-warning {
    color: #a55e15;
    font-size: 12px;
    margin-top: 5px; }

  .new-question {
    height: 0;
    overflow: hidden;
    width: 0;

    > div {
      display: flex; }

    &.expanded {
      height: auto;
      width: 100%; }

    .MuiFormControl-root {
      margin: 0 8px; } }

  .new-question-required {
    margin-left: 10px;

    .MuiFormControlLabel-label {
      font-size: 14px; } }

  .scheduled-time {
    margin: 8px 0;

    &:not(:first-child) {
      margin-top: 24px; }

    .scheduled-time-field {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      .MuiInputBase-root.Mui-disabled {
        color: inherit; } }

    .scheduled-time-label {
      width: 100px; }

    .scheduled-time-input {
      flex-grow: 1; }

    .scheduled-time-input-time {
      display: inline-flex;
      align-items: center;
      margin-left: 100px;

      > div:first-child {
        margin-right: 8px; } } }

  .summary {
    position: relative;

    .remove-optional-content {
      position: absolute;
      top: 0;
      right: 0; } }

  .tags-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 14px;
    margin: 0 -5px 0 3px;

    > div {
      display: inline-flex;
      align-items: center;
      border-radius: 20px;
      margin: 0 5px 8px 5px;
      padding: 2px 2px 2px 10px;

      > button {
        margin-left: 5px; } } }

  .time-range {
    display: flex;
    align-items: center;

    > div:nth-child(2) {
      margin: 0 10px; } }

  .type {

    .type-select {
      width: 200px; } }

  .validator {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    margin: -15px;
    padding: 15px;

    &:hover, &.expand-progress-list {
      .progress-list, .progress-list-arrow {
        display: block; } }

    > div {
      flex-grow: 1; } }

  .progress-info {
    position: relative; }

  .progress-bar {
    position: relative;
    background-color: #dddddd;
    border-radius: 27px;
    height: 22px;
    margin-right: 65px;
    overflow: hidden; }

  .progress-bar-fill {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #008000;
    border-radius: inherit;
    display: flex;
    align-items: center;
    transition: width linear 0.25s;
    overflow: hidden; }

  .progress-bar-text {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: inline-flex;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    height: 22px;
    padding: 0 10px;
    white-space: nowrap; }

  .progress-list {
    display: none;
    position: absolute;
    background-color: #ffffff;
    box-shadow: 0 0 4px #aaaaaa;
    font-size: 13px;
    margin-left: -12px;
    margin-top: 15px;
    min-width: 250px;
    padding: 4px 12px;
    z-index: 1;

    .progress-list-item {
      position: relative;
      margin: 15px 0;

      .item-description {
        margin-left: 26px; }

      .item-valid-indicator {
        position: absolute;
        top: 0;
        left: 0;

        .item-valid-indicator-icon {
          border: 1px solid #008000;
          border-radius: 50%;
          height: 16px;
          width: 16px; } }

      &.item-valid {

        .item-description {
          color: #008000;
          font-weight: 500; }

        .item-valid-indicator-icon {
          background-color: #008000; } } }

    .emphasis {
      font-weight: 500; } }

  .progress-list-arrow {
    display: none;
    position: absolute;
    height: 15px;
    width: 30px;
    overflow: hidden;
    z-index: 2;

    &::after {
      content: "";
      position: absolute;
      width: 15px;
      height: 15px;
      background: #ffffff;
      transform: rotate(45deg);
      top: 8px;
      left: 8px;
      box-shadow: 0 0 4px #aaaaaa; } }

  .progress-list-info {
    border-top: 1px solid #dddddd;
    color: #888888;
    margin: 5px 0;
    padding: 10px 0 5px 0; } }

.authoring-drawer-panel {
  width: 600px;
  box-sizing: border-box;
  .authoring-drawer-panel-header {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #dde1e5;
      font-size: 20px;
      font-weight: 500;
      padding: 10px 0; }
  .authoring-drawer-panel-content {
      padding: 5px 15px;
      > div {
          margin: 15px 0; } }

  .drawer-panel-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    margin: 8px 0; }

  .description {
    color: #888888; }

  .notification-setting {
    .notification-options {
      margin-left: 10px; } }

  .publishing-notifications,
  .publishing-reminders {
    background-color: #f3f3f4;
    padding: 10px 15px; }

  .reminders {
    margin: 10px 0; }

  .reminder {
    position: relative;
    margin: 10px 0;
    > div {
      padding: 15px 20px; }
    > button {
      position: absolute;
      top: 10px;
      right: 15px; }
    .reminder-setting {
      background-color: #ffffff; }
    .reminder-label {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 5px; }
    .reminder-time {
      display: flex;
      align-items: center; }
    .reminder-time-input {
      background-color: #ffffff;
      margin-left: 10px;
      max-width: 70px;
      > div {
        border-radius: 0; } }
    .reminder-time-type {
      background-color: #f3f3f4;
      border-radius: 0; } }

  .custom-reminder {
    margin-top: 10px; }

  .custom-reminder-type {
    margin: 0 0 15px 30px;
    width: calc(100% - 30px);
    > div {
      background-color: #ffffff; } } }

.tags-list-drawer {
  width: 450px;

  > hr {
    margin: 15px 0; }

  .tags-list-checkbox {
    margin-top: -6px;
    margin-bottom: -6px;
    min-width: 32px; }

  .tags-list-tag-group-text {
    color: #666666;
    font-weight: 500; }

  .tags-list-tags {
    > div {
      padding-left: 50px; }

    .restricted-tag-icon {
      color: #a52a2a; } } }

.authoring-image-cropper-content {
  text-align: center;
  width: $sparrow-content-modal-width; }

.authoring-gallery-content {
  width: 896px;

  nav {
    margin-top: 10px;

    > ul {
      justify-content: center; } } }

.authoring-gallery-items {

  &.authoring-gallery-documents {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    > div {
      box-sizing: border-box;
      margin-bottom: 10px;
      margin-right: 16px;
      width: calc(25% - 12px);
      &:nth-child(4n+4) {
        margin-right: 0; } } }

  .authoring-gallery-document {
    position: relative;
    border: 2px solid #dde1e5;
    box-sizing: border-box;
    cursor: pointer;
    padding: 10px 30px;
    &:hover, &.item-selected {
      border-color: #3b78ab; }
    img {
      height: 181px;
      width: 100%; }
    .item-selected-number {
      padding: 6px 6px 4px 8px; } }

  .authoring-gallery-image {
    border: 4px solid transparent;
    box-sizing: border-box;
    cursor: pointer;

    &:hover, &.item-selected {
      border-color: #3b78ab; } }

  .document-name {
    font-size: 14px;
    overflow: hidden;
    padding: 10px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap; }

  .item-selected-number {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #3b78ab;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    padding: 6px 4px 2px 8px;
    text-align: center;
    width: 22px; }

  .video-indicator {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 70px; } }

table {
  tr:hover {
    .more-options-icon {
      visibility: visible; } } }
