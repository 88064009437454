#readability-settings-dialog {
    .padding {
        padding: 20px 16px; }

    .readability-settings-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: solid 1px #dde1e5; }

    .readability-settings-footer {
        border-top: solid 1px #dde1e5;

        .danger-link {
            color: #b72026;
            text-transform: none;
            text-decoration: underline;
            font-size: 12px;
            font-weight: normal;

            &:disabled {
                color: rgba(0, 0, 0, 0.26); } } }

    .reset-actions {
        display: flex;
        justify-content: space-between;

        .action {
            color: #3b78ab;
            font-size: 14px;
            text-decoration: underline;
            text-transform: none;
            font-weight: normal; } } }
