div.list-container-title-div {
    display: flex;
    margin-left: 5px;

    div.list-title-div-file-name-div {
        flex: 1 1 300px; }

    div.list-title-div-property {
        flex: 0 1 170px;
        box-sizing: border-box;
        padding: 2px 8px; } }
