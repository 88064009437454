.feed-buttons {
    margin-bottom: 15px;
    margin-top: 15px;
    text-align: center; }

.card-feed-buttons {
    flex-direction: column-reverse;
    display: flex;
    flex-wrap: wrap;
    align-content: center; }
