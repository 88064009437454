@import './common.sass';

#news-feed {
    .card, .column {
        .load-tile {
            align-content: center; } }

    .tile {
        border: 1px solid #dde1e5;

        .stats {
            position: absolute;
            top: 15px;
            right: 15px;
            margin-top: 2px;
            font-size: 18px;
            text-shadow: 0 0 2px #000000;
            svg {
                margin-right: 5px; }
            & > div {
                display: inline-flex;
                &:not(:first-child) {
                    margin-left: 8px; } } } }

    &.horizontal-scrollable {
        .card {
            display: flex;
            flex-wrap: nowrap; }
        .tile {
            max-width: 350px;
            margin-right: 15px;
            min-width: 300px; } } }
