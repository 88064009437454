.post-view, .post-preview-page {

  .compliance {
    position: relative;
    border-top: 1px solid;
    border-bottom: 1px solid;
    padding: 18px 14px 18px 0;
    margin-top: 50px;
    min-height: 1.2em;
    user-select: none;
    transition: all 0.5s ease-in-out;
    &.acknowledged {
      border-color: #eaeaea;
      .compliance-text {
        opacity: 0.74; } }

    &.unacknowledged {
      color: #ffffff;
      background-color: #c81027;
      border-color: #c81027;
      border-radius: 2px; }

    .checkbox, img {
      position: absolute;
      margin-top: -2px; }

    .checkbox {
      top: 11px;
      left: 11px;
      > .MuiIconButton-label {
        background-color: #ffffff;
        color: #ffffff; }
      input {
        height: 15px;
        width: 15px; }
      label, label div {
        height: 22px;
        width: 22px;
        margin: 0; }

      &:not(.is-checked) {
        label div {
          border-color: #ffffff; }
        &:hover i {
          color: #c81027; } }

      &.is-checked label div {
        background-color: #c81027;
        border-color: #c81027; } }

    img {
      height: 24px;
      left: 12px; }

    .compliance-text {
      display: block;
      margin-left: 50px; } } }
