
.stats-row {
    display: flex;
    justify-content: space-evenly;
    &.wrap {
        flex-direction: column;
        flex-grow: 1; } }
.number-wrapper {
    border-radius: 5px;
    border: 1px solid #dde1e5;
    background-color: #fcfcf9;
    box-sizing: border-box;
    padding: 0px 10px 8px 10px;
    margin: 10px 10px 10px 0px;
    color: #333;
    display: flex;
    flex-direction: column;
    font-weight: 700;
    font-size: 18px;
    align-items: center;
    text-align: center;
    flex: 1;
    position: relative;
    .number {
        height: 55px;
        vertical-align: middle;
        display: flex;
        align-items: center;
        &.left {
            font-size: 45px;
            height: 100px;
            font-weight: 500; } }
    &.activity-widget {
        max-width: 185px;
        flex: unset;
        min-width: 115px;
        justify-content: center;
        width: 185px; }
    &.reaction-widget {
        max-width: 100px;
        padding: 8px 20px 0px 20px;
        height: 60%;
        margin-bottom: 0;
        .title {
            font-size: 14px;
            font-weight: normal;
            padding-top: 5px;
            margin-top: 0;
            margin-bottom: -10px; } }
    &.percent {
        max-width: 100px;
        height: 20%;
        margin-top: 4px;
        padding-top: 0px;
        .title {
            padding-top: 0;
            margin-top: 1px;
            margin-bottom: 0; } }

    &.small {
        margin-right: 0px; }
    &.small:nth-last-child(-n+2) {
        margin-top: 0px; }
    .title {
        font-size: 13px;
        margin-top: -8px;
        font-weight: normal; }
    .description {
        font-size: 11px;
        font-weight: normal;
        padding-top: 3px; } }

.metric-title {
    font-size: 16px;
    color: #666666;
    font-weight: 600;
    text-transform: uppercase; }
.more-info {
    color: #888;
    font-style: italic;
    margin-left: 10px; }
