.reading-analysis-graphs {
    display: flex;
    margin-top: 10px;
    flex-wrap: wrap; }
.reading-analysis-wrapper {
    width: 50%; }
.reading-analysis {
    padding: 35px 10px;
    .gradient {
        position: relative;
        height: 20px; }
    .gradient::after {
        content: '';
        background-color: lightgrey;
        height: 28px;
        width: 1px;
        display: block;
        position: absolute;
        right: 0px; }
    .gradient::before {
        content: '';
        background-color: lightgrey;
        height: 28px;
        width: 1px;
        display: block;
        position: absolute;
        left: 0px; }
    .slider {
        position: absolute;
        transform: rotate(90deg);
        top: -25px; }
    .tick {
        position: absolute;
        border-left: 1px solid #f0f0f0;
        height: 28px;
        transform: rotate(90deg);
        top: -2px;
        left: 34px; }
    .labels {
        margin-top: 10px;
        font-size: 12px;
        color: #333;
        font-weight: normal;
        display: flex;
        justify-content: space-between; } }

.graphs-disclaimer {
  color: #A6A6A6;
  font-size: 12px;
  text-align: left; }

.nivo-wrapper {
    padding-top: 15px;
    padding-bottom: 50px;
    height: 200px; }

.post-insights-header {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    .interval-filter {
        margin-top: -25px; }
    .date {
        font-weight: 600;
        padding-left: 3px; } }

.pie-wrapper {
    display: flex;
    justify-content: space-evenly; }

.activity-insights-wrapper {
    padding-top: 20px; }
.activity-insights-row {
    display: flex;
    flex-wrap: wrap; }

.comment-label {
    font-size: 12px;
    font-weight: normal;
    color: #666666; }

.comment-content {
    margin-left: 10px;
    flex: 1;
    margin-top: 10px;
    .comment-heading {
        font-weight: 700;
        color: #333333;
        font-size: 15px; }
    .comment-wrapper {
        display: flex;
        padding-top: 10px;
        justify-content: flex-start;
        .author-avatar {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: #2196f3;
            border-radius: 50%;
            color: #ffffff;
            font-size: 21px;
            height: 46px;
            width: 46px;
            min-width: 46px;
            margin-right: 8px;
            user-select: none;
            margin-top: 16px; }
        .header {
            display: flex;
            justify-content: space-between;
            .comment-contents {
                width: 50%;
                flex-direction: row;
                .name-label {
                    @extend .comment-label;
                    opacity: .74; }
                .date-label {
                    @extend .comment-label;
                    opacity: .54;
                    margin-left: 16px; } }
            .data-label {
                @extend .comment-label;
                opacity: .54;
                margin-left: 43%; } }
        .body {
            font-size: 14px; } } }

@media screen and (max-width: 1145px) and (min-width: 1024px) {
    .comment-content {
        .comment-wrapper {
            .body {
                width: 250px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis; } } } }

@media screen and (max-width: 845px) {
    .comment-content {
        .comment-wrapper {
            .body {
                width: 250px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis; } } } }
