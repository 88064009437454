.emails-one-last-check-dialog {

    .emails-check-dialog-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: solid 1px #dde1e5; }

    .emails-check-dialog-content {
        padding-top: 15px !important;
        max-height: 700px;
        overflow-y: auto;

        .data-container {
            margin-bottom: 15px;

            .data-container-label {
                color: #7f7f7f; }

            .send-to-label {
                margin-top: 6px; }

            .recipient-button-container {
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                .recipient-count {
                    margin-right: 25px; } } }

        .email-content-view {
            margin-top: 15px;
            border-radius: 5px;
            border: solid 1px #dde1ef;

            .body {
                min-height: 380px; } }

        .preview-help {
            margin-top: 5px;
            font-style: italic;
            color: #666666;
            font-size: 13px; } } }
