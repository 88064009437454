.time-bar {
    max-width: 400px;
    max-width: calc(125px * 3);
    margin: 0 auto;

    .active {
        background: white;
        color: white;
        background: #01b5fa;
        border: 1px solid #00adff; }
    button {
        color: graytext;
        border: 0;
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 20px;
        padding: 6px 18px;
        font-size: 12px;
        cursor: pointer;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
        margin: 0px 6px;
        outline: none; } }

.no-data-display {
    height: 175px; }

.loading-styling {
    position: relative;
    top: 55px; }
