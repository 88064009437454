.document-creation {
  .document-uploader {
    .uploader {
      border-width: 3px;
      margin: 20px 20px 10px 20px;
      min-height: 150px;
      .large {
        font-size: inherit;
        font-weight: inherit; }
      svg {
        height: 30px;
        margin-bottom: 8px;
        width: 30px; }
      .centered-content {
        line-height: 1.25em;
        padding: 20px;
        text-align: center; } } }
  .document-properties {
    margin: 20px 30px;
    > div {
      display: flex;
      align-items: flex-start;
      margin: 16px 0;
      > div {
        flex-grow: 0;
        &:first-child {
          display: inline-flex;
          flex-shrink: 0;
          line-height: 1.5em;
          padding: 8px 0;
          width: 200px; } } }
    .document-state {
      display: inline-flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      > div {
        &:first-child {
          min-width: 230px;
          width: calc(60% - 8px); }
        &:last-child {
          width: calc(40% - 8px); } } }
    .file-name,
    .revisions {
      padding: 8px 0; } }
  .no-details {
    font-style: italic;
    font-weight: 500;
    margin: 20px; }
  .supported-file-types {
    color: #3b78ab;
    font-size: 14px;
    font-style: italic;
    margin: 10px 20px;
    text-decoration: underline; }
  .uploaded-files {
    margin: 20px;
    .no-files {
      font-style: italic;
      font-weight: 500; } } }

.document-editor {
  width: 800px;
  .document-editor-title {
    align-items: center;
    border-bottom: 1px solid #dde1e5;
    font-size: 20px;
    font-weight: 500;
    display: flex;
    justify-content: space-between; } }

.document-summary {
  min-width: 500px;
  .document-summary-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dde1e5;
    font-size: 18px;
    font-weight: 500;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    button {
      margin-left: 8px; } }
  .document-summary-properties {
    font-size: 14px;
    padding: 8px 16px;
    > div {
      display: flex;
      align-items: center;
      margin: 8px 0;
      > div:first-child {
        color: #888888;
        width: 150px; } } } }

.supported-file-types-description {
  font-size: 12px;
  padding: 5px; }

@media screen and (max-width: 1023px) {
  .document-creation {
    .document-properties {
      .document-state {
        flex-wrap: wrap;
        > div {
          &:first-child {
            margin-bottom: 8px;
            min-width: 0;
            width: 100%; }
          &:last-child {
            width: 100%; } } } } } }
