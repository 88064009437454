$maxLimit: 20;

@mixin gradient-base {
    position: absolute;
    bottom: 0;
    height: 50%;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    width: 100%; }

.content-band-card,
.content-band-carousel-item {
    .date-block {
        position: relative;
        width: 58px;
        min-width: 58px;
        height: 61px;
        font-size: 13px; }

    &.sm {
        .day,
        .month {
            font-size: 13px; }

        .time {
            font-size: 12px; } } }

.content-band-card,
.content-band-window,
.content-band-carousel-item {
    .read-date {
        &.unread {
            font-size: 9px; } } }

.content-band-card {
    flex: 0 0 197px;
    min-height: 186px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    border: 1px solid #dde1e5;
    background-color: #ffffff;
    box-sizing: border-box;

    &.sm {
        &:not(.full-title) {
            max-height: 186px; }

        .hero-img {
            height: 110px;

            .date-block {
                top: 15px; } } }

    &.lg {
        max-height: 288px;

        .hero-img {
            height: 150px;
            width: 265px;

            .date-block {
                top: 50px;

                .day,
                .month {
                    font-size: 13px; }

                .time {
                    font-size: 12px; } } }

        .content-band-card-content {
            max-height: 140px; } }

    .content-band-card-content {
        padding: 8px 10px 12px;
        box-sizing: border-box; }

    .hero-img {
        width: 100%;

        .date-block {
            margin-left: 9px; } } }

.content-band-window,
.content-band-card,
.content-band-carousel-item {
    &.sm {
        .title {
            font-size: 12px; } }

    &.lg {
        .title {
            font-size: 18px; }

        .summary {
            font-size: 14px; } }

    .hero-badges {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 20px;
        margin-top: 5px;

        .type-banners {
            height: 20px;
            top: 4px;
            position: unset!important;

            .type-banner {
                height: 20px;
                position: unset!important;

                .icon {
                    height: 20px;
                    width: 20px; } } } }

    .content-band-card-content {
        display: flex;
        flex-direction: column;

        .breaking-featured-banner {
            padding: 0;

            .breaking-featured-banner-text {
                margin: 0;
                padding: 2px 5px;
                font-size: 9px;
                font-weight: 700;
                text-transform: uppercase; } }

        .title {
            font-weight: 500;
            margin-bottom: 0px; }

        .info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 15px;

            .date {
                font-size: 10px;
                color: #7f7f7f; }

            .attending-in-person,
            .attending-online,
            .waitlisted,
            .response-required, {
                text-transform: uppercase; }

            .attending-in-person,
            .attending-online,
            .not-attending,
            .waitlisted,
            .response-required,
            .not-responded-yet {
                font-size: 9px; } } }

    .hero-img {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center; } }

.content-band-window {
    min-height: 100px;
    height: 105px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    position: relative;

    .gradient {
        @include gradient-base; }

    &.sm {
        flex: 0 0 172px; }

    &.lg {
        flex: 0 0 418px;
        height: 226px; }

    .date-chip {
        background-color: #f2f2f2;
        font-size: 9px;
        font-weight: 500;
        height: 23px;
        margin-left: 5px;
        margin-top: 5px; }

    .content-band-card-content {
        padding: 0px 8px 8px;
        margin-top: auto;

        .info {
            .not-responded-yet {
                color: #ffffff; } }

        .info,
        .title,
        .date {
            color: #ffffff!important; } } }

.content-band-carousel {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    overflow: hidden;

    &.lg {
        width: 100%;

        + div.dots {
            margin-left: auto; } }

    &.preview {
        width: 330px; }

    &.full-width {
        width: 100%;

        .content-band-carousel-item {
            height: 217px; } }

    &.sm {
        &.full-width {
            .content-band-carousel-item {
                &.event {
                    .content-band-card-content {
                        top: 75px; } }
                &.post {
                    .content-band-card-content {
                        top: 140px; } } } }

        &:not(.full-width) {
            height: 175px;

            .content-band-carousel-item {
                max-width: 310px; } } }

    .content-band-carousel-item {
        flex: 1 0 100%;
        transition: transform 1.5s ease-in-out;
        justify-content: center;
        display: flex;
        flex-direction: column;
        margin-top: auto;
        border-radius: 4px;

        .hero-img {
            border-radius: 4px;
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.35);
            height: 100%; }

        &.event {
            .content-band-card-content {
                top: 35px; } }

        &.post {
            .content-band-card-content {
                top: 100px; } }

        &.sm {
            position: relative;

            .gradient {
                @include gradient-base; } }

        &.lg {
            min-height: 270px;
            flex-direction: row;

            .hero-img {
                border-radius: 0;
                flex-basis: 49%;

                .date-block {
                    top: 155px;
                    left: 15px;
                    width: 100px;
                    height: 100px; } }

            .content-band-card-content {
                display: flex;
                margin-top: initial;
                padding: 0 66px;
                position: relative;
                top: 60px;
                max-height: 210px;

                .title-summary-container {
                    display: flex;
                    flex-direction: column;

                    .title {
                        font-size: 26px; }

                    .summary {
                        font-size: 12px; } }

                .info {
                    margin-top: auto;
                    margin-bottom: 20px; } } }

        .content-band-carousel-lg {
            flex-basis: 51%;
            display: flex;
            position: relative;

            .gradient {
                @include gradient-base; }

            .hero-badges {
                width: 100%;
                position: absolute;
                margin-top: 21px; } }

        .carousel-overlay-btns {
            display: flex;
            width: 100%;
            top: 50%;
            justify-content: space-between;
            position: absolute;
            padding: 0px 15px;
            box-sizing: border-box; }

        .content-band-card-content {
            padding: 0 7px 8px 7px;
            color: #ffffff;
            position: relative;

            .date {
                color: #ffffff; }

            .date-block {
                margin-left: 0;
                margin-bottom: 8px; }

            .info {
                .not-responded-yet {
                    color: #ffffff; } } } } }

.content-band-window,
.content-band-carousel-item {

    .content-band-card-content {
        z-index: 2;

        .info {
            .attending-in-person,
            .attending-online,
            .not-attending,
            .waitlisted,
            .response-required,
            .not-responded-yet {
                color: #ffffff; } } } }
