.file-attachment-section {
    background-color: #ffffff;
    padding: 0;

    .file-attachment-display {
        border: solid lightgray;
        border-width: 1px;
        width: -webkit-fill-available;
        padding: 10px 10px 10px 15px;
        text-transform: none;
        justify-content: flex-start;
        font-size: 16px;
        color: unset;
        font-weight: unset;
        margin: 4px; }


    .file-attachment-name {
        white-space: normal;
        word-break: break-all; }

    .file-attachment-header {
        font-weight: bold;
        font-size: 17px;
        margin-bottom: 15px;
        margin-top: 15px; } }
