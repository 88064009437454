@import "common.sass";

.news-feed-section {
	@media screen and (max-width: 400px) {
		.window {
			.tile {
				.starts-date {
					visibility: hidden; } } } }

	.window {
		width: 100%;
		margin-top: 15px;

		.starts-date {
			display: flex;
			margin: 15px;
			padding: 5px 10px;

			width: fit-content;
			border-radius: 25px;
			background: white;
			color: black;
			font-weight: 500;
			font-size: 16px;
			text-transform: uppercase;

			border: 0px;
			box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }

		.truncated-text-1 {
			display: -webkit-box!important;
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical; }

		.info {
			padding: 0px!important;
			justify-content: end;

			.topics {
				color: white!important; }

			.summary {
				white-space: normal!important; }

			.attendance {
				padding: 0px!important;

				.attending-in-person,
				.attending-online,
				.response-required,
				.waitlisted, {
					color: white!important; } } } } }

