.type-banners {
  position: absolute;
  top: 14px;
  left: 0;
  color: #ffffff;
  font-size: 12px;
  overflow: hidden;
  user-select: none;
  z-index: 1; }

.type-banner {
  position: relative;
  display: flex;
  align-items: center;
  height: 18%;
  min-height: 15px;
  max-height: 40px;
  overflow: hidden;

  &.public {
    color: #333333;
    .icon, .text > div {
      background-color: #ffffff; } }

  &.restricted {
    .icon, .text > div {
      background-color: #b72026; } }

  &:not(:first-child) {
    margin-top: 5px; }

  > div {
    height: 100%; }

  .icon {
    transition: border-radius 0.1s linear 0.1s;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 25px 25px 0;
    height: 100%;
    padding: 0 2%;
    box-sizing: border-box;
    z-index: 2;
    > img {
      height: 64%; }

    &:not(.icon-only):hover {
      border-radius: 0;
      + .text {
        transform: translateX(0);
        padding-right: 25px; } }

    &.expanded {
      border-radius: 0;
      .expand-icon {
          opacity: 0; } } }

  .text {
    transform: translateX(-100%);
    transition: transform 0.2s linear;
    box-sizing: border-box;
    z-index: 1;
    > div {
      display: inline-flex;
      align-items: center;
      border-radius: 0 25px 25px 0;
      height: 100%;
      line-height: 1em;
      padding-right: 8px;
      white-space: nowrap;
      i {
        text-align: right;
        width: 24px;
        cursor: pointer; } }
    &.expand {
      transform: translateX(0);
      padding-right: 25px; } } }

.type-banners:not(.full) {
  height: calc(100% - 14px); }

.type-banners.full {
  .type-banner {
    height: 30px;

    .clear-icon {
      cursor: pointer; }

    .icon {
      cursor: pointer;

      .expand-icon {
        margin-left: 5px; }

      &:hover {
        &.collapsed {
          border-radius: 0 25px 25px 0;
          + .text {
            transform: translateX(-100%); } }
        &.expanded {
          + .text {
            transform: translateX(0); } } }

      &.expanded {
        .expand-icon {
          opacity: 0; } } }

    .text {
      > div {
        i {
          text-align: right;
          width: 24px;
          cursor: pointer; } } } } }

@media screen and (min-width: 640px) {
  .type-banner {
    .text {
      > div {
        text-overflow: ellipsis;
        overflow: hidden; } } } }
