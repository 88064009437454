.breaking-featured-banner {
  &.detailed {
    margin: 10px 0; }

  .breaking-featured-banner-text {
    display: inline-block;
    color: #ffffff;
    margin-right: 8px;
    padding: 2px 10px; }

  .breaking-featured-banner-description {
    border-left: 1px solid;
    display: inline;
    font-size: 13px;
    padding-left: 5px; } }
