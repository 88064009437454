.preview-appbar {
    background-color: transparent;
    border-bottom: 1px solid #dde1e5;

    .preview-toolbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .preview-title {
            font-size: 18px;
            color: #333333;
            margin: 0; }

        .preview-pill {
            position: absolute;
            left: 50%;
            transform: translateX(-50%); }

        .preview-actions {
            .action {
                margin-right: 30px; } } } }

.preview-content {
    padding: 21px 35px 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
    margin-top: 64px; // height of the top app bar

    .preview-help {
        font-style: italic;
        color: #666666;
        font-size: 13px;
        margin: 0;
        align-self: flex-start;
        width: 100%; } }
