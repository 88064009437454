.external-post-source-edit-form {
    button {
        margin: 4px; }

    .row {
        padding-bottom: 24px; }

    label {
        font-size: 12px;
        color: #666;
        display: block;
        padding-bottom: 8px;
        margin: 0; }

    .toggle-switch-container {
        display: flex;
        align-items: center;
        .toggleswitch {
            margin-right: 16px; }
        label {
            font-size: 14px;
            margin-bottom: 4px; } }

    .img-wrapper {
        display: block;
        float: left;
        width: 50%;
        height: 125px;
        margin: 4px;
        background-color: #ccc;
        position: relative;

        .img {
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            box-sizing: border-box;
            cursor: pointer; } } }
