.newsletter-uploader {
    border: dashed 2px #cccccc;
    min-height: 180px;
    height: 100%;
    width: 100%;
    transition: all 0.25s;
    box-sizing: border-box;
    position: relative;

    .centered-content {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        background-color: #ffffff;
        color: #c4c4c4;

        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        .progress-bar-wrapper {
            display: inline-block;
            width: 30%;
            padding: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; } }


    .large {
        font-size: 26px;
        font-weight: 600;
        transition: all 0.25s;

        span {
            color: #cccccc;
            margin-left: 12px; } }

    .original-input {
        display: block;
        width: 66px;
        height: 25px;
        clip: rect(0px 0px 0px 0px);
        position: absolute;
        left: 0;
        top: 0; }

    .choose-file-button {
        display: inline-block;
        cursor: pointer;
        color: #888; }

    &.is-dragging {
        border: dashed 6px #3b78ab;

        .choose-file-button {
            color: #cccccc; }

        .large span {
            color: #888888; } } }

.newsletter-uploader-preview {
    position: relative;
    width: 100%;

    button {
        position: absolute;
        right: 5px;
        top: 5px;
        background-color: #ffffff;
        color: #333333;
        &:hover {
            background-color: #ffffff; } }

    img {
        max-width: 100%;
        height: 100%; } }

.progress {
    width: 100%;
    height: 10px;
    background: #ffffff;
    border: solid 1px #cccccc;
    position: relative;
    margin-bottom: 10px;

    .bar {
        background-color: #3b78ab;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transition: all 0.1s; } }

.errors {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(12px);
    width: 80%;
    font-size: 90%;
    text-align: center;
    background-color: #444444;
    color: #ffffff;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);

    p {
        margin: 0;
        padding: 4px; } }
