.email-sender-container {
    .sender-edit-section {
        border-radius: 5px;
        border: 1px solid #dde1e5;
        padding-right: 5px;
        padding-left: 5px;
        margin-bottom: 10px;
        width: 600px;

        .edit-sender {
            margin: 15px 5px; }

        .sender-email {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;

            .email-prefix {
                width: 55%; }

            .email-suffix {
                width: 40%; } }

        .sender-email-buttons-container {
            margin-top: 10px;

            .add-button {
                margin-right: 5px; }

            .discard-button {
                color: #888888; } } }

    .email-sender-list-section {
        border-radius: 5px;
        border-style: solid;
        border-width: 1px;
        border-color: #dde1e5;
        padding-right: 5px;
        padding-left: 5px;
        margin-bottom: 10px;
        width: 600px;

        .sender-list {
            max-height: 350px;
            overflow-y: scroll; }

        .email-sender-search-bar {
            padding-top: 10px;
            padding-bottom: 6px;
            margin-left: 5px;
            margin-right: 5px;
            width: 98%; } } }
