.three-dots {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .three-dots-dot {
        animation: color-flash infinite;
        animation-duration: 1s;

        &.dot-1 {
            animation-delay: 0s; }
        &.dot-2 {
            animation-delay: 0.333s; }
        &.dot-3 {
            animation-delay: 0.666s; } } }

@keyframes color-flash {
    0% {
        fill: #444; }
    33.3% {
        fill: #eee; }
    66.6% {
        fill: #6e6e6e; }
    100% {
        fill: #444; } }
