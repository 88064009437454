.navigation-items-callout {
  box-sizing: border-box;
  width: 315px;

  .pages-list {
    > div {
      border-bottom: 1px solid #dde1e5;
      &:last-child {
        border-bottom: none; } } }

  .search-icon {
    fill: #666666;
    margin-right: 5px; }

  .text-to-search {
    margin-bottom: 8px; } }

.navigation-items-list {
  tr {
    > td {
      box-sizing: border-box; } }
  .navigation-item-order {
    width: 100px; }
  .child-item {
    > td {
      &:not(:first-child):not(:last-child) {
        padding-left: 32px;
        padding-right: 0; } } }
  .parent-item {
    border-bottom: 1px solid #dde1e5;
    border-top: 1px solid #dde1e5;
    &:first-child {
      border-top: none; } } }
