.dialog-tenantmanagement-title {
  padding-top: 15px;
  padding-right: 10px; }

.dialog-tenantmanagement-content {
  padding-bottom: 20px;
  padding-top: 20px; }

.required-label:after {
  content: "*";
  color: red; }
