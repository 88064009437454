#cb-mobile-preview {
    min-height: 700px;

    .cb-content {
        &.mobile-preview {
            &.window,
            &.card {
                padding-left: 7px; }

            &.window {
                padding-bottom: 20px; } } } }
