.audience-listing {
    .header-view {
        display: inline-flex;
        align-items: center; }

    .audience-results {
        margin-bottom: 15px; }

    .audience-state {
      position: relative;
      margin-right: -12px;
      &::before {
        content: "";
        position: absolute;
        left: -16px;
        border-radius: 50%;
        height: 8px;
        top: 5px;
        width: 8px; }
      &.green::before {
        background-color: #3b6531; }
      &.grey::before {
        background-color: #adb7c1; } }

    tbody {
        tr:hover {
            cursor: pointer; } } }

.audience-associated-item {
  cursor: pointer;
  &:hover {
    text-decoration: underline; } }

.audience-manager {
    display: flex;
    background: #fff;
    padding: 50px;

    button {
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 3px;
        padding: 8px 22px;
        font-size: 14px;
        cursor: pointer;
        box-shadow: 0 2px 3px rgba(0,0,0,0.1); }

    .audience-column {
        width: 50%; }

    .manager-controls-everyone {
        width: 90%;
        min-height: 185px;
        button {
            background-color: #2f4050;
            color: #fff; } }

    .manager-controls {
        width: 90%;
        min-height: 219px;
        button {
            background-color: #2f4050;
            color: #fff; } } }

.everyone-audience-manager {
    display: flex;
    padding: 50px;

    button {
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 3px;
        padding: 8px 22px;
        font-size: 14px;
        cursor: pointer;
        box-shadow: 0 2px 3px rgba(0,0,0,0.1); }

    .audience-column {
        width: 50%; }

    .manager-controls-everyone {
        width: 90%;
        min-height: 185px;
        button {
            background-color: #2f4050;
            color: #fff; } }

    .manager-controls {
        width: 90%;
        min-height: 219px;
        button {
            background-color: #2f4050;
            color: #fff; } } }

.audience-manager,
.everyone-audience-manager,
.everyone-audience-manager-groups {
    .details {
        background-color: #fcfcfc;
        width: 90%;
        padding: 15px;
        border-radius: 3px;
        border: 1px solid #BCBCBC;
        .input-wrapper {
            display: flex;
            padding-bottom: 10px;
            &.subscription {
                margin: 0 auto;
                width: 90%;
                margin-top: 10px; }
            label {
                text-align: right;
                padding-right: 10px;
                width: 90px;
                padding-top: 2px; }
            input {
                width: 400px;
                max-height: 16px; } }
        &.list {
            padding: 0px 5px 15px 5px;
            min-height: 600px;
            display: inline-block;
            width: 510px; }
        &.subscription {
            width: 98%; }

        .search-btn {
            margin-left: 10px;
            height: 30px;
            padding: 5px 22px;
            color: #fff; }

        .page-controls {
            width: 90%;
            margin: 0 auto;
            min-height: 30px;
            .page-btn {
                border: 1px solid #e5e6e7;
                padding: 0px 5px;
                color: #666;
                &.next {
                    float: right; } } } } }

.results {
    margin: 0 auto;
    background-color: #fff;
    width: 85%;
    padding: 15px;
    border-radius: 3px;
    margin-top: 10px;
    margin-bottom: 15px;
    border: 1px solid #e5e6e7;
    min-height: 400px;
    &.delete {
        background-color: #fcfcfc;
        .add-btn {
                font-size: 22px;
                float: right;
                cursor: pointer;
                color: #05b34b;
                min-width: 22px; } }
    .item {
        padding: 10px 5px;
        border-bottom: 1px solid #ddd;
        &:first-child {
            padding-top: 0px; }
        &:last-child {
            padding-bottom: 0px;
            border-bottom: none; }
        .add-btn {
            font-size: 22px;
            float: right;
            cursor: pointer;
            color: #05b34b;
            min-width: 22px; }
        .remove-btn {
            color: #fe0004;
            font-size: 22px;
            float: right;
            cursor: pointer; }
        .preview-btn {
            font-size: 22px;
            cursor: pointer;
            margin-right: 10px; }
        input {
            float: right;
            margin-top: 8px; } }
    .group-user {
        padding: 5px;
        width: 90%;
        padding-left: 20px;
        border-top: 1px solid #ddd; } }

.audience-title {
    color: #666;
    margin-left: 18px;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
    .add-btn {
        float: right; } }

.audience-warning {
    color: #e6911a;
    margin-left: 18px;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px; }

.popup-disclaimer {
    margin-left: 18px;
    margin-bottom: 15px;
    font-size: 15px;
    margin-top: 15px; }

.radio-wrapper {
    text-align: center;
    padding-bottom: 10px;
    label {
        margin: 10px;
        font-size: 18px;
        vertical-align: bottom; } }

@media screen and (max-width: 1000px) {
    .audience-manager {
        .audience-column {
            width: auto; } } }

@media screen and (max-width: 1000px) {
    .everyone-audience-manager {
        .audience-column {
            width: auto; } } }


.bulk-edit-modal-screen {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 5px;
    background: rgba(0,0,0,0.2);
    position: fixed;
    z-index: 9999; }


.bulk-edit-modal-container {
    width: 600px;
    border-radius: 3px;
    background: white;
    margin: 0 auto;
    top: 25%;
    position: relative;
    box-shadow: 0 3px 5px rgba(0,0,0,0.2);
    padding: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column; }
.bulk-edit-modal-header {
    display: flex;
    justify-content: space-between; }

.bulk-edit-btn-row {
    display: flex;
    justify-content: flex-end;
    bottom: 0px;
    margin-top: 20px; }
.bulk-edit-insights-row {
    display: flex; }

.callout {
    .callout-content {
        .disabled-button-message {
            font-size: 12px;
            width: 160px; } } }
