.authoring-page {
  min-height: 85vh;

  .no-event-data {
    padding: 25px 20px; }

  .tab-root {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    z-index: 3;

    .tab-commands {
      position: relative;

      button {
        margin-right: 15px;

        &:first-child {
          margin-left: 15px; } } }

    .tab-commands-list {
      position: absolute;
      right: -105px;
      background-color: #ffffff;
      box-shadow: 0 0 4px #aaaaaa;
      margin-top: 2px;
      z-index: 2;

      > div > svg {
        margin-right: 15px; }

      .tab-commands-list-item {
        white-space: nowrap; } } } }

.event-tab-command {
  position: relative;
  display: inline-block;
  margin: 0 15px;

  .event-tab-command-list {
    position: absolute;
    left: 1px;
    right: 1px;
    background-color: #ffffff;
    box-shadow: 0 0 4px #aaaaaa;
    margin-top: 1px;
    z-index: 4;

    .tab-command-list-item {
      white-space: nowrap; } } }
