
.channel-detail {
    position: relative;
    box-sizing: border-box;
    overflow: auto;
    background: #ffffff;
    padding: 0px;
    .disabled {
    	margin-top: 25px;
    	margin-left: 15px;
    	font-size: 20px;
    	color: #595959;
    	font-style: italic;
    	background-color: #ffffff; }
    h3 {
        padding-left: 15px; }
    .editing-btns {
        margin-left: 15px;
        margin-top: 50px;
        display: flex;
        background-color: #2196f3; }

    header {
        border-bottom: 1px solid #efefef;
        padding: 0 0 15px 15px;
        min-height: 70px;
        position: relative; }

    button {
        padding: 0px 14px;
        margin-top: -40px;
        border-radius: 3px;
        background-color: #2f4050;
        color: #ffffff;
        margin-right: 15px;
        &:disabled {
            color: #ccc;
            cursor: default; } }

    .error-mssg {
        margin-top: 10px;
        color: red; }

    .edit-btn {
        margin-top: 20px;
        margin-left: 20px;
        color: #000000;
        background-color: #ffffff;
        padding: 6px;
        font-size: 18px; }

    .reset-btn {
        color: #000000;
        background-color: #ffffff; }

    .name-edit {
        width: 400px;
        margin-top: 15px;
        font-size: 25px;
        font-weight: bold;
        font-family: Open Sans; }

    h2 {
        margin-bottom: 5px;
        margin-top: 25px; }
    .author {
        color: #797979;
        font-style: italic; }

    .topic-details {
        width: 60%;
        background-color: #f4f4f4;
        border-radius: 3px;
        padding: 10px 20px;
        margin-left: 40px;
        border: 1px solid #BCBCBC;
        margin-top: 40px;
        label {
            font-weight: bold;
            margin-right: 15px;
            min-width: 90px; }
        .detail-wrapper {
            margin-top: 10px;
            display: flex; }
        .row-wrapper {
            display: flex; }

        p {
            margin-right: 20px;
            margin-left: -20px; } } }

.tag-stats {
	.alert {
		margin: 0 15px 25px; }
	.post-awareness {
		span.xLabel {
			transform: translate(50px, -20px);
			display: inline-block;
			font-size: 12px; } }
	.post-reactions {
		width: 50%;
		float: left;
		&:after {
			content: '';
			clear: both; } } }

.tag-detail-items-wrapper {
	overflow: hidden; }


.tag-detail-items {
	display: -webkit-flex;
	display: flex;
	align-items: flex-start;
	align-content: flex-start;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding: 15px;
	margin-right: -20px; }

.tag-detail-item {
	position: relative;
	width: calc(50% - 20px);
	margin-right: 20px;
	margin-bottom: 20px;
	border: 1px solid #ddd;
	box-sizing: border-box;
	.author {
		font-size: 12px;
		color: #696969; } }

.detail-table {
    width: 100%;
    border-collapse: collapse;
    th {
        padding: 10px;
        text-align: left; }
    td {
        padding: 10px;
        border-top: 1px solid #ddd; }
    tr th:first-child, tr td:first-child {
        padding-left: 15px; }
    tr td:last-child {
        padding-right: 15px; }
    td.num, th.num {
        text-align: right;
        width: 45px; }
    td.date, th.date {
        width: 120px;
        text-align: left; } }
