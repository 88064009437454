@import "../../common/components/authoring/styles/authoringVariables";

#content-bands-editor-content {
    margin-top: $navbar-height;

    .authoring-container {
        overflow-y: scroll;

        .main-editor-paper {
            padding: 0 !important; }

        .main-editor-paper-open,
        .main-editor-paper-closed {
            padding: 0 !important;
            overflow: unset !important; } }

    #drawer-bookmark-button {
        top: $navbar-height; } }

.callout-content {
    height: auto; }
