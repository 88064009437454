.banner-container {
    display: flex;
    position: absolute;
    z-index: 10;
    .banner-base {
        display: flex;
        align-items: center;
        min-width: 250px;
        padding-left: 10px;
        .title {
            color: white;
            white-space: nowrap; } } }
