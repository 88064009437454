.external-sources-settings {
  .tableview {
    margin: 0;
    .tableview-column {
      height: 2em; } }

  .list-controls {
    margin-bottom: 25px; } }


